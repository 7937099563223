import React, { useEffect, useState } from "react";
import classNames from "./marketplace.module.scss";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import {
  assetClassesTabs,
  companiesTabs,
  productsTabs,
  servicesTabs,
  subAssetClassesTabs,
} from "../../assets/data/marketplace";
import axios from "axios";

const MarketPlaceMain = () => {
  const { marketPlaceType } = useParams();

  const [selectedTab, setSelectedTab] = useState("");
  const [marketPlaceValues, setMarketPlaceValues] = useState("");
  const [allServices, setAllServices] = useState("");
  const [allAssets, setAllAssets] = useState("");
  const [allSubAssets, setAllSubAssets] = useState("");

  //functions

  async function getAssetClassValues() {
    try {
      let response = await axios.get(
        `https://api.retired.app/retired/user/get/userprofiles?email=${localStorage.getItem(
          "LoginAccount"
        )}&userType=advisor`
      );

      if (response?.data?.status && response?.data?.data?.length > 0) {
        console.log(response?.data?.data[0], "response from get asset classes");
        setMarketPlaceValues(response?.data?.data[0]);
      }
    } catch (error) {
      console.log(error?.message, "Get asset class values error");
    }
  }

  async function getAllServices() {
    try {
      let response = await axios.get(
        `https://api.retired.app/retired/service/advisor/get?advisoremail=${localStorage.getItem(
          "LoginAccount"
        )}`
      );

      if (response?.data?.status && response?.data?.data?.length > 0) {
        setAllServices(response?.data?.data);
      } else {
        setAllServices([]);
      }
      //   console.log(response, "Get Asset Classes response");
    } catch (error) {
      setAllServices([]);
      console.log(error?.message, "Get All Select One Data error");
    }
  }
  async function getAllAsset() {
    try {
      let response = await axios.get(
        `https://api.retired.app/retired/asset/get`
      );

      if (response?.data?.status && response?.data?.data?.length > 0) {
        setAllAssets(response?.data?.data);
      } else {
        setAllAssets([]);
      }
      //   console.log(response, "Get Asset Classes response");
    } catch (error) {
      setAllAssets([]);
      console.log(error?.message, "Get All Select One Data error");
    }
  }

  async function getAllSubAsset() {
    try {
      let response = await axios.get(
        `https://api.retired.app/retired/subasset/get`
      );

      if (response?.data?.status && response?.data?.data?.length > 0) {
        setAllSubAssets(response?.data?.data);
      } else {
        setAllSubAssets([]);
      }
      //   console.log(response, "Get Asset Classes response");
    } catch (error) {
      setAllSubAssets([]);
      console.log(error?.message, "Get All Select One Data error");
    }
  }

  // conditional renderings

  const conditionTabsRendering = () => {
    switch (marketPlaceType) {
      case "assetclasses":
        return assetClassesTabs;
      case "subassetclasses":
        return subAssetClassesTabs;
      case "companies":
        return companiesTabs;
      case "products":
        return productsTabs;
      case "services":
        return servicesTabs;
      default:
        return []; // or any default value you want to return
    }
  };

  const conditionContent = () => {
    switch (marketPlaceType) {
      case "assetclasses":
        return (
          <SelectProductAndServices
            mapItems={marketPlaceValues?.assetClassDetails}
            stateObj={{ name: "name", icon: "icon", desc: "description" }}
          />
        );
      case "subassetclasses":
        return (
          <SelectProductAndServices
            mapItems={marketPlaceValues?.subAssetClassDetails}
            stateObj={{ name: "name", icon: "icon", desc: "description" }}
          />
        );
      case "companies":
        return (
          <SelectProductAndServices
            mapItems={marketPlaceValues?.investmentProviderDetails}
            stateObj={{ name: "fullName", icon: "icon", desc: "description" }}
          />
        );
      case "products":
        return (
          <SelectProductAndServices
            mapItems={marketPlaceValues?.ProductDetails}
            stateObj={{
              name: "productName",
              desc: "description",
            }}
          />
        );
      case "services":
        return (
          <SelectProductAndServices
            mapItems={allServices}
            stateObj={{ name: "name", icon: "icon", desc: "description" }}
          />
        );
      default:
        return <></>; // or any default value you want to return
    }
  };

  const conditionContentAll = () => {
    switch (marketPlaceType) {
      case "assetclasses":
        return (
          <SelectProductAndServices
            mapItems={allAssets}
            stateObj={{ name: "name", icon: "icon", desc: "description" }}
            addBtn={true}
          />
        );
      case "subassetclasses":
        return (
          <SelectProductAndServices
            mapItems={allSubAssets}
            stateObj={{ name: "name", icon: "icon", desc: "description" }}
            addBtn={true}
          />
        );
      case "companies":
        return (
          <SelectProductAndServices
            mapItems={marketPlaceValues?.investmentProviderDetails}
            stateObj={{ name: "fullName", icon: "icon", desc: "description" }}
            addBtn={true}
          />
        );
      case "products":
        return (
          <SelectProductAndServices
            mapItems={marketPlaceValues?.ProductDetails}
            stateObj={{
              name: "productName",
              desc: "description",
            }}
            addBtn={true}
          />
        );
      case "services":
        return (
          <SelectProductAndServices
            mapItems={allServices}
            stateObj={{ name: "name", icon: "icon", desc: "description" }}
            addBtn={true}
          />
        );
      default:
        return <></>; // or any default value you want to return
    }
  };

  //renderings

  useEffect(() => {
    if (localStorage.getItem("LoginAccount")) {
      getAssetClassValues();
      getAllServices();
      getAllAsset();
      getAllSubAsset();
    }
  }, []);

  useEffect(() => {
    if (marketPlaceType && marketPlaceValues) {
      switch (marketPlaceType) {
        case "assetclasses":
          setSelectedTab(assetClassesTabs[0]?.name);
          break;
        case "subassetclasses":
          setSelectedTab(subAssetClassesTabs[0]?.name);
          break;
        case "companies":
          setSelectedTab(companiesTabs[0]?.name);
          break;
        case "products":
          setSelectedTab(productsTabs[0]?.name);
          break;
        case "services":
          setSelectedTab(servicesTabs[0]?.name);
          break;
        default:
          break;
      }
    }
  }, [marketPlaceValues, marketPlaceType]);

  return (
    <div className={classNames.marketplaceMain}>
      <div className={classNames.allTabs}>
        {[...conditionTabsRendering()]?.map((eachTab, index) => {
          return (
            <div
              key={eachTab?.name + index}
              className={
                selectedTab === eachTab?.name && classNames.selectedTab
              }
              onClick={() => setSelectedTab(eachTab?.name)}
            >
              {eachTab?.name}
            </div>
          );
        })}
      </div>
      <div>
        {(selectedTab === "My Asset Classes" ||
          selectedTab === "My Sub-Asset Classes" ||
          selectedTab === "My Companies" ||
          selectedTab === "My Products" ||
          selectedTab === "My Services") &&
        (marketPlaceValues || allServices)
          ? conditionContent()
          : selectedTab === "All Asset Classes" ||
            selectedTab === "All Sub-Asset Classes"
          ? // ||
            // selectedTab === "All Companies" ||
            // selectedTab === "All Products" ||
            // selectedTab === "All Services"
            conditionContentAll()
          : ""}
      </div>
    </div>
  );
};

export default MarketPlaceMain;

const SelectProductAndServices = ({ title, mapItems, stateObj, addBtn }) => {
  return (
    <div className={classNames.selectDiv}>
      {title && <div className={classNames.title}>{title}</div>}
      <div className={classNames.allSelects}>
        {Array.isArray(mapItems) && mapItems?.length > 0
          ? mapItems?.map((eachItem, index) => {
              return (
                <div>
                  <div>
                    <div className={classNames.profile}>
                      {stateObj?.icon && (
                        <img
                          src={
                            eachItem[stateObj?.icon]
                              ? eachItem[stateObj?.icon]
                              : ""
                          }
                          alt={
                            eachItem[stateObj?.name]
                              ? eachItem[stateObj?.name]
                              : ""
                          }
                        />
                      )}
                      <div className={classNames.title}>
                        {eachItem[stateObj?.name]
                          ? eachItem[stateObj?.name]
                          : ""}
                      </div>
                    </div>
                    <div className={classNames.desc}>
                      {eachItem[stateObj?.desc] ? eachItem[stateObj?.desc] : ""}
                    </div>
                  </div>
                  {addBtn && (
                    <div className={classNames.addContainer}>
                      <div className={classNames.addBtn}>Add</div>
                    </div>
                  )}
                </div>
              );
            })
          : ""}
      </div>
    </div>
  );
};
