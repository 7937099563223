import React, { useContext, useEffect, useState } from "react";
import "./Article.scss";
import AdvisorSidebar from "../../../../components/AdvisorSidebar/AdvisorSidebar";
import { FiPlus, FiSearch } from "react-icons/fi";
import PotImg from "../../../../assets/Images/AdvisorList/pot.png";
import MarkerIconImg from "../../../../assets/Images/AdvisorList/Edit.png";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { Globalcontext } from "../../../../contexts/GlobalContext";
import AdvisorSidebarLatest from "../../../../components/AdvisorSidebarLatest";
import Header from "../../AdvisorProfile/Header";
import CreateArticle from "../CreateArticle";

const ArticlePage = () => {
  const [activeTab, setActiveTab] = useState("Articles/Blogs");
  const {
    email,
    token,
    tostShowOn,
    proposal,
    setSelectitem,
    clinetDetail,
    setclinetDetail,
    setgobalseacrhadviser,
    opentoggleclass,
    setopentoggleclass,
  } = useContext(Globalcontext);
  const history = useHistory();
  const [articles, setArticles] = useState([]);
  const [createArticle, setCreateArticle] = useState(false);

  // const articles = [
  //   {
  //     id: 1,
  //     title: "Lorem ipsum dolor sit amet,",
  //     content: "orem ipsum dolor sit amet, conseadipiscingctetur adipiscing elit, sed do....",
  //   },
  //   {
  //     id: 2,
  //     title: "Lorem ipsum dolor sit amet,",
  //     content: "orem ipsum dolor sit amet, conseadipiscingctetur adipiscing elit, sed do....",
  //   },
  //   {
  //     id: 3,
  //     title: "Lorem ipsum dolor sit amet,",
  //     content: "orem ipsum dolor sit amet, conseadipiscingctetur adipiscing elit, sed do....",
  //   },
  // ];

  const getArticles = () => {
    axios
      .get(
        `https://publications.apimachine.com/article?email=${localStorage.getItem(
          "LoginAccount"
        )}`
      )
      .then(({ data }) => {
        if (data.status) {
          setArticles(data?.data);
        }
      });
  };

  useEffect(() => {
    if (localStorage.getItem("LoginAccount")) {
      getArticles();
    }
  }, [localStorage.getItem("LoginAccount")]);
  const handleTabClick = (tab) => {
    setActiveTab(tab);
    if (tab === "Articles/Blogs") {
      history.push("/advisor-library/articles");
    } else {
      history.push("/advisor-library/social-content");
    }
  };

  return (
    <div className="advisor-chat-pagemainflex">
      <Header />
      <div className="flexContainers">
        <AdvisorSidebarLatest />
        <div className="advisor-chat-advisor-rightsidedatashow">
          {/* <div className="article-page-header">
            <div className="tab-container">
              <button
                className={`tab ${
                  activeTab === "Articles/Blogs" ? "active" : ""
                }`}
                onClick={() => handleTabClick("Articles/Blogs")}
              >
                Articles/Blogs
              </button>
              
            </div>
            <div className="search-container">
              <FiSearch className="search-icon" />
              <input
                type="text"
                placeholder="Search"
                className="search-input"
              />
            </div>
          </div> */}

          <div className="article-main">
            <div className="article-list">
              {articles?.map((item) => (
                <div
                  key={item.id}
                  className="article-card"
                  onClick={() => {
                    window.open(
                      `https://retired.app/advisorprofiles/shorupandomains@gmail.com/article/${item?.custom_url}`
                    );
                  }}
                >
                  <img
                    src={item?.icon}
                    alt="Article"
                    className="article-image"
                  />
                  <div className="article-content">
                    <h3 className="article-title">{item?.title}</h3>
                    <p className="article-description">{item?.desc}</p>
                    <a href="#" className="read-more">
                      Read more...
                    </a>
                  </div>
                </div>
              ))}
            </div>

            {/* <div className="article-list1">
              <img src={MarkerIconImg} alt="Edit" className="edit-icon" />
            </div> */}
          </div>

          <button
            className="create-new-button"
            onClick={() => {
              setCreateArticle(true);
            }}
          >
            <FiPlus size={20} /> CREATE NEW
          </button>
          {createArticle && (
            <CreateArticle setCreateArticle={setCreateArticle} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ArticlePage;
