import React, { useState } from "react";
import classNames from "./advisorsidebarlatest.module.scss";
import { allAdvisorSidebar } from "../../assets/data";
import { FaAngleDown } from "react-icons/fa";
import { useHistory, useLocation } from "react-router-dom";

const AdvisorSidebarLatest = () => {
  const history = useHistory();
  const location = useLocation();
  const [selectedMenu, setSelectedMenu] = useState("Dashboard");
  const [selectedSubMenu, setSelectedSubMenu] = useState("");

  return (
    <div className={classNames.advisorSidebarLatest}>
      {allAdvisorSidebar?.map((eachSidebarItem, index) => {
        return (
          <div
            className={classNames.eachSidebarItem}
            key={eachSidebarItem?.name + index}
          >
            <div
              className={`${classNames.eachItem} ${
                index > 3 && classNames.notClickable
              }`}
              onClick={() => {
                // setSelectedMenu(eachSidebarItem?.name);
                // setSelectedSubMenu("");
                if (selectedMenu === eachSidebarItem?.name) {
                  setSelectedMenu("");
                } else {
                  setSelectedMenu(eachSidebarItem?.name);
                  // if (eachSidebarItem?.subMenu?.length > 0) {
                  //   setSelectedSubMenu(
                  //     eachSidebarItem?.subMenu?.length > 0
                  //       ? eachSidebarItem?.subMenu[0]?.name
                  //       : ""
                  //   );
                  //   history.push(eachSidebarItem?.subMenu[0]?.link);
                  // }
                }
              }}
            >
              <span>
                <img src={eachSidebarItem?.icon} alt={eachSidebarItem?.name} />
                <span>{eachSidebarItem?.name}</span>
              </span>
              <FaAngleDown
                className={
                  selectedMenu === eachSidebarItem?.name &&
                  classNames.rotateArrow
                }
              />
            </div>
            {selectedMenu === eachSidebarItem?.name &&
              Array.isArray(eachSidebarItem?.subMenu) &&
              eachSidebarItem?.subMenu?.length > 0 && (
                <div className={classNames.subMenu}>
                  {Array.isArray(eachSidebarItem?.subMenu) &&
                  eachSidebarItem?.subMenu?.length > 0
                    ? eachSidebarItem?.subMenu?.map((eachItem, index) => {
                        return (
                          <div
                            key={eachItem?.name + index}
                            className={
                              (selectedSubMenu === eachItem?.name ||
                                location?.pathname?.includes(eachItem?.link)) &&
                              classNames.selectedSubMenu
                            }
                            onClick={() => {
                              if (eachItem?.link) {
                                setSelectedSubMenu(eachItem?.name);
                                history.push(eachItem?.link);
                              }
                            }}
                          >
                            {eachItem?.name}
                          </div>
                        );
                      })
                    : ""}
                </div>
              )}
          </div>
        );
      })}
    </div>
  );
};

export default AdvisorSidebarLatest;
