import React, { useContext, useState } from "react";
import "./navbar.css";
import { Link } from "react-router-dom";
import mainlogo from "../../assets/Images/Home/main-logo.svg";

import {
  affiliateapp,
  bonds,
  moneymarkets,
  retiredapp,
} from "../../assets/Func/navigations";
import menu from "../../assets/Images/About/menu.svg";
import { HiMenuAlt1, HiChevronLeft } from "react-icons/hi";
import { Globalcontext } from "../../contexts/GlobalContext";

const Navbar = () => {
  const { sideBarStatus, setSideBarStatus } = useContext(Globalcontext);

  return (
    <nav className="main-nav">
      <div className="nav-logo">
        <Link to="/">
          <img src={mainlogo} alt="mainlogo" />
        </Link>
      </div>
      <ul className="main-nav-navigations">
        {window.location.pathname == "/coach" ? (
          <>
            <li>How It Works</li>
            <li>Countries</li>
            <li>Partners</li>
            <li className="main-nav-btn">Apps</li>
          </>
        ) : (
          <>
            <li onClick={affiliateapp}>Refer & Earn</li>
            <li onClick={moneymarkets}>Money Markets</li>
            <li onClick={bonds}>Bonds</li>
            <li>
              <Link to="aboutus">About us</Link>
            </li>
            <li className="main-nav-btn">Apps</li>
            <li className="main-nav-btn" onClick={retiredapp}>
              Login
            </li>
          </>
        )}
      </ul>
      <div
        className="main-nav-menu"
        onClick={() => setSideBarStatus(!sideBarStatus)}
      >
        {sideBarStatus ? <HiChevronLeft /> : <HiMenuAlt1 />}
      </div>
    </nav>
  );
};

export default Navbar;
