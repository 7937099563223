import React, { useState } from "react";
import "./shareon.scss";
import { BiEdit } from "react-icons/bi";
import twitter from "../../../../assets/Images/AdvisorList/twitter.svg";
import whatsaap from "../../../../assets/Images/AdvisorList/whatsaap.svg";
import facebook from "../../../../assets/Images/AdvisorList/facebook.svg";
import linkedin from "../../../../assets/Images/AdvisorList/linkedin.svg";
import insta from "../../../../assets/Images/AdvisorList/insta.svg";
import image from "../../../../assets/Images/AdvisorList/blog.png";
import MarkerImg from "../../../../assets/Images/AdvisorList/marker.png";
import profile from "../../../../assets/Images/AdvisorList/profile-1.png";
import AdvisorSidebar from "../../../../components/AdvisorSidebar/AdvisorSidebar";
import { useHistory } from "react-router-dom";
import AdvisorSidebarLatest from "../../../../components/AdvisorSidebarLatest";
import Header from "../../AdvisorProfile/Header";

const ShareOn = () => {
  const [activeTab, setActiveTab] = useState("Articles/Blogs");
  const history = useHistory();

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    if (tab === "Articles/Blogs") {
      history.push("/advisor-library/articles");
    } else {
      history.push("/advisor-library/social-content");
    }
  };

  return (
    <div className="advisor-profile-pagemainflex">
      <Header />
      <div className="flexContainers">
        <AdvisorSidebarLatest />
        <div className="advisor-profile-rightsidedatashow advisor-add-article">
          <div className="article-page-header">
            <div className="tab-container">
              <button
                className={`tab ${
                  activeTab === "Articles/Blogs" ? "active" : ""
                }`}
                onClick={() => handleTabClick("Articles/Blogs")}
              >
                Articles/Blogs
              </button>
              <button
                className={`tab ${
                  activeTab === "Social Content" ? "active" : ""
                }`}
                onClick={() => handleTabClick("Social Content")}
              >
                Social Content
              </button>
            </div>
          </div>

          <div className="containers">
            <div className="title">
              <h3>Share on</h3>
              <img
                src={MarkerImg}
                alt="marker"
                onClick={() =>
                  history.push("/advisor-library/social-content/share-on/edit")
                }
              />
            </div>
            <div className="share-icons">
              <img src={twitter} alt="twitter" style={{ fontSize: "64px" }} />
              <img src={whatsaap} alt="whatsaap" style={{ fontSize: "64px" }} />
              <img src={facebook} alt="facebook" style={{ fontSize: "64px" }} />
              <img src={linkedin} alt="linkedin" style={{ fontSize: "64px" }} />
              <img src={insta} alt="instagram" style={{ fontSize: "64px" }} />
            </div>
            <div className="contents">
              <img src={image} alt="" />
              <h2>Need Basic Finance Planning</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                in reprehenderit in voluptate velit esse cillum dolore eu fugiat
                nulla pariatur.
              </p>
              <div className="contact-info">
                <img
                  src={profile}
                  alt="Harvey Bing"
                  className="contact-image"
                />
                <div className="contact-details">
                  <h3>Harvey Bing</h3>
                  <p>9123456789</p>
                  <p>harveybing@gmail.com</p>
                </div>
              </div>
            </div>
            <div className="actions">
              <button className="btn share">Share</button>
              <button className="btn copy">Copy</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareOn;
