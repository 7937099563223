import React from "react";
import "./calculator.css";
import Calculatorsection from "../../components/Landing/sections/Calculatorsection/Calculatorsection";
import doublearrow from "../../assets/Images/Home/doublearrow.svg";
import { Link } from "react-router-dom";

const Calculator = () => {
  return (
    <>
      <Calculatorsection />
      <Link to="/">
        <img src={doublearrow} alt="doublearrow" className="doublearrow" />
      </Link>
    </>
  );
};

export default Calculator;
