import React from "react";
import "./legacydesigns.css";
import Legacydesigns from "../../components/Landing/Legacydesigns";

const LegacydesignsPage = () => {
  return (
    <div>
      <Legacydesigns />
    </div>
  );
};

export default LegacydesignsPage;
