export const assetClassesTabs = [
  { name: "My Asset Classes" },
  { name: "My Firm’s Asset Classes" },
  { name: "All Asset Classes" },
];

export const subAssetClassesTabs = [
  { name: "My Sub-Asset Classes" },
  { name: "My Firm’s Sub-Asset Classes" },
  { name: "All Sub-Asset Classes" },
];

export const companiesTabs = [
  { name: "My Companies" },
  { name: "My Firm’s Companies" },
  { name: "All Companies" },
];

export const productsTabs = [
  { name: "My Products" },
  { name: "My Firm’s Products" },
  { name: "All Products" },
];

export const servicesTabs = [
  { name: "My Services" },
  { name: "My Firm’s Services" },
  { name: "All Services" },
];
