import axios from "axios";
import React, { createContext, useCallback, useEffect, useState } from "react";

const LoginContext = createContext();

export function LoginContextProvider({ children }) {
  const [email, setEmail] = useState(
    localStorage.getItem("LoginAccount") || ""
  );
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem("AccessToken") || ""
  );
  const [token, setToken] = useState(localStorage.getItem("Token") || "");

  useEffect(() => {
    localStorage.setItem("LoginAccount", email);
  }, [email]);
  useEffect(() => {
    localStorage.setItem("AccessToken", accessToken);
  }, [accessToken]);
  useEffect(() => {
    localStorage.setItem("Token", token);
  }, [token]);

  const setLoginValues = useCallback((email, accessToken, token) => {
    setEmail(email);
    setAccessToken(accessToken);
    setToken(token);
  }, []);

  const validateToken = useCallback(
    (
      email,
      token,
      succesFn = () => {},
      failedFn = () => setLoginValues("", "", "")
    ) => {
      console.log('Validating token:', {email, token});
      axios
        .post("https://comms.globalxchange.io/coin/verifyToken", {
          email,
          token,
        })
        .then((res) => {
          console.log('Validation response:', res);
          res.data.status ? succesFn() : failedFn()
    })
    .catch((error) => {
      console.error('Token validation failed:', error);
      console.error('Error details:', error.response ? error.response.data : 'No response data');
      failedFn();
    });
    },
    [setLoginValues]
  );

  useEffect(() => {
    if (email && token) {
      validateToken(email, token);
    }
  }, [email, token, validateToken]);

  return (
    <LoginContext.Provider value={{ setLoginValues }}>
      {children}
    </LoginContext.Provider>
  );
}

export default LoginContext;
