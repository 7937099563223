import React from 'react';
import { FiPlus } from "react-icons/fi";
import './ClientReport.scss';
import AdvisorSidebar from '../../../../components/AdvisorSidebar/AdvisorSidebar';
import CaretIcon from '../../../../assets/Images/AdvisorList/caret-down.png';

const CreateReport = () => {
  return (
    <div className="advisor-chat-page-main-flex" style={{ '--caret-icon': `url(${CaretIcon})` }}>
      <AdvisorSidebar />
      <div className="create-report-container">
        <h2>Create Report</h2>
        <div className="report-form">
          <div className="form-row">
            <div className="input-group">
              <label htmlFor="title-input">Title</label>
              <input id="title-input" type="text" className="title-input" />
            </div>
            <div className="input-group">
              <label htmlFor="amount-input">Target Amount</label>
              <input id="amount-input" type="text" value="10,00,000" className="amount-input" />
            </div>
            <div className="input-group">
              <label htmlFor="duration-input">Duration</label>
              <input id="duration-input" type="text" value="2 years" className="duration-input" />
            </div>
          </div>
          <table className="asset-table">
            <thead>
              <tr>
                <th>Asset class</th>
                <th>Asset class type</th>
                <th>Company</th>
                <th>Scheme</th>
                <th>Avg interest rate</th>
                <th>Tenure</th>
                <th>No. of installments</th>
                <th>% of total target</th>
                <th>Asset value at target date</th>
                <th>Start date</th>
                <th>Amount per investment</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <select defaultValue="Mutual funds">
                    <option>Mutual funds</option>
                  </select>
                </td>
                <td>
                  <select defaultValue="Equity">
                    <option>Equity</option>
                  </select>
                </td>
                <td>
                  <select defaultValue="ICICI">
                    <option>ICICI</option>
                  </select>
                </td>
                <td>
                  <select defaultValue="Index funds">
                    <option>Index funds</option>
                  </select>
                </td>
                <td>10%</td>
                <td>
                  <select defaultValue="Monthly">
                    <option>Monthly</option>
                  </select>
                </td>
                <td className='bl_col'>36</td>
                <td>20%</td>
                <td className='bl_col'>2,00,000</td>
                <td className='bl_col'>23-10-2020</td>
                <td className='bl_col'>40,000</td>
              </tr>
              <tr>
                <td>
                  <select defaultValue="Mutual funds">
                    <option>Mutual funds</option>
                  </select>
                </td>
                <td>
                  <select defaultValue="Equity">
                    <option>Equity</option>
                  </select>
                </td>
                <td>
                  <select defaultValue="ICICI">
                    <option>ICICI</option>
                  </select>
                </td>
                <td>
                  <select defaultValue="Index funds">
                    <option>Index funds</option>
                  </select>
                </td>
                <td>10%</td>
                <td>
                  <select defaultValue="Monthly">
                    <option>Monthly</option>
                  </select>
                </td>
                <td className='bl_col'>36</td>
                <td>20%</td>
                <td className='bl_col'>2,00,000</td>
                <td className='bl_col'>14-10-2021</td>
                <td className='bl_col'>40,000</td>
              </tr>
            </tbody>
          </table>
          <button className="add-row-button">
            <FiPlus /> Add row
          </button>
          <table className="summary-table">
            <thead>
              <tr>
                <th>Asset class</th>
                <th>Summary</th>
                <th colSpan="3">Performance</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className='bl_col'>Mutual funds</td>
                <td>Lorem ipsum</td>
                <td>Year 1</td>
                <td>Year 2</td>
                <td>Year 3</td>
                <td>
                  <button className="expand-button">+</button>
                </td>
              </tr>
              <tr>
                <td className='bl_col'>Mutual funds</td>
                <td>Lorem ipsum</td>
                <td>Year 1</td>
                <td>Year 2</td>
                <td>Year 3</td>
                <td>
                  <button className="expand-button">+</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="button-group">
          <button className="cancel-button">Cancel</button>
          <button className="save-button">Save</button>
        </div>
      </div>
    </div>
  );
};

export default CreateReport;
