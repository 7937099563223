import React from "react";
import "./Modal.scss";

const Modal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="checkbox-container">
          <div className="checkbox-item">
            <input type="checkbox" id="forAll" />
            <label htmlFor="forAll">For All</label>
          </div>
          <div className="checkbox-item">
            <input type="checkbox" id="publish" />
            <label htmlFor="publish">Publish on your website</label>
          </div>
        </div>
        <button className="share-button">Share</button>
      </div>
    </div>
  );
};

export default Modal;
