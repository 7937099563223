import React, { useContext, useEffect, useState } from "react";
import "./AdvisorProfile.scss";
import { FiSearch } from "react-icons/fi";
import AdvisorSidebar from "../../../components/AdvisorSidebar/AdvisorSidebar";

import PersonImg from "../../../assets/Images/AdvisorList/person.png";
import VideoImg from "../../../assets/Images/AdvisorList/blog.png";
import MarkerIconImg from "../../../assets/Images/AdvisorList/Edit.png";
import ShareIconImg from "../../../assets/Images/AdvisorList/shareImg.png";
import LockIconImg from "../../../assets/Images/AdvisorList/Lock_Icon.png";
import PersonIconImg from "../../../assets/Images/AdvisorList/profile-1.png";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import axios from "axios";
import LoginContext from "../../../contexts/LoginContext";
import Header from "./Header";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import AdvisorSidebarLatest from "../../../components/AdvisorSidebarLatest";

const AdvisorProfile = () => {
  const [globalsearchadviser, setglobalsearchadviser] = useState("");
  const [userData, setUserData] = useState("");
  const history = useHistory();
  const { setLoginValues } = useContext(LoginContext);

  const handleLogout = () => {
    setLoginValues("", "", "");
    localStorage.clear();
    history.push("/advisor/login");
  };

  const fetchProfileData = async () => {
    try {
      const { data } = await axios.get(
        `https://api.retired.app/retired/user/get/userprofiles?email=${
          localStorage.getItem("LoginAccount") || ""
        }`
      );
      if (data?.status) {
        setUserData(data?.data[0]);
      }
      console.log(data, "fetch Profile data");
      // return data;
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };
  const [getProduct, setgetProduct] = useState([]);
  const [complainData, setcomplainData] = useState([]);

  const [videoData, setVideoData] = useState([]);
  const getVideoData = () => {
    axios
      .get(
        `https://publications.apimachine.com/video/publication/66479e22530d1d0cd205a7dd`
      )
      .then(({ data }) => {
        setVideoData(data?.data);
      });
  };

  const Products = () => {
    axios
      .get(
        `https://api.retired.app/retired/product/get?creationAdvisorEmail=${
          localStorage.getItem("LoginAccount") || ""
        }`
      )
      .then(({ data }) => {
        if (data.status) {
          setgetProduct(data?.data);
        } else {
          setgetProduct([]);
        }
      });
  };
  const companiesFunc = () => {
    axios
      .get(`https://api.retired.app/retired/investmentprovider/get`)
      .then(({ data }) => {
        if (data.status) {
          setcomplainData(data?.data);
        } else {
          setcomplainData([]);
        }
      });
  };
  // const {
  //   data: profileData,
  //   error,
  //   isLoading,
  // } = useQuery("profileData", fetchProfileData, {
  //   staleTime: 3 * 60 * 1000,
  //   refetchOnWindowFocus: false,
  // });

  const handleEditProfileInfo = () => {
    history.push("/advisor-profile/edit");
  };

  //renderings

  useEffect(() => {
    Products();
    companiesFunc();
    getVideoData();
    fetchProfileData();
  }, [localStorage.getItem("LoginAccount")]);

  // if (isLoading) return <div>Loading...</div>;
  // if (error) return <div>Error while fetching data</div>;

  // const userData = profileData?.data?.[0];

  return (
    <div className="advisor-profile-pagemainflex">
      <Header />
      <div className="flexContainers">
        {/* <AdvisorSidebar /> */}
        <AdvisorSidebarLatest />
        <div className="advisor-profile-rightsidedatashow">
          <div className="advisor-profile-main">
            <div className="advisor-profile-parent">
              <div className="advisor-profile-content">
                <div className="profile-info">
                  <img
                    src={userData?.profilePic}
                    alt="Profile"
                    className="profile-image"
                  />
                  <div className="profile-details">
                    <div className="profile-main">
                      <div className="flex_class">
                        <h3 className="profile-heading">
                          <span>
                            {userData?.firstName ? (
                              userData?.firstName
                            ) : (
                              <Skeleton height={15} />
                            )}
                          </span>{" "}
                          <span>
                            {userData?.lastName ? userData?.lastName : ""}
                          </span>
                        </h3>
                        {/* <img
                          src={MarkerIconImg}
                          alt="EditIcon"
                          onClick={handleEditProfileInfo}
                        /> */}
                      </div>
                      <div className="profile-expertise">
                        <span>
                          {userData?.userType ? userData?.userType : ""}
                        </span>
                        {userData?.userType && userData?.brokerAgeName && " | "}
                        <span>
                          {userData?.brokerAgeName
                            ? userData?.brokerAgeName
                            : ""}
                        </span>
                      </div>
                      <p className="profile-experience">{userData?.jobTitle}</p>
                      <a href="#" className="profile-place">
                        {userData?.countryName ? userData?.countryName : ""}
                      </a>
                    </div>
                    <div className="about-us">
                      <h6>About</h6>
                      <p>{userData?.about ? userData?.about : ""}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="advisor-profile-content custom-flex">
                <div className="products-services">
                  {/* <div className="services">
                  <div className="flex_class">
                    <h3 className="section-heading">Services</h3>
                    <img
                      src={MarkerIconImg}
                      alt="EditIcon"
                      onClick={() =>
                        history.push("/advisor-profile/add-service")
                      }
                    />
                  </div>
                  <ul>
                    <li>Lorem ipsum</li>
                    <li>Lorem ipsum jadosd</li>
                    <li>Lorem ipsum jadosd</li>
                    <li>Lorem ipsum jadosd</li>
                    <li>Lorem ipsum jadosd</li>
                  </ul>
                </div> */}
                  {/* <div className="products">
                    <div className="flex_class">
                      <h3 className="section-heading">Products</h3>
                      <img
                        src={MarkerIconImg}
                        alt="EditIcon"
                        // onClick={handleEditClick}
                      />
                    </div>
                    <ul>
                      {getProduct?.map((item) => {
                        return <li>{item?.productName}</li>;
                      })}
                    </ul>
                  </div>

                  <div className="testimonial-parent">
                    <div className="flex_class">
                      <h3 className="section-heading">Companies</h3>
                      <img
                        src={MarkerIconImg}
                        alt="EditIcon"
                        // onClick={handleEditClick}
                      />
                    </div>
                    <ul>
                      {complainData?.map((item) => {
                        return <li>{item?.displayName}</li>;
                      })}
                    </ul>
                  </div> */}
                </div>
              </div>
            </div>

            <div className="advisor-profile-parent-1">
              <div className="videos">
                <div className="flex_class">
                  <h3 className="section-heading">Videos</h3>
                  {/* <img
                    src={MarkerIconImg}
                    alt="EditIcon"
                    // onClick={handleEditClick}
                  /> */}
                </div>
                <div className="video-flex">
                  {videoData?.map((item) => (
                    <div
                      className="video"
                      onClick={(e) =>
                        window.open("https://" + item?.video, "_blank")
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        src={item?.image}
                        alt="Video"
                        className="video-image"
                      />
                      <p className="video-title">{item?.title}</p>
                      <a href="#" className="video-link">
                        {item?.desc}
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvisorProfile;
