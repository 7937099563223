import React from "react";
import "./ClientPreview.scss";
import { FiArrowLeft, FiDownload } from "react-icons/fi";

import BackArrowImg from "../../../../assets/Images/AdvisorList/back_arrow.png";
import DownLoadImg from "../../../../assets/Images/AdvisorList/Download.png";
import AdvisorSidebar from "../../../../components/AdvisorSidebar/AdvisorSidebar";
import { useHistory } from "react-router-dom";
import AdvisorSidebarLatest from "../../../../components/AdvisorSidebarLatest";
import Header from "../../AdvisorProfile/Header";

const ClientPreview = () => {
  const history = useHistory();

  return (
    <div className="pagemainflex">
      <Header />
      <div className="flexContainers">
        <AdvisorSidebarLatest />
        <div className="rightsidedatashow">
          <div className="flex_preview">
            <div className="reportPage">
              <div className="reportContainer">
                <h3 className="reportTitle">Title</h3>
                <p className="reportText">
                  This is a common paragraph for every report. This is a common
                  paragraph for every report. This is a commojklj kajjdkn
                  paragraph for every report. This is a common paragraph for
                  every report. This is a common paragraph for ejsakdavery
                  report. This is a common paragraph for every report. This is a
                  common paragraph for every report.
                </p>
                <h4 className="sectionTitle">Lorem Ipsum</h4>
                <p className="reportText">
                  This is a common paragraph for every report. This is a common
                  paragraph for every report. This is a commojklj kajjdkn
                  paragraph for every report. This is a common paragraph for
                  every report. This is a common paragraph for ejsakdavery
                  report. This is a common paragraph for every report. This is a
                  common paragraph for every report.
                </p>
                <p className="reportText">
                  This is a common paragraph for every report. This is a common
                  paragraph for every report. This is a commojklj kajjdkn
                  paragraph for every report. This is a common paragraph for
                  every report. This is a common paragraph for ejsakdavery
                  report. This is a common paragraph for every report. This is a
                  common paragraph for every report.
                </p>
                <table className="reportTable">
                  <thead>
                    <tr>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
                <h4 className="sectionTitle">Product 1</h4>
                <p className="reportText">
                  This is a common paragraph for every report. This is a common
                  paragraph for every report. This is a commojklj kajjdkn
                  paragraph for every report. This is a common paragraph for
                  every report. This is a common paragraph for ejsakdavery
                  report. This is a common paragraph for every report. This is a
                  common paragraph for every report.
                </p>
              </div>
            </div>
            <button className="share_button">share</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientPreview;
