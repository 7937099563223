import React, { useEffect, useState } from "react";
import styles from "./product.module.scss";
import { useParams } from "react-router-dom";
import axios from "axios";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import blog from "./blog.png";
import { FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { CiFacebook } from "react-icons/ci";
import { useHistory } from "react-router-dom";
import imgCard from "./cardImg.png";
import HeaderNav from "../../components/HeaderNav";
import HeaderNavFirms from "../../components/HeaderNav/HeaderNavFirms";

const SubAssetsFirms = () => {
  let history = useHistory();
  const { firmsid } = useParams();

  const [firmsProfile, setFirmsProfile] = useState("");
  const [loadingUserData, setLoadingUserData] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  //functions

  async function getFirmsProfile(firmsID) {
    setLoadingUserData(true);
    try {
      let response = await axios.get(
        `https://api.retired.app/retired/firm/get?firm_id=${firmsID}`
      );

      if (response?.data?.status && response?.data?.data?.length > 0) {
        setFirmsProfile(response?.data?.data[0]);
      }
      setLoadingUserData(false);
      console.log(response, "Get firms profile response");
    } catch (error) {
      setLoadingUserData(false);
      console.log(error?.messagge, "Get firms profile error");
    }
  }

  //renderings

  useEffect(() => {
    if (firmsid) {
      getFirmsProfile(firmsid);
    }
  }, []);

  return (
    <div style={{ zoom: "125%" }}>
      <HeaderNavFirms
        firmsProfile={firmsProfile}
        lastpage={true}
        history={history}
      />
      <div style={{ height: "86vh", marginTop: "2rem" }}>
        {Array.isArray(firmsProfile?.subAssetClassDetails) &&
          firmsProfile?.subAssetClassDetails?.length > 0 &&
          firmsProfile?.subAssetClassDetails?.map((item, index) => {
            if (index % 2 == 0) {
              return (
                <div className={styles.evenCard}>
                  <div style={{ width: "50%" }}>
                    <div className={styles.cardTitle}>{item?.name}</div>
                    <div className={styles.cardSubTitle}>
                      {item?.description}
                    </div>
                  </div>
                  <div
                    style={{
                      width: "48%",
                      borderRadius: "10px",
                      border: "1px solid #e7e7e7",
                      padding: "2rem",
                      width: "40.5rem",
                      height: "26.875rem",
                    }}
                  >
                    <img
                      src={item.icon ? item.icon : imgCard}
                      alt=""
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                </div>
              );
            } else {
              return (
                <div
                  className={styles.oddCard}
                  style={{
                    backgroundColor: `rgba(${parseInt(
                      firmsProfile?.colorcode?.substring(0, 2),
                      16
                    )}, ${parseInt(
                      firmsProfile?.colorcode?.substring(2, 4),
                      16
                    )}, ${parseInt(
                      firmsProfile?.colorcode?.substring(4, 6),
                      16
                    )}, 0.1)`, // 0.5 is the opacity value
                  }}
                >
                  <div
                    style={{
                      width: "50%",
                      borderRadius: "10px",
                      border: "1px solid #e7e7e7",
                      padding: "2rem",
                      width: "40.5rem",
                      height: "26.875rem",
                    }}
                  >
                    <img
                      src={item.icon ? item.icon : imgCard}
                      alt=""
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                  <div style={{ width: "50%" }}>
                    <div className={styles.cardTitle}>{item?.name}</div>
                    <div className={styles.cardSubTitle}>
                      {item?.description}
                    </div>
                  </div>
                </div>
              );
            }
          })}
      </div>
      <div
        className={styles.footerNav}
        style={{ backgroundColor: `#${firmsProfile?.colorcode}` }}
      >
        <div className={styles.footerLeft}>
          <img
            src={require("./phone.png").default}
            alt=""
            className={styles.phoneIcon}
          />
          <div className={styles.phoneNo}>{firmsProfile?.phonenumber}</div>
        </div>
        {/* <img
          src={require("./social.png").default}
          alt=""
          className={styles.socialLinks}
        /> */}
        <div
          style={{
            width: "7.125rem",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <FaInstagram
            className={styles.socialLinks}
            onClick={() => window.open(firmsProfile?.instagram, "_blank")}
          />
          <FaLinkedinIn
            className={styles.socialLinks}
            onClick={() => window.open(firmsProfile?.linkedIn, "_blank")}
          />
          <CiFacebook
            className={styles.socialLinks}
            onClick={() => window.open(firmsProfile?.facebook, "_blank")}
          />
        </div>
      </div>
    </div>
  );
};

export default SubAssetsFirms;
