import React, { useState } from "react";
import "./EditArticle.scss";
import { FiSearch, FiPlus } from "react-icons/fi";
import AdvisorSidebar from "../../../../components/AdvisorSidebar/AdvisorSidebar";
import { useHistory } from "react-router-dom";

import DeleteIcon from "../../../../assets/Images/AdvisorList/trash.png";
import VideoImg from "../../../../assets/Images/AdvisorList/blog.png";
import Modal from "../../../../components/Advisors/Modal/Modal";
import AdvisorSidebarLatest from "../../../../components/AdvisorSidebarLatest";
import Header from "../../AdvisorProfile/Header";

const EditArticle = () => {
  const [globalsearchadviser, setglobalsearchadviser] = useState("");
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleEditProfileInfo = () => {
    history.push("/advisor-profile/edit");
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="advisor-profile-pagemainflex">
      <Header />
      <div className="flexContainers">
        <AdvisorSidebarLatest />
        <div className="advisor-profile-rightsidedatashow">
          <div className="searchProfileContainer">
            <div className="searchBoxContainer">
              <FiSearch className="searchIcon" />
              <input type="text" placeholder="Search" className="searchInput" />
            </div>
          </div>

          <div className="advisor-edit-article-main">
            <div>
              <div className="article-card">
                <div className="card-content">
                  <input
                    type="text"
                    className="title-input"
                    value="Lorem nja jaledu orem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusm"
                  />
                  <textarea
                    className="description-input"
                    value="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam empor incididunt ut labore et dolore magna aliqua. Ut enim ad minim venia Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                  />
                  <input
                    type="text"
                    className="tags-input"
                    value="#abcshj #lorem #ipsum"
                  />
                </div>
                <div className="card-image">
                  <img src={VideoImg} alt="Article" />
                </div>
                <button className="delete-button">
                  <img
                    src={DeleteIcon}
                    alt="Delete"
                    onClick={handleOpenModal}
                  />
                </button>
              </div>
              <div className="article-card">
                <div className="card-content">
                  <input
                    type="text"
                    className="title-input"
                    value="Lorem nja jaledu orem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusm"
                  />
                  <textarea
                    className="description-input"
                    value="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam empor incididunt ut labore et dolore magna aliqua. Ut enim ad minim venia Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
                  />
                  <input
                    type="text"
                    className="tags-input"
                    value="#abcshj #lorem #ipsum"
                  />
                </div>
                <div className="card-image">
                  <img src={VideoImg} alt="Article" />
                </div>
                <button className="delete-button">
                  <img
                    src={DeleteIcon}
                    alt="Delete"
                    onClick={handleOpenModal}
                  />
                </button>
              </div>
            </div>
            <button className="add-button">
              <FiPlus /> Add
            </button>

            <div className="buttons">
              <button className="cancel-button">Cancel</button>
              <button className="save-button">Save</button>
            </div>
          </div>
        </div>
      </div>

      <Modal isOpen={isModalOpen} onClose={handleCloseModal} />
    </div>
  );
};

export default EditArticle;
