import React, { useState } from "react";
import AdvisorSidebar from "../../../components/AdvisorSidebar/AdvisorSidebar";

import BackArrowImg from "../../../assets/Images/AdvisorList/Vector.png";
import { useHistory } from "react-router-dom";

import AdvisorProfileAddService from "../../../components/Advisors/AdvisorProfileAddService/AdvisorProfileAddService";

const EditAdvisorProfileInfo = () => {
  const [isEditing, setIsEditing] = useState(false);
  const history = useHistory();

  const handleSave = (formData) => {
    console.log("Saved data:", formData);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  return (
    <div className="advisor-profile-pagemainflex">
      <AdvisorSidebar />
      <div className="advisor-profile-rightsidedatashow">
        <div className="topBar">
          <button className="backButton">
            <img src={BackArrowImg} alt="back arrow" onClick={() => history.goBack()} />
          </button>
        </div>
        <AdvisorProfileAddService onSave={handleSave} onCancel={handleCancel} />
      </div>
    </div>
  );
};

export default EditAdvisorProfileInfo;
