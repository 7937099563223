import React, { useEffect, useState } from 'react'
import './AdvisorIndiviual.scss'
import shorupan from '../../assets/shorupan.svg'
import MenuList from '../../components/MenuList/MenuList'
import Location from '../../components/Location/Location'
import { useHistory, useParams } from 'react-router-dom';
import axios from 'axios'
import AdvisorSidebar from '../../components/AdvisorSidebar/AdvisorSidebar'





export default function AdvisorIndiviual() {
    const { id } = useParams();
    const history = useHistory()
    const [planbvalue, setplanbvalue] = useState([])
    const [globalseacrhadviser, setglobalseacrhadviser] = useState('')
    useEffect(() => {
        planb()
        setglobalseacrhadviser('')
    }, [id])
    const planb = () => {


        axios.get(
            `https://api.retired.app/retired/user/get/userprofiles?userType=advisor`
        ).then(({ data }) => {
            if (data.status) {
                setplanbvalue(data?.data)
                console.log("4534534534534534534", data?.data)
                //setdatalist(data?.data);

            }
            else {
                setplanbvalue([])
            }
        });
    }
    return (
        <div className='pagemainflex'>
            {/* <MenuList /> */}
            <AdvisorSidebar/>

            <div className="rightsidedatashow">
                <Location />
                <div className='adInivial'>
                    <div className='titlediv'>
                        <div className='pagetitle'>{id}</div>
                        <div className='para'>{id === "individuals" ? ` (${planbvalue?.length})` : "(52)"}</div>
                    </div>
                    <div className='topInputSearchsection'>
                        <input placeholder='Search Services By Name..'
                            value={globalseacrhadviser}
                            onChange={(e) => setglobalseacrhadviser(e.target.value)}
                        />
                        <div className='dropadinviualselect'>
                            <div className='nameListtitle'>Specialty</div>
                            <select>
                                <option>All Assets</option>
                            </select>
                        </div>

                        <div className='dropadinviualselect'>
                            <div className='nameListtitle'>Specialty</div>
                            <select>
                                <option>All Assets</option>
                            </select>
                        </div>

                    </div>
                    <div className='linedatalist'></div>


                    <div className="advesorcard">
                        {
                            id === "individuals" ?

                                <div className="advisorItemlist">
                                    {
                                        planbvalue?.filter((dT) => dT.firstName?.toLowerCase()?.includes(globalseacrhadviser.toLowerCase()))?.map(item => {

                                            return (
                                                <div className="ad-card"
                                                  
                                                >
                                                    <img src={item?.profilePic} />
                                                    <div className="name">{item?.firstName} {item?.lastName}</div>
                                                    <div className="level">{item?.state}</div>

                                                    <div className='insidecart'>
                                                        <div className="item-inside">
                                                            <div className="value">{item?.connectedUserCount}</div>
                                                            <div className="valueName">Clients</div>
                                                        </div>
                                                        <div className="item-inside">
                                                            <div className="value"
                                                                style={{
                                                                    textAlign: "center"
                                                                }}
                                                            >{item?.totalUserplanCount}</div>
                                                            <div className="valueName">Planb</div>
                                                        </div>
                                                        <div className="item-inside"
                                                            style={{
                                                                justifyContent: "end"
                                                            }}
                                                        >
                                                            <div className="value"
                                                            // style={{
                                                            //     color: "green"
                                                            // }}
                                                            >₹0.00</div>
                                                            <div className="valueName"
                                                                style={{
                                                                    textAlign: "end"
                                                                }}
                                                            >AUM </div>
                                                        </div>
                                                    </div>
                                                    <div className="profilebtn"
                                                      onClick={() => history.push(`/advisors/individuals/${item?.email}`)}
                                                    >See Profile</div>
                                                    <div className="seletbtn"
                                                    // onClick={() => callapi(item)}
                                                    >Select</div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                :
                                <div className="advisorItemlist">
                                    {
                                        [1, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2]?.map(item => {

                                            return (
                                                <div className="ad-card">
                                                    <img src={shorupan} />
                                                    <div className="name">Shorupan Pirakaspathy</div>
                                                    <div className="level">ARN: 12341234</div>

                                                    <div className='insidecart'>
                                                        <div className="item-inside">
                                                            <div className="value">0</div>
                                                            <div className="valueName">Clients</div>
                                                        </div>
                                                        <div className="item-inside">
                                                            <div className="value"
                                                                style={{
                                                                    textAlign: "center"
                                                                }}
                                                            >0</div>
                                                            <div className="valueName">Retirements</div>
                                                        </div>
                                                        <div className="item-inside"
                                                            style={{
                                                                justifyContent: "end"
                                                            }}
                                                        >
                                                            <div className="value"
                                                            // style={{
                                                            //     color: "green"
                                                            // }}
                                                            >₹0.00</div>
                                                            <div className="valueName"
                                                                style={{
                                                                    textAlign: "end"
                                                                }}
                                                            >AUM </div>
                                                        </div>
                                                    </div>
                                                    <div className="profilebtn">See Profile</div>
                                                    <div className="seletbtn"
                                                    // onClick={() => callapi(item)}
                                                    >Select</div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                        }

                    </div>

                </div>
            </div>
        </div>
    )
}
