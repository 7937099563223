import React, { useCallback, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./SocialContent.scss";
import AdvisorSidebar from "../../../../components/AdvisorSidebar/AdvisorSidebar";
import { FiPlus } from "react-icons/fi";
import PotImg from "../../../../assets/Images/AdvisorList/pot.png";
import FilterImg from "../../../../assets/Images/AdvisorList/settings.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Filter from "./Filter/Filter";
import AdvisorSidebarLatest from "../../../../components/AdvisorSidebarLatest";
import Header from "../../AdvisorProfile/Header";

const filterTabs = [
  "All",
  "Lorem Ipsum",
  "Investment Type",
  "Products and Services",
  "Investment Type",
  "Products and Services",
  "Investment Type",
];

const weeks = [
  {
    week: "Week 1",
    posts: Array(6).fill({
      title: "Lorem ipsum dolor sit amet...",
      date: "12 Jan 2024",
    }),
  },
  {
    week: "Week 2",
    posts: Array(3).fill({
      title: "dolor sit amet...",
      date: "12 Jan 2024",
    }),
  },
  {
    week: "Week 3",
    posts: Array(6).fill({
      title: "sit amet...",
      date: "12 Jan 2024",
    }),
  },
];

const settings = {
  infinite: false,
  speed: 200,
  slidesToShow: 4.5,
  slidesToScroll: 1,
  centerMode: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4.5,
        slidesToScroll: 1,
        infinite: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const SocialContent = () => {
  const [activeTab, setActiveTab] = useState("Social Content");
  const [selectedMonth, setSelectedMonth] = useState("January, 2024");
  const history = useHistory();
  const [fopen, setFopen] = useState(false);
  const [filteredContent, setFilteredContent] = useState([]);
  const [filters, setFilters] = useState({
    companies: [],
    products: [],
    subProducts: [],
    keywords: [],
  });

  const handleFilterApply = useCallback((data) => {
    setFilters(data);
    setFopen(false);
  }, []);

  useEffect(() => {
    const filterContent = () => {
      const filtered = weeks
        .map((week) => ({
          ...week,
          posts: week.posts.filter((post) => {
            const { companies, products, subProducts, keywords } = filters;
            const content = post.title.toLowerCase();
            const matchesCompanies = companies.length
              ? companies.some((company) =>
                  content.includes(company.toLowerCase())
                )
              : true;
            const matchesProducts = products.length
              ? products.some((product) =>
                  content.includes(product.toLowerCase())
                )
              : true;
            const matchesSubProducts = subProducts.length
              ? subProducts.some((subProduct) =>
                  content.includes(subProduct.toLowerCase())
                )
              : true;
            const matchesKeywords = keywords.length
              ? keywords.some((keyword) =>
                  content.includes(keyword.toLowerCase())
                )
              : true;

            return (
              matchesCompanies &&
              matchesProducts &&
              matchesSubProducts &&
              matchesKeywords
            );
          }),
        }))
        .filter((week) => week.posts.length > 0);
      setFilteredContent(filtered);
    };

    filterContent();
  }, [filters]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    if (tab === "Social Content") {
      history.push("/advisor-library/social-content");
    } else {
      history.push("/advisor-library/articles");
    }
  };

  const handleFilterClick = useCallback((e) => {
    setFopen(true);
  }, []);
  const handleCloseFilter = () => {
    setFopen(false);
  };

  const handleRemoveFilter = (type, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [type]: prevFilters[type].filter((item) => item !== value),
    }));
  };

  const areFilterActive = () => {
    const isActive = Object.values(filters).some((filter) => filter.length > 0);
    return isActive;
  };

  return (
    <>
      <div className="advisor-chat-pagemainflex">
        <Header />
        <div className="flexContainers">
          <AdvisorSidebarLatest />

          <div className="advisor-chat-advisor-rightsidedatashow">
            <div className="social-content-header">
              <div className="tab-container">
                {/* <button
                  className={`tab ${
                    activeTab === "Articles/Blogs" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("Articles/Blogs")}
                >
                  Articles/Blogs
                </button> */}
                <button
                  className={`tab ${
                    activeTab === "Social Content" ? "active" : ""
                  }`}
                  onClick={() => handleTabClick("Social Content")}
                >
                  Social Content
                </button>
              </div>
              <div className="filter-container" onClick={handleFilterClick}>
                <img src={FilterImg} alt="Filter" className="filter-icon" />
                <span className="filter-text">Filter</span>
              </div>
            </div>

            {areFilterActive() && (
              <div className="applied-filters">
                {Object.keys(filters).map((type) =>
                  filters[type].map((filter) => (
                    <div key={filter} className="filter-tab">
                      {filter}
                      <span
                        className="remove-filter"
                        onClick={() => handleRemoveFilter(type, filter)}
                      >
                        x
                      </span>
                    </div>
                  ))
                )}
              </div>
            )}

            <div className="month-selector">
              <select
                value={selectedMonth}
                onChange={(e) => setSelectedMonth(e.target.value)}
              >
                <option value="January, 2024">January, 2024</option>
                <option value="January, 2024">January, 2024</option>
                <option value="January, 2024">January, 2024</option>
              </select>
            </div>

            <div className="social-content-main">
              {filteredContent.map((week, weekIndex) => (
                <div key={weekIndex} className="week-section">
                  <h3 className="week-title">{week.week}</h3>
                  <Slider {...settings}>
                    {week.posts.map((post, postIndex) => (
                      <div key={postIndex} className="post-card">
                        <img src={PotImg} alt="Post" className="post-image" />
                        <div className="post-content">
                          <p className="post-title">{post.title}</p>
                          <p className="post-date">{post.date}</p>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              ))}
            </div>

            <button className="create-new-button">
              <FiPlus size={20} /> CREATE NEW
            </button>
          </div>
        </div>
      </div>
      {fopen && (
        <Filter
          handleCancel={handleCloseFilter}
          handleApply={handleFilterApply}
        />
      )}
    </>
  );
};

export default SocialContent;
