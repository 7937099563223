import React, { useEffect, useState } from "react";
import styles from "./product.module.scss";
import { useParams } from "react-router-dom";
import axios from "axios";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { CiFacebook } from "react-icons/ci";
import { useHistory } from "react-router-dom";
import HeaderNav from "../../components/HeaderNav";

const AdvisorProfileLayout = ({ child }) => {
  let history = useHistory();
  const { email } = useParams();
  const [userData, setUserData] = useState(null);
  const [loadingUserData, setLoadingUserData] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (email) {
      setLoadingUserData(true);
      axios
        .get(
          `https://comms.globalxchange.io/retired/user/get/userprofiles?email=${email}`
        )
        .then(({ data }) => {
          if (data.status) {
            setUserData(data?.data[0]);
            setLoadingUserData(false);
          } else {
            setLoadingUserData(false);
          }
        });
    }
  }, [email]);

  return (
    <div style={{ zoom: "125%" }}>
      <HeaderNav userData={userData} lastpage={true} history={history} />
      <div>{child}</div>
      <div
        className={styles.footerNav}
        style={{ backgroundColor: `#${userData?.colorCode}` }}
      >
        <div className={styles.footerLeft}>
          <img
            src={require("./phone.png").default}
            alt=""
            className={styles.phoneIcon}
          />
          <div className={styles.phoneNo}>{userData?.phoneNumber}</div>
        </div>

        <div
          style={{
            width: "7.125rem",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <FaInstagram
            className={styles.socialLinks}
            onClick={() => window.open(userData?.instagram, "_blank")}
          />
          <FaLinkedinIn
            className={styles.socialLinks}
            onClick={() => window.open(userData?.linkedIn, "_blank")}
          />
          <CiFacebook
            className={styles.socialLinks}
            onClick={() => window.open(userData?.facebook, "_blank")}
          />
        </div>
      </div>
    </div>
  );
};

export default AdvisorProfileLayout;
