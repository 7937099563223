import React, { useState } from "react";
import "./AdvisorChat.scss";
import { FiFilter, FiSearch } from "react-icons/fi";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import MenuList from "../../../components/MenuList/MenuList";

import AmazonImg from "../../../assets/Images/AdvisorList/amazon.png";
import DocImg from "../../../assets/Images/AdvisorList/doc.png";
import FilterImg from "../../../assets/Images/AdvisorList/settings.png";
import sendIconImg from "../../../assets/Images/AdvisorList/sendIcon.png";
import AdvisorSidebar from "../../../components/AdvisorSidebar/AdvisorSidebar";

const reports = [
  {
    id: 1,
    title: "New report from Harvey",
    idText: "Abc-report-125898",
    image: DocImg,
  },
  {
    id: 2,
    title: "Amazon had a stock boom...",
    idText: "Abc-report-125898",
    image: AmazonImg,
  },
  {
    id: 3,
    title: "New report from Harvey",
    idText: "Abc-report-125898",
    image: DocImg,
  },
];

const AdvisorChat = () => {
  const [globalseacrhadviser, setglobalseacrhadviser] = useState("");
  const [message, setMessage] = useState("");

  const modules = {
    toolbar: {
      container: "#toolbar",
    },
  };

  const handleSendMessage = () => {
    console.log("Message sent:", message);
    setMessage("");
  };

  return (
    <div className="advisor-chat-pagemainflex">
      {/* <MenuList /> */}
      <AdvisorSidebar />

      <div className="advisor-chat-advisor-rightsidedatashow">
        <div className="searchProfileContainer">
          <div className="searchBoxContainer">
            <FiSearch className="searchIcon" />
            <input type="text" placeholder="Search" className="searchInput" />
          </div>
          <div className="profileTextContainer">
            <span className="profileName">Harvey Bing,</span>
            <a href="/profile" className="viewProfile">
              View profile
            </a>
          </div>
        </div>

        <div className="chatContainer">
          <div className="chatBox">
            <div className="chatContent">
              <div className="message">
                <div className="messageText">
                  Lorem Ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisil?
                </div>
                <div className="messageDate">14/03, 9:03 PM</div>
              </div>
              <div className="message">
                <div className="messageText">
                  Lorem Ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisil?
                </div>
                <div className="messageDate">14/03, 9:03 PM</div>
              </div>
            </div>
            <div className="chatInput">
              <ReactQuill
                value={message}
                onChange={setMessage}
                placeholder="Type Here"
                className="editor"
                modules={modules}
              />
              <div id="toolbar" className="quill-toolbar">
                <div className="toolbar-buttons-left">
                  <button className="ql-bold"></button>
                  <button className="ql-italic"></button>
                  <button className="ql-underline"></button>
                  <button className="ql-link"></button>
                  <button className="ql-list" value="ordered"></button>
                  <button className="ql-list" value="bullet"></button>
                </div>

                <button className="sendButton" onClick={handleSendMessage}>
                  <img src={sendIconImg} alt="Send" />
                </button>
              </div>
            </div>
          </div>
          <div className="reportsBox">
            <div className="reportsHeader">
              <h3 className="reportsTitle">Reports</h3>
              <div className="filterIcon">
                <img src={FilterImg} alt="Filter" />
              </div>
            </div>
            <div className="reportSection">
              <h4 className="reportCategory">Recently updated</h4>
              {reports.slice(0, 2).map((report) => (
                <div key={report.id} className="reportItem">
                  <img
                    src={report.image}
                    alt={report.title}
                    className="reportImage"
                  />
                  <div className="reportDetails">
                    <div className="reportTitle">{report.title}</div>
                    <div className="reportId">{report.idText}</div>
                  </div>
                </div>
              ))}
            </div>
            <div className="reportSection">
              <h4 className="reportCategory">Approved</h4>
              {reports.map((report) => (
                <div key={report.id} className="reportItem">
                  <img
                    src={report.image}
                    alt={report.title}
                    className="reportImage"
                  />
                  <div className="reportDetails">
                    <div className="reportTitle">{report.title}</div>
                    <div className="reportId">{report.idText}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvisorChat;
