import React from "react";
import { card1 } from "../../../../assets/data/cards";
import Rotatecard from "../../../3dcard/Rotatecard";
import "./sixthsection.css";

const Sixthsection = () => {
  return (
    <section className="sixth-section">
      <div>
        {card1.map((eachcard) => {
          return <Rotatecard eachcard={eachcard} />;
        })}
      </div>
    </section>
  );
};

export default Sixthsection;
