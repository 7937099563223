import React from "react";
import AmazonImg from "../../../../assets/Images/AdvisorList/amazon.png";
import DocImg from "../../../../assets/Images/AdvisorList/doc.png";
import FilterImg from "../../../../assets/Images/AdvisorList/settings.png";
import AdvisorSidebar from "../../../../components/AdvisorSidebar/AdvisorSidebar";
import { FiSearch } from "react-icons/fi";
import Person1Img from '../../../../assets/Images/AdvisorList/Ellipse.png'

import ClientCard from "../../../../components/Advisors/ClientCard/ClientCard";
import './ClientList.scss'
import axios from "axios";
import { useQuery } from "react-query";

const ClientList = () => {
  const reports = [
    {
      id: 1,
      title: "New report from Harvey",
      idText: "Abc-report-125898",
      image: DocImg,
    },
    {
      id: 2,
      title: "Amazon had a stock boom...",
      idText: "Abc-report-125898",
      image: AmazonImg,
    },
    {
      id: 3,
      title: "New report from Harvey",
      idText: "Abc-report-125898",
      image: DocImg,
    },
  ];

  // const clients = [
  //   { id: 1, name: 'Harvey Bing', other: '12345ABcd', experience: 'Experience lorem Lorem ipsum dolor sit amet, consectetur adipiscing elit', image: Person1Img, time: '11:04 am' },
  //   { id: 2, name: 'Harvey Bing', other: '12345ABcd', experience: 'Experience lorem Lorem ipsum dolor sit amet, consectetur adipiscing elit', image: Person1Img, time: '11:04 am' },
  //   { id: 3, name: 'Harvey Bing', other: '12345ABcd', experience: 'Experience lorem Lorem ipsum dolor sit amet, consectetur adipiscing elit', image: Person1Img, time: '11:04 am' },
  //   { id: 4, name: 'Harvey Bing', other: '12345ABcd', experience: 'Experience lorem Lorem ipsum dolor sit amet, consectetur adipiscing elit', image: Person1Img, time: '11:04 am' },
  //   { id: 5, name: 'Harvey Bing', other: '12345ABcd', experience: 'Experience lorem Lorem ipsum dolor sit amet, consectetur adipiscing elit', image: Person1Img, time: '11:04 am' },
  //   { id: 6, name: 'Harvey Bing', other: '12345ABcd', experience: 'Experience lorem Lorem ipsum dolor sit amet, consectetur adipiscing elit', image: Person1Img, time: '11:04 am' },
  //   { id: 7, name: 'Harvey Bing', other: '12345ABcd', experience: 'Experience lorem Lorem ipsum dolor sit amet, consectetur adipiscing elit', image: Person1Img, time: '11:04 am' },
  //   { id: 8, name: 'Harvey Bing', other: '12345ABcd', experience: 'Experience lorem Lorem ipsum dolor sit amet, consectetur adipiscing elit', image: Person1Img, time: '11:04 am' },
  // ];

  const fetchProfileData = async () => {
    try {
      const {data} = await axios.get('https://api.retired.app/retired/useradvisor/get?advisoremail=shorupandomains@gmail.com');

      console.log('data ==>', data);

      return data;
    } catch (error) {
      console.error('Error fetching profile data:', error);
    }
  }
  

  const {
    data: clientdata,
    error,
    isLoading,
  } = useQuery("clientData", fetchProfileData, {
    staleTime: 3 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  console.log(clientdata, 'clientdata -->')
  
  if(isLoading) return <div>Loading...</div>
  if(error) return  <div>Error while fetching data</div>
  
  const clientData = clientdata?.data[0];
  // console.log(clientData, 'clientData -->')
  // console.log(profileCount, 'profilecount -->')

  const clients = clientdata?.data.map(client => {
    const profile = client.ProfileCount[0];
    return {
      id: client._id,
      name: profile ? `${profile?.firstName} ${profile?.lastName}` : 'Unknown',
      experience: profile ? profile?.phoneNumber : 'No Experience Info',
      image: profile ? profile?.profilePic : Person1Img,
      time: profile ? new Date(profile?.date).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'}) : 'No Time Info',
    };
  }) || [];


  return (
    <div className="advisor-chat-pagemainflex">
      {/* <MenuList /> */}
      <AdvisorSidebar />

      <div className="advisor-client-list-rightsidedatashow">
        <div className="searchProfileContainer">
          <div className="searchBoxContainer">
            <FiSearch className="searchIcon" />
            <input type="text" placeholder="Search" className="searchInput" />
          </div>
        </div>

        <div className="client-main">
        <div className="client-child">
        {clients.map((client, index) => (

           <ClientCard  key={index} {...client}  />
        ))} 
        </div>
        <div className="reportsBox client-child">
          <div className="reportsHeader">
            <h3 className="reportsTitle">Notifications</h3>
            <div className="filterIcon">
              <img src={FilterImg} alt="Filter" />
            </div>
          </div>
          <div className="reportSection">
            <h4 className="reportCategory">Today</h4>
            {reports.slice(0, 2).map((report) => (
              <div key={report.id} className="reportItem">
                <img
                  src={report.image}
                  alt={report.title}
                  className="reportImage"
                />
                <div className="reportDetails">
                  <div className="reportTitle">{report.title}</div>
                  <div className="reportId">{report.idText}</div>
                </div>
              </div>
            ))}
          </div>
          <div className="reportSection">
            <h4 className="reportCategory">Last Week</h4>
            {reports.map((report) => (
              <div key={report.id} className="reportItem">
                <img
                  src={report.image}
                  alt={report.title}
                  className="reportImage"
                />
                <div className="reportDetails">
                  <div className="reportTitle">{report.title}</div>
                  <div className="reportId">{report.idText}</div>
                </div>
              </div>
            ))}
          </div>
          <div className="reportSection">
            <h4 className="reportCategory">Older</h4>
            {reports.slice(0, 2).map((report) => (
              <div key={report.id} className="reportItem">
                <img
                  src={report.image}
                  alt={report.title}
                  className="reportImage"
                />
                <div className="reportDetails">
                  <div className="reportTitle">{report.title}</div>
                  <div className="reportId">{report.idText}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default ClientList;
