import React, { useContext } from "react";
import { sidebar } from "../../assets/data/cards";

// import Navbar from "../Navbar/Navbar";
import Navbar from "../Navbar/Navbar/Navbar";
import "./landing.css";
import Firstsection from "./sections/Firstsection/Firstsection";
import Secondsection from "./sections/Secondsection/Secondsection";

import { bonds } from "../../assets/Func/navigations";
import { Globalcontext } from "../../contexts/GlobalContext";
import Plansection from "./sections/Plansection/Plansection";
import secondsectionside from "../../assets/Images/Home/secondsectionside.jpg";
// import Calculatorsection from "./sections/Calculatorsection/Calculatorsection";

const Landing = () => {
  const { sideBarStatus } = useContext(Globalcontext);
  return (
    <Firstsection />
  );
};

export default Landing;

const Eachsidebarcard = ({ data }) => {
  let logoImage =
    require(`../../assets/Images/rotatecards/${data.image}.svg`).default;
  return (
    <div className="eachsidebarcard" onClick={bonds}>
      <div>
        <img src={logoImage} alt={data.image} />
      </div>
      <div>
        <div>{data.title}</div>
        <div>{data.slogan}</div>
      </div>
    </div>
  );
};
