import React, { useState } from "react";
import image from "../../../../assets/Images/AdvisorList/blog.png";
import { FaImage } from "react-icons/fa6";
import "./editcontent.scss";
import AdvisorSidebar from "../../../../components/AdvisorSidebar/AdvisorSidebar";
import { useHistory } from "react-router-dom";
import AdvisorSidebarLatest from "../../../../components/AdvisorSidebarLatest";
import Header from "../../AdvisorProfile/Header";

const EditContent = () => {
  const [activeTab, setActiveTab] = useState("Articles/Blogs");
  const history = useHistory();

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    if (tab === "Articles/Blogs") {
      history.push("/advisor-library/articles");
    } else {
      history.push("/advisor-library/social-content");
    }
  };
  return (
    <div className="advisor-profile-pagemainflex">
      <Header />
      <div className="flexContainers">
        <AdvisorSidebarLatest />
        <div className="advisor-profile-rightsidedatashow advisor-add-article">
          <div className="article-page-header">
            <div className="tab-container">
              <button
                className={`tab ${
                  activeTab === "Articles/Blogs" ? "active" : ""
                }`}
                onClick={() => handleTabClick("Articles/Blogs")}
              >
                Articles/Blogs
              </button>
              <button
                className={`tab ${
                  activeTab === "Social Content" ? "active" : ""
                }`}
                onClick={() => handleTabClick("Social Content")}
              >
                Social Content
              </button>
            </div>
          </div>
          <div className="container-cust">
            <div className="image-overlay">
              <img
                src={image}
                alt="Stock market display"
                className="background-image"
              />
              <div className="overlay">
                <FaImage style={{ fontSize: "30px" }} />
                <p>Drag and drop image here or Choose image</p>
              </div>
            </div>
            <div className="text-inputs">
              <input
                type="text"
                placeholder="Need Basic Finance Planning"
                className="heading-input"
              />
              <textarea
                placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam empor incididunt ut labore et dolore magna aliqua. Ut enim ad minim venia Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore magna aliqua. Ut enim ad minimlore magna aliqua. Ut enim ad minimlore magna aliqua."
                className="paragraph-input"
              ></textarea>
            </div>
            <div className="action">
              <button className="btn">Save</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditContent;
