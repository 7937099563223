import React, { useEffect } from "react";
import styles from "./header.module.scss";

const HeaderNavFirms = ({ firmsProfile, lastpage, history }) => {
  return (
    <div
      className={styles.headerNav}
      style={{ backgroundColor: `#${firmsProfile?.colorcode}` }}
    >
      <img
        src={firmsProfile?.logo}
        alt={firmsProfile?.name}
        className={styles.leftLogo}
        onClick={(e) => {
          if (lastpage) {
            history.goBack();
          } else {
            window.scrollTo(0, 0);
          }
        }}
      />
      {/* <div className={styles.leftLogo}></div> */}
      <div className={styles.rightMenus}>
        <div>
          <a
            href="#about"
            onClick={(e) => {
              lastpage && history.goBack();
            }}
          >
            About
          </a>
        </div>
        <div>
          <a
            href="#products"
            onClick={(e) => {
              lastpage && history.goBack();
            }}
          >
            Products
          </a>
        </div>
        <div>
          <a
            href="#blog"
            onClick={(e) => {
              lastpage && history.goBack();
            }}
          >
            Blog
          </a>
        </div>
      </div>
    </div>
  );
};

export default HeaderNavFirms;
