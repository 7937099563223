import React, { useRef } from "react";
import classNames from "./custom.module.scss";
import { FaPlus } from "react-icons/fa6";

const CustomInput = ({
  title,
  titleSize,
  placeholder,
  availableContainer,
  available,
  stateValue,
  setState,
  name,
}) => {
  return (
    <div className={classNames.customInput}>
      {title && (
        <div
          className={classNames.title}
          style={{ fontSize: titleSize ? titleSize : "" }}
        >
          {title}
        </div>
      )}
      <div className={classNames.inputContainer}>
        <input
          type="text"
          placeholder={placeholder}
          onChange={(event) => {
            if (name) {
              setState((prev) => {
                return { ...prev, [name]: event?.target?.value };
              });
            } else {
              setState(event?.target?.value);
            }
          }}
        />
        {availableContainer && (
          <div
            className={classNames.availableContainer}
            style={{ background: available ? "" : "black" }}
          >
            {available ? "Available" : "Not Available"}
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomInput;

export const CustomInputMultiple = ({
  title,
  titleSize,
  placeholder,
  stateValue,
  setState,
  name,
  func,
}) => {
  const inputRef = useRef(null);
  return (
    <div className={classNames.customInput}>
      {title && (
        <div
          className={classNames.title}
          style={{ fontSize: titleSize ? titleSize : "" }}
        >
          {title}
        </div>
      )}
      <div className={classNames.inputContainer}>
        <input
          ref={inputRef}
          type="text"
          placeholder={placeholder}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              const inputValue = event?.target?.value;
              func(inputValue);
              inputRef.current.value = "";
            }
          }}
        />
        <div
          className={classNames.addTag}
          onClick={() => {
            const inputValue = inputRef.current.value;
            func(inputValue);
            inputRef.current.value = "";
          }}
        >
          <FaPlus />
        </div>
      </div>
      <div className={classNames.allTags}>
        {Array.isArray(stateValue[name]) &&
          stateValue[name]?.length > 0 &&
          stateValue[name]?.map((eachTag, index) => {
            return <div key={eachTag + index}>{eachTag}</div>;
          })}
      </div>
    </div>
  );
};

export const CustomInputWithBox = ({
  title,
  titleSize,
  placeholder,
  stateValue,
  setState,
  name,
  inputText,
}) => {
  return (
    <div className={classNames.customInput}>
      {title && (
        <div
          className={classNames.title}
          style={{ fontSize: titleSize ? titleSize : "" }}
        >
          {title}
        </div>
      )}
      <div
        className={classNames.inputContainer}
        style={{ border: inputText ? "1px solid #e5e5e5" : "" }}
      >
        {inputText && <div className={classNames.inputText}>{inputText}</div>}
        <input
          type="text"
          placeholder={placeholder}
          onChange={(event) => {
            if (name) {
              setState((prev) => {
                return { ...prev, [name]: event?.target?.value };
              });
            } else {
              setState(event?.target?.value);
            }
          }}
          style={{ border: inputText ? "none" : "" }}
        />
      </div>
    </div>
  );
};
