import React, { useEffect, useState } from "react";
import classNames from "./productsandservices.module.scss";

import { FaCircleChevronRight } from "react-icons/fa6";
import { IoMdAdd } from "react-icons/io";
import axios from "axios";
import {
  NewEachCustomImageUploadcustom,
  NewMyEachCustomTextarea,
  NewUIMyEachInput,
} from "../GloabelInput";
import { toast } from "react-toastify";

const ProductsAndServices = () => {
  const allTabs = [
    "Asset Classes",
    "Sub-Asset Classes",
    "Companies",
    "Products",
    "Services",
  ];

  const [selectedTab, setSelectedTab] = useState("Asset Classes"); // default "Asset Classes"
  const [localLoading, setLocalLoading] = useState(false);
  const [allAssetClasses, setAllAssetClasses] = useState([]);
  const [allSubAssetClasses, setAllSubAssetClasses] = useState([]);
  const [allAssetClassesCompanies, setAllAssetClassesCompanies] = useState([]);
  const [allProductsClasses, setAllProductsClasses] = useState([]);
  const [allServices, setAllServices] = useState([]);
  const [allSelectedItems, setAllSelectedItems] = useState([]);

  //functions

  async function getAllSelectOneData(URL, setState) {
    try {
      let response = await axios.get(URL);

      if (response?.data?.status && response?.data?.data?.length > 0) {
        setState(response?.data?.data);
      } else {
        setState([]);
      }
      //   console.log(response, "Get Asset Classes response");
    } catch (error) {
      setState([]);
      console.log(error?.message, "Get All Select One Data error");
    }
  }

  async function addServiceNextTab(URL, name) {
    try {
      let response = await axios.post(URL, { [name]: allSelectedItems });
      if (response?.data?.status) {
        toast(response?.data?.message);
        if (selectedTab === "Asset Classes") {
          setSelectedTab("Sub-Asset Classes");
          setAllSelectedItems([]);
        } else if (selectedTab === "Sub-Asset Classes") {
          setSelectedTab("Companies");
          setAllSelectedItems([]);
        } else if (selectedTab === "Companies") {
          setSelectedTab("Products");
          setAllSelectedItems([]);
        } else if (selectedTab === "Products") {
          setSelectedTab("Services");
          setAllSelectedItems([]);
        }
      } else {
        toast(response?.data?.message);
      }

      console.log(response, "Add service to next response");
    } catch (error) {
      console.log(error?.message, "Add service to next error");
    }
  }

  function initiateFuncToNextTab() {
    if (selectedTab === "Asset Classes") {
      addServiceNextTab(
        `https://api.retired.app/retired/user/add/asset?email=${localStorage.getItem(
          "LoginAccount"
        )}`,
        "assetProfiles"
      );
    } else if (selectedTab === "Sub-Asset Classes") {
      addServiceNextTab(
        `https://api.retired.app/retired/user/add/subasset?email=${localStorage.getItem(
          "LoginAccount"
        )}`,
        "subAssetProfiles"
      );
    } else if (selectedTab === "Companies") {
      addServiceNextTab(
        `https://api.retired.app/retired/user/add/provider?email=${localStorage.getItem(
          "LoginAccount"
        )}`,
        "providerProfile"
      );
    } else if (selectedTab === "Products") {
      addServiceNextTab(
        `https://api.retired.app/retired/user/add/product?email=${localStorage.getItem(
          "LoginAccount"
        )}`,
        "productData"
      );
    }
  }

  //renderings

  useEffect(() => {
    getAllSelectOneData(
      "https://api.retired.app/retired/asset/get",
      setAllAssetClasses
    );
    getAllSelectOneData(
      "https://api.retired.app/retired/subasset/get",
      setAllSubAssetClasses
    );
    getAllSelectOneData(
      "https://api.retired.app/retired/investmentprovider/get",
      setAllAssetClassesCompanies
    );
    getAllSelectOneData(
      "https://api.retired.app/retired/product/get",
      setAllProductsClasses
    );
  }, []);

  useEffect(() => {
    getAllSelectOneData(
      `https://api.retired.app/retired/service/advisor/get?advisoremail=${localStorage.getItem(
        "LoginAccount"
      )}`,
      setAllServices
    );
  }, [localLoading]);

  return (
    <div className={classNames.productsAndServices}>
      <div className={classNames.header}>
        <div className={classNames.allTabs}>
          {allTabs?.map((eachTab, index) => {
            return (
              <div
                key={eachTab + index}
                className={selectedTab === eachTab && classNames.selectedTab}
                // onClick={() => {
                //   setSelectedTab(eachTab);
                // }}
              >
                {eachTab}
              </div>
            );
          })}
        </div>
        <div className={classNames.submitBtn} onClick={initiateFuncToNextTab}>
          <span>Save & Proceed</span>
          <FaCircleChevronRight />
        </div>
      </div>
      <div className={classNames.contentContainer}>
        {selectedTab === "Asset Classes" ? (
          <SelectProductAndServices
            title="Select the asset classes you want to promote"
            mapItems={allAssetClasses}
            mapID="asset_id"
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            allSelectedItems={allSelectedItems}
            setAllSelectedItems={setAllSelectedItems}
          />
        ) : selectedTab === "Sub-Asset Classes" ? (
          <SelectProductAndServices
            title="Select the sub-asset classes you want to promote"
            mapItems={allSubAssetClasses}
            mapID="sub_asset_id"
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            allSelectedItems={allSelectedItems}
            setAllSelectedItems={setAllSelectedItems}
          />
        ) : selectedTab === "Companies" ? (
          <SelectProductAndServices
            title="Select the companies  you want to promote"
            mapItems={allAssetClassesCompanies}
            mapID="investment_provider_id"
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            allSelectedItems={allSelectedItems}
            setAllSelectedItems={setAllSelectedItems}
          />
        ) : selectedTab === "Products" ? (
          <SelectProductAndServices
            title="Select the products you want to promote"
            mapItems={allProductsClasses}
            mapID="product_id"
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            allSelectedItems={allSelectedItems}
            setAllSelectedItems={setAllSelectedItems}
          />
        ) : selectedTab === "Services" ? (
          <ServicesTab
            title="Which services are you going to offer?"
            mapItems={allServices}
            mapID="service_advisor_id"
            selectedTab={selectedTab}
            setLocalLoading={setLocalLoading}
            setSelectedTab={setSelectedTab}
            allSelectedItems={allSelectedItems}
            setAllSelectedItems={setAllSelectedItems}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default ProductsAndServices;

const SelectProductAndServices = ({
  title,
  mapItems,
  mapID,
  selectedTab,
  setSelectedTab,
  allSelectedItems,
  setAllSelectedItems,
}) => {
  return (
    <>
      {title && <div className={classNames.title}>{title}</div>}
      <div className={classNames.allSelects}>
        {Array.isArray(mapItems) && mapItems?.length > 0
          ? mapItems?.map((eachItem, index) => {
              return (
                <div
                  className={
                    allSelectedItems?.includes(eachItem[mapID]) &&
                    classNames.selectedServices
                  }
                  onClick={() => {
                    setAllSelectedItems((prev) => {
                      if (prev?.includes(eachItem[mapID])) {
                        return prev.filter((item) => item !== eachItem[mapID]);
                      } else {
                        return [...prev, eachItem[mapID]];
                      }
                    });
                  }}
                >
                  <div className={classNames.profile}>
                    {selectedTab !== "Products" && (
                      <img
                        src={
                          selectedTab === "Products"
                            ? eachItem?.productIcon
                            : eachItem?.icon
                        }
                        alt={
                          selectedTab === "Products"
                            ? eachItem?.productName
                            : eachItem?.name
                        }
                      />
                    )}
                    <div className={classNames.title}>
                      {selectedTab === "Products"
                        ? eachItem?.productName
                        : eachItem?.name}
                    </div>
                  </div>
                  <div className={classNames.desc}>
                    {selectedTab === "Products"
                      ? eachItem?.description
                      : eachItem?.description}
                  </div>
                </div>
              );
            })
          : ""}
      </div>
    </>
  );
};

const ServicesTab = ({
  title,
  mapItems,
  mapID,
  selectedTab,
  setLocalLoading,
  setSelectedTab,
}) => {
  const [newServiceAdvisor, setNewServiceAdvisor] = useState({});
  const [allSelectedItems, setAllSelectedItems] = useState([]);
  const [createServiceAdvisor, setCreateServiceAdvisor] = useState(false);

  //functions

  async function addServiceToAdvisor() {
    newServiceAdvisor.advisoremail = localStorage.getItem("LoginAccount");
    try {
      let response = await axios.post(
        "https://api.retired.app/retired/service/advisor/add",
        newServiceAdvisor
      );

      if (response?.data?.status) {
        toast(response?.data?.message);
        setLocalLoading((prev) => !prev);
        setCreateServiceAdvisor(false);
        setNewServiceAdvisor({});
      } else {
        toast(response?.data?.message);
      }

      //   console.log(response, "Add service to advisor response");
    } catch (error) {
      console.log(error?.message, "Add service to advisor error");
    }
  }

  return (
    <div className={classNames.servicesTab}>
      {title && <div className={classNames.title}>{title}</div>}
      <div className={classNames.allSelects}>
        {Array.isArray(mapItems) && mapItems?.length > 0
          ? mapItems?.map((eachItem, index) => {
              return (
                <div
                  className={
                    allSelectedItems?.includes(eachItem[mapID]) &&
                    classNames.selectedServices
                  }
                  onClick={() => {
                    setAllSelectedItems((prev) => {
                      if (prev?.includes(eachItem[mapID])) {
                        return prev.filter((item) => item !== eachItem[mapID]);
                      } else {
                        return [...prev, eachItem[mapID]];
                      }
                    });
                  }}
                >
                  <div className={classNames.profile}>
                    {selectedTab !== "Products" && (
                      <img
                        src={
                          selectedTab === "Products"
                            ? eachItem?.productIcon
                            : eachItem?.icon
                        }
                        alt={
                          selectedTab === "Products"
                            ? eachItem?.productName
                            : eachItem?.name
                        }
                      />
                    )}
                    <div className={classNames.title}>
                      {selectedTab === "Products"
                        ? eachItem?.productName
                        : eachItem?.name}
                    </div>
                  </div>
                  <div className={classNames.desc}>
                    {selectedTab === "Products"
                      ? eachItem?.description
                      : eachItem?.description}
                  </div>
                </div>
              );
            })
          : ""}
      </div>
      {!createServiceAdvisor && (
        <div
          className={classNames.createSelectBtn}
          onClick={() => setCreateServiceAdvisor(true)}
        >
          <IoMdAdd />
          <div>Add new service</div>
        </div>
      )}
      {createServiceAdvisor && (
        <div className={classNames.createService}>
          <div className={classNames.flexDiv}>
            <NewEachCustomImageUploadcustom
              title="Upload"
              name="icon"
              placeholder="Profile picture "
              stateValue={newServiceAdvisor}
              setState={setNewServiceAdvisor}
              topTitle={false}
              btnText="Upload Icon"
            />
            <NewUIMyEachInput
              title="Name"
              placeholder="Enter name..."
              // stateValue={client?.name}
              name="name"
              stateValue={newServiceAdvisor}
              setState={setNewServiceAdvisor}
              topTitle="true"
            />
            <NewUIMyEachInput
              title="Cost"
              placeholder="Enter cost.."
              // stateValue={client?.name}
              name="cost"
              stateValue={newServiceAdvisor}
              setState={setNewServiceAdvisor}
              topTitle="true"
            />
          </div>
          <NewMyEachCustomTextarea
            title="Description"
            // stateValue={patientlist?.name}
            name="description"
            placeholder="Enter description..."
            stateValue={newServiceAdvisor}
            setState={setNewServiceAdvisor}
            topTitle="true"
          />
          <div className={classNames.btnsContainer}>
            <div
              className={classNames.coloredBtn}
              onClick={addServiceToAdvisor}
            >
              Save
            </div>
            <div
              onClick={() => {
                setCreateServiceAdvisor(false);
                setNewServiceAdvisor({});
              }}
            >
              Discard
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
