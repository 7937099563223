import React, { useState } from "react";
import "./AdvisorProfileAddService.scss";
import DeleteIcon from "../../../assets/Images/AdvisorList/trash.png";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { FiPlus } from "react-icons/fi";

import PictureImg from '../../../assets/Images/AdvisorList/picture.png';
import FileUploadImg from '../../../assets/Images/AdvisorList/upload_icon.png';
import { FaArrowRightLong } from "react-icons/fa6";
 

const AdvisorProfileAddService = ({ onSave, onCancel }) => {
  const [title, setTitle] = useState("");
  const [descriptionFile, setDescriptionFile] = useState(null);
  const [imageFile, setImageFile] = useState(null);

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleDescriptionFileChange = (e) => {
    setDescriptionFile(e.target.files[0]);
  };

  const handleImageFileChange = (e) => {
    setImageFile(e.target.files[0]);
  };

  const handleSave = () => {
    const formData = {
      title,
      descriptionFile,
      imageFile,
    };
    onSave(formData);
  };

  return (
    <div style={{ padding: "20px 40px 0" }}>
      <div className="add-existing">
        <Link>Add existing products</Link>
        <FaArrowRightLong size={18} color="#525252"/>
      </div>
      <div className="upload-component">
        <div className="header">
          <label className="product-label">Product 1</label>
          <img src={DeleteIcon} alt="delete" className="delete-icon" />
        </div>
        <div className="form-container">
          <div className="form-group title-group">
            <input
              type="text"
              placeholder="Title"
              value={title}
              onChange={handleTitleChange}
              className="title-input"
            />
          </div>
          <div className="form-desc-p">
            <div className="form-group">
              <label className="desc-label">Description</label>
              <div className="file-upload">
                <img src={FileUploadImg} alt="Upload Icon" />
                <p>Drag and drop file here or 
                  <label className="file-input-label">
                    <span> Choose file</span>
                    <input type="file" onChange={handleDescriptionFileChange} />
                  </label>
                </p>
              </div>
            </div>
            <div className="form-group">
              <div className="image-upload">
                <img src={PictureImg} alt="Image Icon" />
                <p>Drag and drop image here or <br /> 
                  <label className="file-input-label">
                    <span> Choose image</span>
                    <input type="file" onChange={handleImageFileChange} />
                  </label>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="add-one-container">
          <button className="add-one-button"><FiPlus/> Add one</button>
        </div>
      </div>
      <div className="buttons">
        <button className="cancel-button" onClick={onCancel}>
          Cancel
        </button>
        <button className="save-button" onClick={handleSave}>
          Save
        </button>
      </div>
    </div>
  );
};

export default AdvisorProfileAddService;
