import React, { useCallback, useEffect, useState } from "react";
import classNames from "./subtabs.module.scss";
import { PieChart, Pie, Sector, ResponsiveContainer } from "recharts";

const ProductsGraphAndAllocationTable = ({
  addSection,
  monthlyPremiums,
  setMonthlyPremiums,
}) => {
  const [minAndMax, setMinAndMax] = useState({ min: 0, max: 0 });
  // const [monthlyPremiums, setMonthlyPremiums] = useState([]);
  const [allProductsPie, setAllProductsPie] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);

  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );

  useEffect(() => {
    if (addSection && addSection.length > 0) {
      const months = addSection.map((section) => section.numberofmonth);
      const min = Math.min(...months);
      const max = Math.max(...months);
      setMinAndMax({ min, max });

      // Initialize an array with a length of max number of months, filled with zeros
      const premiums = Array(max).fill(0);

      // Calculate the premium for each month
      addSection.forEach((product) => {
        for (let i = 0; i < Number(product.numberofmonth); i++) {
          premiums[i] += Number(product.result);
        }
      });

      // all products pie
      let allProductsPie = [];
      addSection.forEach((product) => {
        allProductsPie.push({
          name: product?.productName?.name,
          value: product?.totalcontri ? Number(product?.totalcontri) : "",
        });
      });
      console.log(allProductsPie, "allProductsPie");
      setAllProductsPie(allProductsPie);

      setMonthlyPremiums(premiums);
    } else {
      setMonthlyPremiums([]);
    }
  }, [addSection]);

  console.log(minAndMax, "minAndMax ProductsGraphAndAllocationTable");
  console.log(
    monthlyPremiums,
    "monthlyPremiums ProductsGraphAndAllocationTable"
  );
  console.log(addSection, "addSection ProductsGraphAndAllocationTable");

  return (
    <div className={classNames.productsAndAllocation}>
      <div>
        <div className={classNames.title}>Pie chart</div>
        {/* <ResponsiveContainer width="100%" height="100%"> */}
        <PieChart width={600} height={600}>
          <Pie
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            data={allProductsPie}
            cx={200}
            cy={200}
            innerRadius={60}
            outerRadius={80}
            fill="#8884d8"
            dataKey="value"
            onMouseEnter={onPieEnter}
          />
        </PieChart>
        {/* </ResponsiveContainer> */}
      </div>
      <div className={classNames.allocationTable}>
        <div className={classNames.title}>Allocation table</div>
        <table>
          <thead>
            <tr>
              <th>Month</th>
              <th>Premium</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(monthlyPremiums) &&
              monthlyPremiums?.length > 0 &&
              monthlyPremiums.map((premium, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    {typeof premium === "number" ? premium.toFixed(2) : "0.00"}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProductsGraphAndAllocationTable;

function renderActiveShape(props) {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >{`PV ${value}`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {`(Rate ${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
}
