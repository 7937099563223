import React, { useEffect, useState } from 'react'
import './Location.scss'
import axios from 'axios'
import OutsideClickHandler from 'react-outside-click-handler';
import { useHistory, useParams } from 'react-router-dom';
export default function Location() {
    let history = useHistory()
    const [country, setcountry] = useState([])
    const [toggle, settoggle] = useState(false)
    const { pname } = useParams();
    const [active, setactive] = useState({
        name: "India",
        img: "https://raw.githubusercontent.com/hampusborgos/country-flags/master/png250px/in.png"
    })
    useEffect(() => {
        planb()

    }, [])
    const planb = () => {


        axios.get(
            `https://api.retired.app/coin/vault/countries/data/get`
        ).then(({ data }) => {
            if (data.status) {
                setcountry(data?.countries)

                //setdatalist(data?.data);

            }

        });
    }

    const dropdownselect = (e) => {
        settoggle(false)
        setactive({
            name: e?.name,
            img: e?.image
        })
    }


    console.log("4534534534534534534", country)
    return (
        <div className='locationPage'>
            {
                !pname == "" &&

                <div className='backbutton'
                    onClick={() => history.push('/advisors/individuals')}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="16" viewBox="0 0 19 16" fill="none">
                        <path d="M16.1096 7.08192C14.1814 5.08317 11.4259 4.03354 7.91519 3.96152V0.395436C7.91519 0.235549 7.81861 0.0906901 7.67059 0.029726C7.52258 -0.0304217 7.35316 0.0020084 7.23999 0.115217L0.115769 7.23944C-0.0385895 7.39379 -0.0385895 7.64474 0.115769 7.7991L7.23999 14.9233C7.3532 15.0373 7.52418 15.0713 7.67141 15.0088C7.81942 14.9478 7.91601 14.803 7.91601 14.6431V11.0841C15.869 11.1997 18.243 14.8156 18.2659 14.8513C18.3395 14.97 18.4678 15.0389 18.6023 15.0389C18.6356 15.0389 18.6696 15.0341 18.7037 15.0254C18.8723 14.9803 18.9918 14.8314 18.9981 14.6565C19.0037 14.4776 19.1216 10.2047 16.1096 7.08192Z" fill="#E5E5E5" />
                    </svg>
                </div>
            }
            <div className='droplocation'
                onClick={() => settoggle(true)}
            >
                <img

                    style={{
                        marginRight: "10px"
                    }}
                    src={active?.img} />
                {active?.name}
                <svg
                    style={{
                        marginLeft: "5px"
                    }}
                    xmlns="http://www.w3.org/2000/svg" width="10" height="8" viewBox="0 0 10 8" fill="none">
                    <path opacity="0.25" d="M0.178099 1.50893L4.30209 7.59337C4.61931 8.13554 5.31725 8.13554 5.69791 7.59337L9.8219 1.50893C10.266 0.846288 9.8219 0.00286102 9.12401 0.00286102L0.875987 0.00286102C0.178098 -0.0573721 -0.266046 0.846245 0.178099 1.50893Z" fill="#110F0D" />
                </svg>
            </div>
            {
                toggle &&
                <OutsideClickHandler
                    onOutsideClick={() => {
                        settoggle(false)
                    }}
                >


                    <div className='dropdownlist'

                    >
                        {
                            country?.map(item => {
                                return (
                                    <div className='droplocation'
                                        style={{
                                            marginBottom: "2rem"
                                        }}
                                        onClick={() => dropdownselect(item)}
                                    >
                                        <img

                                            style={{
                                                marginRight: "10px"
                                            }}
                                            src={item?.image} />
                                        {/* <svg

             
                xmlns="http://www.w3.org/2000/svg" width="14" height="19" viewBox="0 0 14 19" fill="none">
                <path d="M6.21653 18.7521C6.31976 18.907 6.49358 19 6.67969 19C6.86579 19 7.03961 18.907 7.14285 18.7521C8.46019 16.7761 10.4005 14.3358 11.7527 11.8539C12.8338 9.86942 13.3594 8.1769 13.3594 6.67969C13.3594 2.99651 10.3629 0 6.67969 0C2.99651 0 0 2.99651 0 6.67969C0 8.1769 0.52558 9.86942 1.60672 11.8539C2.95784 14.3339 4.90189 16.7802 6.21653 18.7521ZM6.67969 1.11328C9.749 1.11328 12.2461 3.61037 12.2461 6.67969C12.2461 7.98609 11.7649 9.50438 10.775 11.3213C9.60955 13.4606 7.94638 15.6307 6.67969 17.4551C5.41318 15.631 3.7499 13.4607 2.58433 11.3213C1.59448 9.50438 1.11328 7.98609 1.11328 6.67969C1.11328 3.61037 3.61037 1.11328 6.67969 1.11328Z" fill="#110F0D" />
                <path d="M6.67969 10.0195C8.52128 10.0195 10.0195 8.52128 10.0195 6.67969C10.0195 4.8381 8.52128 3.33984 6.67969 3.33984C4.8381 3.33984 3.33984 4.8381 3.33984 6.67969C3.33984 8.52128 4.8381 10.0195 6.67969 10.0195ZM6.67969 4.45312C7.90741 4.45312 8.90625 5.45196 8.90625 6.67969C8.90625 7.90741 7.90741 8.90625 6.67969 8.90625C5.45196 8.90625 4.45312 7.90741 4.45312 6.67969C4.45312 5.45196 5.45196 4.45312 6.67969 4.45312Z" fill="#110F0D" />
            </svg> */}
                                        {item?.name}

                                    </div>
                                )
                            })
                        }

                    </div>

                </OutsideClickHandler>}


        </div>
    )
}
