import React, { useState } from 'react'
import MenuList from '../../../components/MenuList/MenuList'
import Location from '../../../components/Location/Location'
import './AdvisorList.scss'

import Person1Img from '../../../assets/Images/AdvisorList/Ellipse.png'
import AdvisorCard from '../../../components/Advisors/AdvisorCard/AdvisorCard'
import { FiSearch } from 'react-icons/fi'
import AdvisorSidebar from '../../../components/AdvisorSidebar/AdvisorSidebar'



const advisors = [
    { id: 1, name: 'Harvey Bing', other: '12345ABcd', experience: 'Experience lorem Lorem ipsum dolor sit amet, consectetur adipiscing elit', place: 'Place', image: Person1Img },
    { id: 2, name: 'Harvey Bing', other: '12345ABcd', experience: 'Experience lorem Lorem ipsum dolor sit amet, consectetur adipiscing elit', place: 'Place', image: Person1Img },
    { id: 3, name: 'Harvey Bing', other: '12345ABcd', experience: 'Experience lorem Lorem ipsum dolor sit amet, consectetur adipiscing elit', place: 'Place', image: Person1Img },
    { id: 4, name: 'Harvey Bing', other: '12345ABcd', experience: 'Experience lorem Lorem ipsum dolor sit amet, consectetur adipiscing elit', place: 'Place', image: Person1Img },
    
  ];


const AdvisorList = () => {
    const [globalseacrhadviser, setglobalseacrhadviser] = useState('')
    const [acceptedAdvisors, setAcceptedAdvisors] = useState([1]);

    const yourAdvisors = advisors.filter(advisor => acceptedAdvisors.includes(advisor.id));

    const otherAdvisors = advisors.filter(advisor => !acceptedAdvisors.includes(advisor.id));


  return (
    <div className='advisor-list-pagemainflex'>
            {/* <MenuList /> */}
            <AdvisorSidebar />

            <div className="advisor-list-rightsidedatashow">
                {/* <Location /> */}
                <div className="advisor-list-searchProfileContainer">
          <div className="searchBoxContainer">
            <FiSearch className="searchIcon" />
            <input type="text" placeholder="Search" className="searchInput" />
          </div>
          {/* <div className="profileTextContainer">
            <span className="profileName">Harvey Bing,</span>
            <a href="/profile" className="viewProfile">View profile</a>
          </div> */}
        </div>
                <div className='advisor-list-adInivial'>
                {/* <div className='topInputSearchsection'>
                        <input placeholder='Search Services By Name..'
                            value={globalseacrhadviser}
                            onChange={(e) => setglobalseacrhadviser(e.target.value)}
                        />
                        <div className='dropadinviualselect'>
                            <div className='nameListtitle'>Specialty</div>
                            <select>
                                <option>All Assets</option>
                            </select>
                        </div>

                        <div className='dropadinviualselect'>
                            <div className='nameListtitle'>Specialty</div>
                            <select>
                                <option>All Assets</option>
                            </select>
                        </div>

                    </div> */}
                    <div className="advisorSections">
                        {acceptedAdvisors.length === 0 ? (
                            <div className="advisorList">
                                {advisors.map((advisor, index) => (
                                    <AdvisorCard key={index} {...advisor} />
                                ))}
                            </div>
                        ) : (
                            <>
                                <div className="yourAdvisors">
                                    <h3 className="sectionTitle">Your Advisor</h3>
                                    {yourAdvisors.map((advisor, index) => (
                                        <AdvisorCard key={index} {...advisor} isAccepted={true} />
                                    ))}
                                </div>
                                <div className="otherAdvisors">
                                    <h3 className="sectionTitle">Others</h3>
                                    {otherAdvisors.map((advisor, index) => (
                                        <AdvisorCard key={index} {...advisor} />
                                    ))}
                                </div>
                            </>
                        )}
                    </div>


                    </div>
            </div>

    </div>
  )
}

export default AdvisorList