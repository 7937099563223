import React from "react";
import {
  NewUIMyEachInput,
  NewDateAndPicker,
  NewEachCustomDropdown,
} from "../GloabelInput";
import axios from "axios";

export default function UserProfileView({ client, setclient }) {
  //functions

  //renderings

  return (
    <div className="editing-page">
      <div className="editingSectionTop notClickable">
        <div className="textSectionTop">
          <div className="textheader">Basic Information</div>
          <div
            style={{
              marginTop: "3.5rem",
            }}
          >
            <img
              src={client?.profilePic}
              alt={client?._id}
              style={{ height: "80px", width: "80px", borderRadius: "50%" }}
            />
          </div>
        </div>
        <div className="eddintright">
          <div className="Innerinnputsection">
            <div className="subdivision">
              <NewUIMyEachInput
                title="First name"
                placeholder="First Name"
                // stateValue={client?.name}
                name="firstName"
                stateValue={client}
                setState={setclient}
                topTitle="true"
              />
            </div>
            <div className="subdivision">
              <NewUIMyEachInput
                title="Last Name"
                placeholder="Last Name"
                // stateValue={client?.name}
                name="lastName"
                stateValue={client}
                setState={setclient}
                topTitle="true"
              />
            </div>
          </div>
          <div className="Innerinnputsection">
            <div className="subdivision">
              <NewUIMyEachInput
                title="Phone number"
                placeholder="Phone number"
                // stateValue={client?.name}
                name="phoneNumber"
                stateValue={client}
                setState={setclient}
                topTitle="true"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="editingSectionTop notClickable">
        <div className="textSectionTop">
          <div className="textheader">Personal Information</div>
        </div>
        <div className="eddintright">
          <div className="Innerinnputsection">
            <div className="subdivision">
              <NewDateAndPicker
                title="Birthday"
                placeholder="DD/MM/YYYY"
                stateValue={client}
                setState={setclient}
                name="birthday"
                topTitle="true"
              />
            </div>
            <div className="subdivision">
              <NewUIMyEachInput
                title="Marital Status"
                placeholder="Marital Status"
                // stateValue={client?.name}
                name="martialStatus"
                stateValue={client}
                setState={setclient}
                topTitle="true"
              />
            </div>
          </div>
          <div className="Innerinnputsection">
            <div className="subdivision">
              <NewUIMyEachInput
                title="Children"
                placeholder="Number of Children"
                // stateValue={client?.name}
                name="children"
                stateValue={client}
                setState={setclient}
                topTitle="true"
              />
            </div>

            <div className="subdivision">
              <NewEachCustomDropdown
                dropdown={["Male", "Female"]}
                name="gender"
                title="Gender"
                placeholder="Select Gender"
                Select
                Gender
                stateValue={client}
                setState={setclient}
                topTitle="true"
                type="single"
              />
            </div>
          </div>
          <div className="Innerinnputsection">
            <div className="subdivision">
              <NewUIMyEachInput
                title="Pan number"
                placeholder="Enter Pan number"
                // stateValue={client?.name}
                name="PANnumber"
                stateValue={client}
                setState={setclient}
                topTitle="true"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="editingSectionTop notClickable">
        <div className="textSectionTop">
          <div className="textheader">Address information</div>
        </div>
        <div className="eddintright">
          <div className="Innerinnputsection">
            <div className="subdivision">
              <NewUIMyEachInput
                title="Address"
                placeholder="Enter Address"
                // stateValue={client?.name}
                name="address"
                stateValue={client}
                setState={setclient}
                topTitle="true"
              />
            </div>
            <div className="subdivision">
              <NewUIMyEachInput
                title="City"
                placeholder="Enter City"
                // stateValue={client?.name}
                name="city"
                stateValue={client}
                setState={setclient}
                topTitle="true"
              />
            </div>
          </div>
          <div className="Innerinnputsection">
            <div className="subdivision">
              <NewUIMyEachInput
                title="State"
                placeholder="Enter State"
                // stateValue={client?.name}
                name="state"
                stateValue={client}
                setState={setclient}
                topTitle="true"
              />
            </div>

            <div className="subdivision">
              <NewUIMyEachInput
                title="Pincode"
                placeholder="Enter Pincode"
                // stateValue={client?.name}
                name="pincode"
                stateValue={client}
                setState={setclient}
                topTitle="true"
              />
            </div>
          </div>

          <div className="Innerinnputsection">
            <div className="subdivision">
              <NewUIMyEachInput
                title="Country"
                placeholder="Enter Pincode"
                // stateValue={client?.name}
                name="countryName"
                stateValue={client}
                setState={setclient}
                topTitle="true"
              />
            </div>

            <div className="subdivision"></div>
          </div>
        </div>
      </div>

      <div className="editingSectionTop notClickable">
        <div className="textSectionTop">
          <div className="textheader">Brokerage information</div>
        </div>
        <div className="eddintright">
          <div className="Innerinnputsection">
            <div className="subdivision">
              <NewUIMyEachInput
                title="Brokerage"
                placeholder="Enter Brokerage"
                // stateValue={client?.name}
                name="brokerAgeName"
                stateValue={client}
                setState={setclient}
                topTitle="true"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
