import React, { useState } from "react";
import crossIcon from "../../../../../../assets/fi-bs-cross-small.svg";
import "./MultiSelect.scss";

const MultiSelect = ({
  label,
  options,
  selectedOptions,
  setSelectedOptions,
}) => {
  const [inputValue, setInputValue] = useState("");
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    setIsDropdownVisible(true);
  };

  const handleOptionClick = (option) => {
    if (!selectedOptions.includes(option)) {
      setSelectedOptions([...selectedOptions, option]);
    }
    setInputValue("");
    setIsDropdownVisible(false);
  };

  const handleRemoveOption = (optionToRemove) => {
    setSelectedOptions(
      selectedOptions.filter((option) => option !== optionToRemove)
    );
  };

  const filteredOptions = options.filter(
    (option) =>
      option.toLowerCase().includes(inputValue.toLowerCase()) &&
      !selectedOptions.includes(option)
  );

  return (
    <div className="multi-select">
      <label className="multi-select-label">{label}</label>
      <div className="input-container">
        {selectedOptions.map((option, index) => (
          <div key={index} className="chip">
            {option}
            <img
              src={crossIcon}
              alt="remove"
              onClick={() => handleRemoveOption(option)}
              className="remove-chip"
            />
          </div>
        ))}
        <input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onFocus={() => setIsDropdownVisible(true)}
          onBlur={() => setTimeout(() => setIsDropdownVisible(false), 200)}
          className="input-box"
          placeholder=""
        />
      </div>
      {isDropdownVisible && filteredOptions.length > 0 && (
        <ul className="dropdown">
          {filteredOptions.map((option, index) => (
            <li
              key={index}
              onClick={() => handleOptionClick(option)}
              className="dropdown-item"
            >
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default MultiSelect;
