import React from "react";
import "./secondsection.css";

//assets
import laptop from "../../../../assets/Images/Home/laptop.jpg";
import secondsectionside from "../../../../assets/Images/Home/secondsectionside.jpg";

const Secondsection = () => {
  return (
    <>
    </>
    // <section className="second-section">
    //   <div className="second-section-content">
    //     <div>
    //       An AI Powered Retirement Planner Which Maps Your Financial Goals Over
    //       The Best Investments In The Crypto Space
    //     </div>
    //     <div>
    //       <img src={secondsectionside} alt="secondsectionside" />
    //     </div>
    //   </div>
    // </section>
  );
};

export default Secondsection;
