import classNames from "./custom.module.scss";
import { useContext, useEffect, useRef, useState } from "react";

// import { toastify } from "../../assets/functions/toastify";
import uploadIcon from "../../assets/upload.svg";
import { areAllFieldsFilled, uploadFileFunc } from "./Fundata";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import "./Editing.scss";
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import "flatpickr/dist/themes/material_orange.css";
import profileUser from "../../assets/profileUser.svg";
import "./custom.scss";
export const NewEachCustomImageUploadcustom = ({
  title,
  topTitle,
  name,
  placeholder,
  stateValue,
  setState,
  btnText,
}) => {
  //image update form
  const fileInputRefNewUser = useRef(null);
  const [uploadFileRFQ, setUploadFileRFQ] = useState("");
  const [uploadFileRFQName, setUploadFileRFQName] = useState("");
  const [updateLoading, setUpdateLoading] = useState(false);

  return (
    <div className={classNames.eachCustomImageUpload}>
      {/* <div className={classNames.topTitle}>{title}</div> */}
      <div
        className={classNames.imageInput}
        style={{
          display: "flex",
          alignItems: "center",
          width: btnText ? "max-content" : "",
        }}
      >
        {uploadFileRFQ ? (
          <img
            onClick={() => {
              if (fileInputRefNewUser.current) {
                fileInputRefNewUser.current.click();
              }
            }}
            src={uploadFileRFQ}
            alt={uploadFileRFQName}
            style={{
              width: "7.25rem",
              height: "7.25rem",
              borderRadius: "55rem",
            }}
          />
        ) : (
          <div
            className={classNames.emptyProfilecustom}
            onClick={() => {
              if (fileInputRefNewUser.current) {
                fileInputRefNewUser.current.click();
              }
            }}
          >
            <img
              src={profileUser}
              alt="uploadIcon"
              style={{
                animation: updateLoading ? "heartbeat 1s infinite" : "",
              }}
            />
          </div>
        )}
        <input
          className={classNames.uploadNewPicPlanB}
          type="file"
          style={{ display: "none" }}
          onChange={async (e) => {
            let resFile = uploadFileFunc(
              e,
              setUploadFileRFQName,
              setUpdateLoading,
              "name"
            );
            console.log(await resFile, "res file link await");
            setUploadFileRFQ(await resFile);
            let profileFile = await resFile;
            setState((prev) => {
              return {
                ...prev,
                [name]: profileFile,
              };
            });
          }}
          ref={fileInputRefNewUser}
        />
      </div>
      {btnText && (
        <div
          className={classNames.uploadBtn}
          onClick={() => {
            if (fileInputRefNewUser.current) {
              fileInputRefNewUser.current.click();
            }
          }}
        >
          {btnText}
        </div>
      )}
    </div>
  );
};

export const MyEachCustomImageUpload = ({
  title,
  topTitle,
  name,
  placeholder,
  stateValue,
  setState,
}) => {
  //image update form
  const fileInputRefNewUser = useRef(null);
  const [uploadFileRFQ, setUploadFileRFQ] = useState("");
  const [uploadFileRFQName, setUploadFileRFQName] = useState("");
  const [updateLoading, setUpdateLoading] = useState(false);

  return (
    <div className={classNames.eachCustomImageUpload}>
      {topTitle && (
        <div
          className={classNames.topTitle}
          style={{
            fontWeight: "600",
            fontSize: "12px",
          }}
        >
          {title}
        </div>
      )}
      <div className={classNames.imageInput}>
        {uploadFileRFQ ? (
          <img
            style={{
              objectFit: "cover",
            }}
            src={uploadFileRFQ}
            alt={uploadFileRFQName}
            onClick={() => {
              if (fileInputRefNewUser.current) {
                fileInputRefNewUser.current.click();
              }
            }}
          />
        ) : (
          <div
            className={classNames.emptyProfile}
            onClick={() => {
              if (fileInputRefNewUser.current) {
                fileInputRefNewUser.current.click();
              }
            }}
          >
            <img
              src={uploadIcon}
              alt="uploadIcon"
              style={{
                animation: updateLoading ? "heartbeat 1s infinite" : "",
              }}
            />
          </div>
        )}
        <input
          className={classNames.uploadNewPicPlanB}
          type="file"
          style={{ display: "none" }}
          onChange={async (e) => {
            let resFile = uploadFileFunc(
              e,
              setUploadFileRFQName,
              setUpdateLoading,
              "name"
            );
            console.log(await resFile, "res file link await");
            setUploadFileRFQ(await resFile);
            let profileFile = await resFile;
            setState((prev) => {
              return {
                ...prev,
                [name]: profileFile,
              };
            });
          }}
          ref={fileInputRefNewUser}
        />
        <div
          className={classNames.imageLabel}
          style={{
            marginTop: "0.8rem",
            color: "rgba(0, 0, 0, 0.5)",
          }}
        >
          {placeholder}
        </div>
      </div>
    </div>
  );
};
export const NewMyEachCustomTextarea = ({
  title,
  topTitle,
  name,
  placeholder,
  stateValue,
  setState,
}) => {
  return (
    <div
      className={classNames.eachCustomTextarea}
      style={{
        height: "175px",
        marginBottom: "0",
        marginTop: "12px",
      }}
    >
      {topTitle && (
        <div
          style={{
            fontWeight: "600",
            color: "#110f0d",

            fontSize: "12px",
            marginBottom: "8px",
            marginTop: "0px",
          }}
          className={classNames.topTitle}
        >
          {title}
        </div>
      )}
      <textarea
        className={classNames.inputContainer}
        style={{
          maxWidth: "100%",
          padding: "0.6rem 1.5rem",
          color: "#0F0609",
          height: "150px",
          fontSize: "14px",
          margin: "0",
          paddingLeft: "10px",
        }}
        placeholder={placeholder}
        value={name ? stateValue[name] : stateValue}
        onChange={(event) => {
          if (setState) {
            if (name) {
              setState((prev) => {
                return {
                  ...prev,
                  [name]: event?.target?.value,
                };
              });
            } else {
              setState(event?.target?.value);
            }
          }
        }}
      />
    </div>
  );
};
export const NewEachCustomDropdown = ({
  title,
  dropdown,
  name,
  name2,
  stateValue,
  setState,
  topTitle,
  type,
  stateVal,
  mapVal,
  indexx,
  calendarObjField,
  eachValuee,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {});
  console.log("dropdowndropdown", dropdown);

  useEffect(() => {
    console.log(stateValue, "ljwekgfhkwjegfkjwef");
  }, [stateValue]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !dropdownRef?.current?.contains(event?.target) &&
        !inputRef?.current?.contains(event?.target)
      ) {
        setIsOpen(false);
      }
    };

    const handleDocumentClick = (event) => {
      handleClickOutside(event);
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const selectOption = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    // Perform any additional actions based on the selected option
  };
  const [countrysearch, setcountrysearch] = useState("");
  useEffect(() => {
    if (
      name === "selectedAppStaff" &&
      Array?.isArray(dropdown) &&
      dropdown?.length > 0
    ) {
      setState({
        NameOfTeam: dropdown[0]?.NameOfTeam,
        Icon: dropdown[0]?.Icon,
        TeamUsername: dropdown[0]?.TeamUsername,
      });
    }
    setcountrysearch("");
  }, [dropdown]);

  return (
    <div
      className={classNames.eachCustomDropdown}
      style={{
        // minWidth: isOpen ? "250px" : "",
        zIndex: isOpen ? "3" : "",
        height: "60px",
        minWidth: "160px",
      }}
    >
      {topTitle && (
        <div
          className={classNames.topTitle}
          style={{
            fontWeight: "600",
            color: "#110F0D",
            marginTop: "0px",
            fontSize: "12px",
            marginBottom: "8px",
          }}
        >
          {title}
        </div>
      )}
      <div
        className={classNames.inputContainer}
        onClick={() => setIsOpen(true)}
        style={{
          pointerEvents: dropdown ? "" : "none",

          maxWidth: "100%",
          padding: "1rem 1.5rem",
          color: "#0F0609",
          fontSize: "14px",
          margin: "0",

          margin: "0rem",
          paddingLeft: "10px",
          marginTop: "24px",
        }}
        ref={dropdownRef}
      >
        <span>
          <span
            style={{
              display: "flex",
              alignItems: "center",
              gap: "15px",
              fontWeight: "500",
              color: "#0f0609",
            }}
          >
            {name === "selectedAppStaff" && stateValue?.Icon ? (
              <img
                src={stateValue?.Icon}
                alt={stateValue?.NameOfTeam}
                style={{ height: "1.25rem" }}
              />
            ) : name === "gender" ? (
              !stateValue?.gender ? (
                <div
                  style={{
                    fontSize: "14px",
                    maxWidth: "100%",
                    fontWeight: "500",
                    color: "#AFAFAF",
                  }}
                >
                  Select Gender
                </div>
              ) : (
                stateValue?.gender
              )
            ) : name === "brokerName" ? (
              !stateValue?.brokerName ? (
                <div
                  style={{
                    fontSize: "14px",
                    maxWidth: "100%",
                    fontWeight: "500",
                    color: "#AFAFAF",
                  }}
                >
                  Select
                </div>
              ) : (
                stateValue?.brokerName
              )
            ) : name === "MaritalStatus" ? (
              !stateValue?.MaritalStatus ? (
                <div
                  style={{
                    fontSize: "14px",
                    maxWidth: "100%",
                    fontWeight: "500",
                    color: "#AFAFAF",
                  }}
                >
                  Select Marital Status
                </div>
              ) : (
                stateValue?.MaritalStatus
              )
            ) : name === "country" ? (
              !stateValue?.country ? (
                <div
                  style={{
                    fontSize: "14px",
                    maxWidth: "100%",
                    fontWeight: "500",
                    color: "#AFAFAF",
                  }}
                >
                  Select Country
                </div>
              ) : (
                stateValue?.country
              )
            ) : name === "state" ? (
              !stateValue?.state ? (
                <div
                  style={{
                    fontSize: "14px",
                    maxWidth: "100%",
                    fontWeight: "500",
                    color: "#0f0609",
                  }}
                >
                  Select State
                </div>
              ) : (
                stateValue?.state
              )
            ) : (
              <>
                {name === "selectedAppStaff" && stateValue?.NameOfTeam
                  ? stateValue?.NameOfTeam
                  : stateValue?.NameOfTeam && !name === "IndustriesId"
                  ? stateValue?.NameOfTeam
                  : selectedOption
                  ? selectedOption
                  : name === "IndustriesId"
                  ? "Click to select industries"
                  : title
                  ? title
                  : name === "selectedAppStaff"
                  ? "Select an Option"
                  : name === "catfallunder"
                  ? "Select an Option"
                  : dropdown[0]?.NameOfTeam}
              </>
            )}
          </span>
          <span
            onClick={(e) => {
              e.stopPropagation();
              setIsOpen(!isOpen);
              return;
            }}
          >
            {isOpen ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}
          </span>
        </span>
        {isOpen && (
          <ul className="dropdown-list">
            {title === "Gender" && dropdown?.length > 0 ? (
              dropdown?.map((each, index) => {
                return (
                  <li
                    onClick={(event) => {
                      event.stopPropagation();
                      // selectOption(
                      //   eachitem[mapVal?.name] +
                      //   " (" +
                      //   eachitem[mapVal?.name1] +
                      //   ")"
                      // );
                      setState({
                        ...stateValue,
                        gender: each,
                      });
                      setIsOpen(false);
                    }}
                    key={index}
                  >
                    {each}
                  </li>
                );
              })
            ) : title === "Marital Status" && dropdown?.length > 0 ? (
              dropdown?.map((each, index) => {
                return (
                  <li
                    onClick={(event) => {
                      event.stopPropagation();
                      // selectOption(
                      //   eachitem[mapVal?.name] +
                      //   " (" +
                      //   eachitem[mapVal?.name1] +
                      //   ")"
                      // );
                      setState({
                        ...stateValue,
                        MaritalStatus: each,
                      });
                      setIsOpen(false);
                    }}
                    key={index}
                  >
                    {each}
                  </li>
                );
              })
            ) : title === "Which therapy category does this fall under?" &&
              dropdown?.length > 0 ? (
              dropdown?.map((each, index) => {
                return (
                  <li
                    onClick={(event) => {
                      event.stopPropagation();
                      // selectOption(
                      //   eachitem[mapVal?.name] +
                      //   " (" +
                      //   eachitem[mapVal?.name1] +
                      //   ")"
                      // );
                      setState({
                        ...stateValue,
                        theraphyCategoryId: each?._id,
                        theraphyCategoryName: each?.name,
                      });
                      setIsOpen(false);
                    }}
                    key={index}
                  >
                    {each?.name}
                  </li>
                );
              })
            ) : title === "What room category does this room fall under?" &&
              dropdown?.length > 0 ? (
              dropdown?.map((each, index) => {
                return (
                  <li
                    onClick={(event) => {
                      event.stopPropagation();
                      // selectOption(
                      //   eachitem[mapVal?.name] +
                      //   " (" +
                      //   eachitem[mapVal?.name1] +
                      //   ")"
                      // );
                      setState({
                        ...stateValue,
                        rooms: each?.roomcategoryid,
                        roomsName: each?.name,
                      });
                      setIsOpen(false);
                    }}
                    key={index}
                  >
                    {each?.name}
                  </li>
                );
              })
            ) : title === "Which location will this be sold at ?" ||
              (title === "At which location is this kitchen item served?" &&
                dropdown?.length > 0) ? (
              dropdown?.map((each, index) => {
                return (
                  <li
                    onClick={(event) => {
                      event.stopPropagation();
                      // selectOption(
                      //   eachitem[mapVal?.name] +
                      //   " (" +
                      //   eachitem[mapVal?.name1] +
                      //   ")"
                      // );
                      setState({
                        ...stateValue,
                        location: each?._id,
                        locationName: each?.name,
                      });
                      setIsOpen(false);
                    }}
                    key={index}
                  >
                    {each?.name}
                  </li>
                );
              })
            ) : title === "Which category does this product fall under?" &&
              dropdown?.length > 0 ? (
              dropdown?.map((each, index) => {
                return (
                  <li
                    onClick={(event) => {
                      event.stopPropagation();
                      // selectOption(
                      //   eachitem[mapVal?.name] +
                      //   " (" +
                      //   eachitem[mapVal?.name1] +
                      //   ")"
                      // );
                      setState({
                        ...stateValue,
                        catfallunder: each?.productcategoryid,
                        catfallunderName: each?.name,
                      });
                      setIsOpen(false);
                    }}
                    key={index}
                  >
                    {each?.name}
                  </li>
                );
              })
            ) : title === "What is the parent service type?" &&
              dropdown?.length > 0 ? (
              dropdown?.map((each, index) => {
                return (
                  <li
                    onClick={(event) => {
                      event.stopPropagation();
                      // selectOption(
                      //   eachitem[mapVal?.name] +
                      //   " (" +
                      //   eachitem[mapVal?.name1] +
                      //   ")"
                      // );
                      setState({
                        ...stateValue,
                        constultandid: each?.consultationtypeid,
                        constultandName: each?.name,
                      });
                      setIsOpen(false);
                    }}
                    key={index}
                  >
                    {each?.name}
                  </li>
                );
              })
            ) : title === "Which brand makes this product?" &&
              dropdown?.length > 0 ? (
              dropdown?.map((each, index) => {
                return (
                  <li
                    onClick={(event) => {
                      event.stopPropagation();
                      // selectOption(
                      //   eachitem[mapVal?.name] +
                      //   " (" +
                      //   eachitem[mapVal?.name1] +
                      //   ")"
                      // );
                      setState({
                        ...stateValue,
                        brandsproduct: each?.productbrandid,
                        brandsproductName: each?.name,
                      });
                      setIsOpen(false);
                    }}
                    key={index}
                  >
                    <img src={each?.icon} />
                    {each?.name}
                  </li>
                );
              })
            ) : title ===
                "Which staff member do you want to make a specialist?" &&
              dropdown?.length > 0 ? (
              dropdown?.map((each, index) => {
                return (
                  <li
                    onClick={(event) => {
                      event.stopPropagation();
                      // selectOption(
                      //   eachitem[mapVal?.name] +
                      //   " (" +
                      //   eachitem[mapVal?.name1] +
                      //   ")"
                      // );
                      setState({
                        ...stateValue,
                        spaceName:
                          each?.TeamDetails?.[0]?.PlayerDetails?.[0]?.name,
                        spaceid: each?.teamplayerid,
                      });
                      setIsOpen(false);
                    }}
                    key={index}
                  >
                    <img
                      src={
                        each?.TeamDetails?.[0]?.PlayerDetails?.[0]?.ProfilePic
                      }
                    />
                    {each?.TeamDetails?.[0]?.PlayerDetails?.[0]?.name}
                  </li>
                );
              })
            ) : title === "Select Country" && dropdown?.length > 0 ? (
              <>
                <input
                  placeholder="Search..."
                  onClick={() => toggleDropdown(true)}
                  value={countrysearch}
                  style={{
                    color: "#7587A7",
                    background: "#F5F7F9",
                    border: "1px solid #D1D7E1",
                    height: "35px",
                    padding: "0 1.3rem",
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "22px",
                    width: "100%",
                    borderRadius: "6px",
                    marginBottom: "10px",
                  }}
                  onChange={(e) => setcountrysearch(e.target.value)}
                />
                {dropdown
                  ?.filter((temp) =>
                    temp?.name
                      ?.toLowerCase()
                      ?.includes(countrysearch?.toLowerCase())
                  )
                  ?.map((eachitem, index) => {
                    console.log("08x8Yhgzcxjzxc", eachitem);
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(eachitem[mapVal?.name]);
                          setState({
                            ...stateValue,
                            [name]: eachitem?.name,
                            flag: eachitem?.flag,
                          });
                        }}
                        key={eachitem[mapVal?.name] + index}
                      >
                        {eachitem?.name}
                      </li>
                    );
                  })}
              </>
            ) : title === "Which location will this specialist work at?" &&
              dropdown?.length > 0 ? (
              dropdown?.map((each, index) => {
                return (
                  <li
                    onClick={(event) => {
                      event.stopPropagation();
                      // selectOption(
                      //   eachitem[mapVal?.name] +
                      //   " (" +
                      //   eachitem[mapVal?.name1] +
                      //   ")"
                      // );
                      setState({
                        ...stateValue,
                        locationid: each?._id,
                        locationName: each?.name,
                      });
                      setIsOpen(false);
                    }}
                    key={index}
                  >
                    {each?.name}
                  </li>
                );
              })
            ) : title === "Select specialist" && dropdown?.length > 0 ? (
              dropdown?.map((each, index) => {
                return (
                  <li
                    onClick={(event) => {
                      event.stopPropagation();
                      // selectOption(
                      //   eachitem[mapVal?.name] +
                      //   " (" +
                      //   eachitem[mapVal?.name1] +
                      //   ")"
                      // );
                      console.log(each, "lkwheflkwhfw");
                      setState({
                        ...stateValue,
                        speicalistId: each?.specialistid,
                        speicalistName:
                          each?.TeamDetails[0]?.PlayerDetails[0]?.name,
                      });
                      setIsOpen(false);
                    }}
                    key={index}
                  >
                    {each?.TeamDetails[0]?.PlayerDetails[0]?.name}
                  </li>
                );
              })
            ) : title === "Which location will this service be offered at?" &&
              dropdown?.length > 0 ? (
              dropdown?.map((each, index) => {
                return (
                  <li
                    onClick={(event) => {
                      event.stopPropagation();
                      // selectOption(
                      //   eachitem[mapVal?.name] +
                      //   " (" +
                      //   eachitem[mapVal?.name1] +
                      //   ")"
                      // );
                      setState({
                        ...stateValue,
                        locationid: each?._id,
                        locationName: each?.name,
                      });
                      setIsOpen(false);
                    }}
                    key={index}
                  >
                    {each?.name}
                  </li>
                );
              })
            ) : title === "Select the timezone" && dropdown?.length > 0 ? (
              dropdown?.map((eachitem, index) => {
                return (
                  <li
                    onClick={(event) => {
                      event.stopPropagation();
                      selectOption(
                        eachitem[mapVal?.name] +
                          " (" +
                          eachitem[mapVal?.name1] +
                          ")"
                      );
                      setState({
                        ...stateValue,
                        TimeZone: eachitem?.UTCDifference,
                        TimeZoneAbbrevation: eachitem?.Abbreviation,
                      });
                    }}
                    key={eachitem[mapVal?.name] + index}
                  >
                    {eachitem[mapVal?.name] +
                      " (" +
                      eachitem[mapVal?.name1] +
                      ")"}
                  </li>
                );
              })
            ) : type === "obj" && dropdown?.length > 0 ? (
              dropdown?.map((eachitem, index) => {
                return (
                  <li
                    onClick={(event) => {
                      event.stopPropagation();
                      selectOption(eachitem[mapVal?.name]);
                      if (name2) {
                        setState({
                          ...stateValue,
                          [name]: eachitem[stateVal?.name1],
                          [name2]: eachitem[stateVal?.name2],
                        });
                      } else {
                        setState({
                          ...stateValue,
                          [name]: eachitem[stateVal],
                        });
                      }
                    }}
                    key={eachitem[mapVal?.name] + index}
                  >
                    {mapVal?.image && (
                      <img
                        src={eachitem[mapVal?.image]}
                        alt={
                          name2 ? eachitem[stateVal?.name1] : eachitem[stateVal]
                        }
                      />
                    )}
                    {mapVal?.subName ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "3px",
                        }}
                      >
                        <div style={{ fontSize: "1.5rem", fontWeight: "510" }}>
                          {eachitem[mapVal?.name]}
                        </div>
                        <div style={{ fontSize: "1rem", fontWeight: "410" }}>
                          {eachitem[mapVal?.subName]}
                        </div>
                      </div>
                    ) : (
                      eachitem[mapVal?.name]
                    )}
                  </li>
                );
              })
            ) : (name === "Gender" ||
                name === "groupType" ||
                type === "single") &&
              dropdown?.length > 0 ? (
              dropdown?.map((eachitem, index) => {
                return (
                  <li
                    onClick={(event) => {
                      event.stopPropagation();
                      selectOption(eachitem);
                      setState({
                        ...stateValue,
                        [name]: eachitem,
                      });
                    }}
                    key={eachitem + index}
                  >
                    {eachitem}
                  </li>
                );
              })
            ) : name === "IndustriesId" && dropdown?.length > 0 ? (
              dropdown?.map((eachitem, index) => {
                return (
                  <li
                    onClick={(event) => {
                      event.stopPropagation();
                      selectOption(eachitem?.Name);
                      setState({
                        ...stateValue,
                        [name]: eachitem?._id,
                      });
                    }}
                    key={eachitem?.Name + index}
                  >
                    {eachitem?.Name}
                  </li>
                );
              })
            ) : name === "defaultDropdown" && dropdown?.length > 0 ? (
              dropdown?.map((eachitem, index) => {
                return (
                  <li
                    onClick={(event) => {
                      event.stopPropagation();
                      selectOption(eachitem);
                      setState(eachitem);
                    }}
                    key={index}
                  >
                    {eachitem}
                  </li>
                );
              })
            ) : (
              dropdown?.length > 0 &&
              dropdown?.map((eachitem, index) => {
                return (
                  <li
                    onClick={(event) => {
                      event.stopPropagation();
                      selectOption(eachitem?.NameOfTeam);
                      if (name === "teamUsername") {
                        setState((prev) => {
                          return {
                            ...prev,
                            [name]: eachitem?.TeamUsername,
                          };
                        });
                      } else if (name === "selectedAppStaff") {
                        setState({
                          NameOfTeam: eachitem?.NameOfTeam,
                          Icon: eachitem?.Icon,
                          TeamUsername: eachitem?.TeamUsername,
                        });
                      } else {
                        setState({
                          ...stateValue,
                          [name]: {
                            NameOfTeam: eachitem?.NameOfTeam,
                            Icon: eachitem?.Icon,
                          },
                        });
                      }
                    }}
                    key={eachitem?.NameOfTeam + index}
                  >
                    <img src={eachitem?.Icon} alt={eachitem?.NameOfTeam} />
                    {eachitem?.NameOfTeam}
                  </li>
                );
              })
            )}
          </ul>
        )}
      </div>
    </div>
  );
};
export const NewUIMyEachInput = ({
  title,
  topTitle,
  name,
  placeholder,
  stateValue,
  setState,
}) => {
  return (
    <div
      className={classNames.eachCustomInput}
      style={{
        //pointerEvents: setState ? "" : "none",
        margin: "0",
      }}
    >
      {topTitle && (
        <div
          style={{
            fontWeight: "600",
            color: "#110F0D",
            marginTop: "0px",
            fontSize: "12px",
            marginBottom: "8px",
          }}
          className={classNames.topTitle}
        >
          {title}
        </div>
      )}
      <input
        className={classNames.inputContainer}
        style={{
          maxWidth: "100%",
          padding: "1rem 1.5rem",
          color: "#0F0609",
          fontSize: "14px",
          margin: "0",
          paddingLeft: "10px",
        }}
        placeholder={placeholder}
        value={name ? stateValue[name] : stateValue}
        onChange={(event) => {
          if (name) {
            setState((prev) => {
              return {
                ...prev,
                [name]: event?.target?.value,
              };
            });
          } else {
            setState(event?.target?.value);
          }
        }}
      />
    </div>
  );
};

export const NewDateAndPicker = ({
  title,
  placeholder,
  name,
  stateValue,
  setState,
  mandatory,
  topTitle,
}) => {
  const datepickerRef = useRef(null);
  const [isOpen, setIsOpen] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !dropdownRef?.current?.contains(event?.target) &&
        !inputRef?.current?.contains(event?.target)
      ) {
        setIsOpen(false);
      }
    };

    const handleDocumentClick = (event) => {
      handleClickOutside(event);
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const selectOption = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    // Perform any additional actions based on the selected option
  };
  useEffect(() => {
    if (datepickerRef.current) {
      const options = {
        dateFormat: "d/m/Y",
        // Other options here
        onChange: function (selectedDates, dateStr, instance) {
          // console.log("Selected start date:", dateStr, datepickerRef.current);
          var today = new Date();
          var dd = String(today.getDate()).padStart(2, "0");
          var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
          var yyyy = today.getFullYear();

          today = dd + "/" + mm + "/" + yyyy;
          // document.write(today);

          var selectedday = dateStr?.split("/");
          let reulut =
            selectedday?.[1] + "/" + selectedday?.[0] + "/" + selectedday?.[2];
          var todaydays = today?.split("/");
          let reuluttoday =
            todaydays?.[1] + "/" + todaydays?.[0] + "/" + todaydays?.[2];

          const selectedDate = new Date(reulut);

          const currentDate = new Date(reuluttoday);
          console.log("czxczxc", selectedDate, "hasd", today);
          if (name == "Dateofjoining") {
            setState((prev) => {
              return { ...prev, [name]: dateStr };
            });
          } else {
            // const parseDate = (dateString) => {
            //   const parts = dateString.split('/');
            //   return new Date(`${parts[2]}-${parts[1]}-${parts[0]}`);
            // };
            // const validateDate = (inputDate) => {
            //   console.log("cxczxczxc",inputDate)
            //       const selectedDateObj = parseDate(inputDate);
            //       console.log("cxczxczxc1",selectedDateObj)
            //       const today = new Date();

            //       if (!isValidDate(selectedDateObj)) {
            //         setMessage('Invalid date format.');
            //       } else if (selectedDateObj > today) {
            //         setMessage('Selected date cannot be in the future.');
            //       } else {
            //         setMessage('');
            //       }
            //     };

            if (selectedDate < currentDate) {
              // alert()
              if (name) {
                setState((prev) => {
                  return { ...prev, [name]: dateStr };
                });
              } else {
                setState(dateStr);
              }
              setIsOpen(false);
            } else {
              // toastify(
              //     "Birthday field it should not be current or future date ",
              //     "error"
              // );
              setState((prev) => {
                return { ...prev, [name]: "" };
              });
            }
          }
        },
      };

      flatpickr(datepickerRef.current, options);
    }
  }, []);

  return (
    <div
      className={classNames.eachInputDatePicker}
      style={{
        margin: "0rem",
        position: "relative",
      }}
      ref={dropdownRef}
      onClick={() => setIsOpen(true)}
    >
      {topTitle && (
        <div
          style={{
            fontWeight: "600",
            color: "#110F0D",
            marginTop: "0px",
            fontSize: "12px",
            marginBottom: "8px",
          }}
          className={classNames.topTitle}
        >
          {title}
        </div>
      )}

      <span
        style={{
          position: "absolute",
          top: "33px",
          right: "10px",
        }}
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(true);
          return;
        }}
      >
        <svg
          style={{
            height: "15px",
          }}
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M8 2V5"
            stroke="#110F0D"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M16 2V5"
            stroke="#110F0D"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M3.5 9.08997H20.5"
            stroke="#110F0D"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z"
            stroke="#110F0D"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M11.9955 13.7H12.0045"
            stroke="#110F0D"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M8.29431 13.7H8.30329"
            stroke="#110F0D"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M8.29431 16.7H8.30329"
            stroke="#110F0D"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </span>
      <input
        type="text"
        id="datepicker"
        style={{
          maxWidth: "100%",
          padding: "1rem 1.5rem",
          color: "#0F0609",
          fontSize: "14px",
          margin: "0",
          background: placeholder === "Select birthday" ? "#f5f5f5" : "",
          margin: "0rem",
          paddingLeft: "10px",
        }}
        ref={datepickerRef}
        className={classNames.inputContainer}
        placeholder={placeholder}
        value={name ? stateValue[name] : stateValue}
        max={
          new Date().getFullYear() +
          "/" +
          (new Date().getMonth() + 1) +
          "/" +
          new Date().getDate()
        }
        // onChange={(event) => {
        //   setState({ ...stateValue, [name]: event?.target?.value });
        // }}
      />
    </div>
  );
};

export const NewEachCustomDropdownOpen = ({
  title,
  dropdown,
  name,
  name2,
  stateValue,
  setState,
  topTitle,
  type,
  stateVal,
  mapVal,
  indexx,
  calendarObjField,
  eachValuee,
  isDropdownOpen: isOpen,
  setIsDropdownOpen: setIsOpen,
}) => {
  // const [isOpen, setIsOpen] = useState(true);
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {});
  console.log("dropdowndropdown", dropdown);

  useEffect(() => {
    console.log(stateValue, "ljwekgfhkwjegfkjwef");
  }, [stateValue]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !dropdownRef?.current?.contains(event?.target) &&
        !inputRef?.current?.contains(event?.target)
      ) {
        setIsOpen(false);
      }
    };

    const handleDocumentClick = (event) => {
      handleClickOutside(event);
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const selectOption = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    // Perform any additional actions based on the selected option
  };
  const [countrysearch, setcountrysearch] = useState("");
  useEffect(() => {
    if (
      name === "selectedAppStaff" &&
      Array?.isArray(dropdown) &&
      dropdown?.length > 0
    ) {
      setState({
        NameOfTeam: dropdown[0]?.NameOfTeam,
        Icon: dropdown[0]?.Icon,
        TeamUsername: dropdown[0]?.TeamUsername,
      });
    }
    setcountrysearch("");
  }, [dropdown]);

  return (
    <div
      className={classNames.eachCustomDropdown}
      style={{
        // minWidth: isOpen ? "250px" : "",
        zIndex: isOpen ? "3" : "",
        height: "60px",
        minWidth: "150%",
        position: "absolute",
        left: "-1px",
      }}
    >
      {topTitle && (
        <div
          className={classNames.topTitle}
          style={{
            fontWeight: "600",
            color: "#110F0D",
            marginTop: "0px",
            fontSize: "12px",
            marginBottom: "8px",
          }}
        >
          {title}
        </div>
      )}
      <div
        className={classNames.inputContainer}
        onClick={() => setIsOpen(true)}
        style={{
          pointerEvents: dropdown ? "" : "none",
          maxWidth: "100%",
          padding: "1rem 1.5rem",
          color: "#0F0609",
          fontSize: "14px",
          margin: "0",
          margin: "0rem",
          paddingLeft: "10px",
          marginTop: "24px",
        }}
        ref={dropdownRef}
      >
        {isOpen && (
          <ul className="dropdown-list">
            {title === "Gender" && dropdown?.length > 0 ? (
              dropdown?.map((each, index) => {
                return (
                  <li
                    onClick={(event) => {
                      event.stopPropagation();
                      // selectOption(
                      //   eachitem[mapVal?.name] +
                      //   " (" +
                      //   eachitem[mapVal?.name1] +
                      //   ")"
                      // );
                      setState({
                        ...stateValue,
                        gender: each,
                      });
                      setIsOpen(false);
                    }}
                    key={index}
                  >
                    {each}
                  </li>
                );
              })
            ) : title === "Marital Status" && dropdown?.length > 0 ? (
              dropdown?.map((each, index) => {
                return (
                  <li
                    onClick={(event) => {
                      event.stopPropagation();
                      // selectOption(
                      //   eachitem[mapVal?.name] +
                      //   " (" +
                      //   eachitem[mapVal?.name1] +
                      //   ")"
                      // );
                      setState({
                        ...stateValue,
                        MaritalStatus: each,
                      });
                      setIsOpen(false);
                    }}
                    key={index}
                  >
                    {each}
                  </li>
                );
              })
            ) : title === "Which therapy category does this fall under?" &&
              dropdown?.length > 0 ? (
              dropdown?.map((each, index) => {
                return (
                  <li
                    onClick={(event) => {
                      event.stopPropagation();
                      // selectOption(
                      //   eachitem[mapVal?.name] +
                      //   " (" +
                      //   eachitem[mapVal?.name1] +
                      //   ")"
                      // );
                      setState({
                        ...stateValue,
                        theraphyCategoryId: each?._id,
                        theraphyCategoryName: each?.name,
                      });
                      setIsOpen(false);
                    }}
                    key={index}
                  >
                    {each?.name}
                  </li>
                );
              })
            ) : title === "What room category does this room fall under?" &&
              dropdown?.length > 0 ? (
              dropdown?.map((each, index) => {
                return (
                  <li
                    onClick={(event) => {
                      event.stopPropagation();
                      // selectOption(
                      //   eachitem[mapVal?.name] +
                      //   " (" +
                      //   eachitem[mapVal?.name1] +
                      //   ")"
                      // );
                      setState({
                        ...stateValue,
                        rooms: each?.roomcategoryid,
                        roomsName: each?.name,
                      });
                      setIsOpen(false);
                    }}
                    key={index}
                  >
                    {each?.name}
                  </li>
                );
              })
            ) : title === "Which location will this be sold at ?" ||
              (title === "At which location is this kitchen item served?" &&
                dropdown?.length > 0) ? (
              dropdown?.map((each, index) => {
                return (
                  <li
                    onClick={(event) => {
                      event.stopPropagation();
                      // selectOption(
                      //   eachitem[mapVal?.name] +
                      //   " (" +
                      //   eachitem[mapVal?.name1] +
                      //   ")"
                      // );
                      setState({
                        ...stateValue,
                        location: each?._id,
                        locationName: each?.name,
                      });
                      setIsOpen(false);
                    }}
                    key={index}
                  >
                    {each?.name}
                  </li>
                );
              })
            ) : title === "Which category does this product fall under?" &&
              dropdown?.length > 0 ? (
              dropdown?.map((each, index) => {
                return (
                  <li
                    onClick={(event) => {
                      event.stopPropagation();
                      // selectOption(
                      //   eachitem[mapVal?.name] +
                      //   " (" +
                      //   eachitem[mapVal?.name1] +
                      //   ")"
                      // );
                      setState({
                        ...stateValue,
                        catfallunder: each?.productcategoryid,
                        catfallunderName: each?.name,
                      });
                      setIsOpen(false);
                    }}
                    key={index}
                  >
                    {each?.name}
                  </li>
                );
              })
            ) : title === "What is the parent service type?" &&
              dropdown?.length > 0 ? (
              dropdown?.map((each, index) => {
                return (
                  <li
                    onClick={(event) => {
                      event.stopPropagation();
                      // selectOption(
                      //   eachitem[mapVal?.name] +
                      //   " (" +
                      //   eachitem[mapVal?.name1] +
                      //   ")"
                      // );
                      setState({
                        ...stateValue,
                        constultandid: each?.consultationtypeid,
                        constultandName: each?.name,
                      });
                      setIsOpen(false);
                    }}
                    key={index}
                  >
                    {each?.name}
                  </li>
                );
              })
            ) : title === "Which brand makes this product?" &&
              dropdown?.length > 0 ? (
              dropdown?.map((each, index) => {
                return (
                  <li
                    onClick={(event) => {
                      event.stopPropagation();
                      // selectOption(
                      //   eachitem[mapVal?.name] +
                      //   " (" +
                      //   eachitem[mapVal?.name1] +
                      //   ")"
                      // );
                      setState({
                        ...stateValue,
                        brandsproduct: each?.productbrandid,
                        brandsproductName: each?.name,
                      });
                      setIsOpen(false);
                    }}
                    key={index}
                  >
                    <img src={each?.icon} />
                    {each?.name}
                  </li>
                );
              })
            ) : title ===
                "Which staff member do you want to make a specialist?" &&
              dropdown?.length > 0 ? (
              dropdown?.map((each, index) => {
                return (
                  <li
                    onClick={(event) => {
                      event.stopPropagation();
                      // selectOption(
                      //   eachitem[mapVal?.name] +
                      //   " (" +
                      //   eachitem[mapVal?.name1] +
                      //   ")"
                      // );
                      setState({
                        ...stateValue,
                        spaceName:
                          each?.TeamDetails?.[0]?.PlayerDetails?.[0]?.name,
                        spaceid: each?.teamplayerid,
                      });
                      setIsOpen(false);
                    }}
                    key={index}
                  >
                    <img
                      src={
                        each?.TeamDetails?.[0]?.PlayerDetails?.[0]?.ProfilePic
                      }
                    />
                    {each?.TeamDetails?.[0]?.PlayerDetails?.[0]?.name}
                  </li>
                );
              })
            ) : title === "Select Country" && dropdown?.length > 0 ? (
              <>
                <input
                  placeholder="Search..."
                  onClick={() => toggleDropdown(true)}
                  value={countrysearch}
                  style={{
                    color: "#7587A7",
                    background: "#F5F7F9",
                    border: "1px solid #D1D7E1",
                    height: "35px",
                    padding: "0 1.3rem",
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "22px",
                    width: "100%",
                    borderRadius: "6px",
                    marginBottom: "10px",
                  }}
                  onChange={(e) => setcountrysearch(e.target.value)}
                />
                {dropdown
                  ?.filter((temp) =>
                    temp?.name
                      ?.toLowerCase()
                      ?.includes(countrysearch?.toLowerCase())
                  )
                  ?.map((eachitem, index) => {
                    console.log("08x8Yhgzcxjzxc", eachitem);
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(eachitem[mapVal?.name]);
                          setState({
                            ...stateValue,
                            [name]: eachitem?.name,
                            flag: eachitem?.flag,
                          });
                        }}
                        key={eachitem[mapVal?.name] + index}
                      >
                        {eachitem?.name}
                      </li>
                    );
                  })}
              </>
            ) : title === "Which location will this specialist work at?" &&
              dropdown?.length > 0 ? (
              dropdown?.map((each, index) => {
                return (
                  <li
                    onClick={(event) => {
                      event.stopPropagation();
                      // selectOption(
                      //   eachitem[mapVal?.name] +
                      //   " (" +
                      //   eachitem[mapVal?.name1] +
                      //   ")"
                      // );
                      setState({
                        ...stateValue,
                        locationid: each?._id,
                        locationName: each?.name,
                      });
                      setIsOpen(false);
                    }}
                    key={index}
                  >
                    {each?.name}
                  </li>
                );
              })
            ) : title === "Select specialist" && dropdown?.length > 0 ? (
              dropdown?.map((each, index) => {
                return (
                  <li
                    onClick={(event) => {
                      event.stopPropagation();
                      // selectOption(
                      //   eachitem[mapVal?.name] +
                      //   " (" +
                      //   eachitem[mapVal?.name1] +
                      //   ")"
                      // );
                      console.log(each, "lkwheflkwhfw");
                      setState({
                        ...stateValue,
                        speicalistId: each?.specialistid,
                        speicalistName:
                          each?.TeamDetails[0]?.PlayerDetails[0]?.name,
                      });
                      setIsOpen(false);
                    }}
                    key={index}
                  >
                    {each?.TeamDetails[0]?.PlayerDetails[0]?.name}
                  </li>
                );
              })
            ) : title === "Which location will this service be offered at?" &&
              dropdown?.length > 0 ? (
              dropdown?.map((each, index) => {
                return (
                  <li
                    onClick={(event) => {
                      event.stopPropagation();
                      // selectOption(
                      //   eachitem[mapVal?.name] +
                      //   " (" +
                      //   eachitem[mapVal?.name1] +
                      //   ")"
                      // );
                      setState({
                        ...stateValue,
                        locationid: each?._id,
                        locationName: each?.name,
                      });
                      setIsOpen(false);
                    }}
                    key={index}
                  >
                    {each?.name}
                  </li>
                );
              })
            ) : title === "Select the timezone" && dropdown?.length > 0 ? (
              dropdown?.map((eachitem, index) => {
                return (
                  <li
                    onClick={(event) => {
                      event.stopPropagation();
                      selectOption(
                        eachitem[mapVal?.name] +
                          " (" +
                          eachitem[mapVal?.name1] +
                          ")"
                      );
                      setState({
                        ...stateValue,
                        TimeZone: eachitem?.UTCDifference,
                        TimeZoneAbbrevation: eachitem?.Abbreviation,
                      });
                    }}
                    key={eachitem[mapVal?.name] + index}
                  >
                    {eachitem[mapVal?.name] +
                      " (" +
                      eachitem[mapVal?.name1] +
                      ")"}
                  </li>
                );
              })
            ) : type === "obj" && dropdown?.length > 0 ? (
              dropdown?.map((eachitem, index) => {
                return (
                  <li
                    onClick={(event) => {
                      event.stopPropagation();
                      selectOption(eachitem[mapVal?.name]);
                      if (name2) {
                        setState({
                          ...stateValue,
                          [name]: eachitem[stateVal?.name1],
                          [name2]: eachitem[stateVal?.name2],
                        });
                      } else {
                        setState({
                          ...stateValue,
                          [name]: eachitem[stateVal],
                        });
                      }
                    }}
                    key={eachitem[mapVal?.name] + index}
                  >
                    {mapVal?.image && (
                      <img
                        src={eachitem[mapVal?.image]}
                        alt={
                          name2 ? eachitem[stateVal?.name1] : eachitem[stateVal]
                        }
                      />
                    )}
                    {mapVal?.subName ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "3px",
                        }}
                      >
                        <div style={{ fontSize: "1.5rem", fontWeight: "510" }}>
                          {eachitem[mapVal?.name]}
                        </div>
                        <div style={{ fontSize: "1rem", fontWeight: "410" }}>
                          {eachitem[mapVal?.subName]}
                        </div>
                      </div>
                    ) : (
                      eachitem[mapVal?.name]
                    )}
                  </li>
                );
              })
            ) : type === "arrayItems" && dropdown?.length > 0 ? (
              dropdown?.map((eachitem, index) => {
                return (
                  <li
                    onClick={(event) => {
                      event.stopPropagation();
                      selectOption(eachitem[mapVal?.name]);
                      setState((prev) => {
                        return {
                          ...stateValue,
                          [name]: Array.isArray(prev[name])
                            ? [...prev[name], eachitem]
                            : [eachitem],
                        };
                      });
                    }}
                    key={eachitem[mapVal?.name] + index}
                  >
                    {mapVal?.image && (
                      <img
                        src={eachitem[mapVal?.image]}
                        alt={eachitem[stateVal]}
                      />
                    )}
                    {mapVal?.subName ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "3px",
                        }}
                      >
                        <div style={{ fontSize: "1.5rem", fontWeight: "510" }}>
                          {eachitem[mapVal?.name]}
                        </div>
                        <div style={{ fontSize: "1rem", fontWeight: "410" }}>
                          {eachitem[mapVal?.subName]}
                        </div>
                      </div>
                    ) : (
                      eachitem[mapVal?.name]
                    )}
                  </li>
                );
              })
            ) : (name === "Gender" ||
                name === "groupType" ||
                type === "single") &&
              dropdown?.length > 0 ? (
              dropdown?.map((eachitem, index) => {
                return (
                  <li
                    onClick={(event) => {
                      event.stopPropagation();
                      selectOption(eachitem);
                      setState({
                        ...stateValue,
                        [name]: eachitem,
                      });
                    }}
                    key={eachitem + index}
                  >
                    {eachitem}
                  </li>
                );
              })
            ) : name === "IndustriesId" && dropdown?.length > 0 ? (
              dropdown?.map((eachitem, index) => {
                return (
                  <li
                    onClick={(event) => {
                      event.stopPropagation();
                      selectOption(eachitem?.Name);
                      setState({
                        ...stateValue,
                        [name]: eachitem?._id,
                      });
                    }}
                    key={eachitem?.Name + index}
                  >
                    {eachitem?.Name}
                  </li>
                );
              })
            ) : name === "defaultDropdown" && dropdown?.length > 0 ? (
              dropdown?.map((eachitem, index) => {
                return (
                  <li
                    onClick={(event) => {
                      event.stopPropagation();
                      selectOption(eachitem);
                      setState(eachitem);
                    }}
                    key={index}
                  >
                    {eachitem}
                  </li>
                );
              })
            ) : (
              dropdown?.length > 0 &&
              dropdown?.map((eachitem, index) => {
                return (
                  <li
                    onClick={(event) => {
                      event.stopPropagation();
                      selectOption(eachitem?.NameOfTeam);
                      if (name === "teamUsername") {
                        setState((prev) => {
                          return {
                            ...prev,
                            [name]: eachitem?.TeamUsername,
                          };
                        });
                      } else if (name === "selectedAppStaff") {
                        setState({
                          NameOfTeam: eachitem?.NameOfTeam,
                          Icon: eachitem?.Icon,
                          TeamUsername: eachitem?.TeamUsername,
                        });
                      } else {
                        setState({
                          ...stateValue,
                          [name]: {
                            NameOfTeam: eachitem?.NameOfTeam,
                            Icon: eachitem?.Icon,
                          },
                        });
                      }
                    }}
                    key={eachitem?.NameOfTeam + index}
                  >
                    <img src={eachitem?.Icon} alt={eachitem?.NameOfTeam} />
                    {eachitem?.NameOfTeam}
                  </li>
                );
              })
            )}
          </ul>
        )}
      </div>
    </div>
  );
};
