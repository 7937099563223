import React from "react";
import Header from "../pages/Advisors/AdvisorProfile/Header";
import AdvisorSidebarLatest from "../components/AdvisorSidebarLatest";

const Dashboardv2 = ({ child }) => {
  return (
    <div className="advisor-profile-editing">
      <Header />
      <div className="flexContainers">
        <AdvisorSidebarLatest />
        {child}
      </div>
    </div>
  );
};

export default Dashboardv2;
