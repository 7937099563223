import { BrowserRouter } from "react-router-dom";
import Routes from "./Routes";
import { QueryClient, QueryClientProvider } from "react-query";
import { LoginContextProvider } from "./contexts/LoginContext";
import { ToastContainer } from "react-toastify";
import { Globalcontext } from "./contexts/GlobalContext";
import { useState } from "react";
import TopCard from "./components/topcard/topcard";
function AtmsApp() {
  const queryClient = new QueryClient();

  const [sideBarStatus, setSideBarStatus] = useState(false);
  const [centerContainerShow, setcenterContainerShow] = useState();
  const [allgoals, setallgoals] = useState([{ goal: "Travel" }]);
  const [opentoggleclass, setopentoggleclass] = useState(false)
  // todo sidebar
  const [opencard, setopencard] = useState("translateX(-140%)");
  const [therightcard, settherightcard] = useState("translateX(-140%)");
  const [closecard, setclosecard] = useState("");
  const [pagemask, setpagemask] = useState("");
  const [closedisp, setclosedisp] = useState("none");
  const [closerightdisp, setcloserightdisp] = useState("none");
  const [icondisp, seticondisp] = useState("none");
  const [clinetDetail, setclinetDetail] = useState({})
  const [proposal, setproposal] = useState([])
  const [Selectitem, setSelectitem] = useState({})
  const [finalDetail, setfinalDetail] = useState({})
  const [createnewProsoal, setcreatenewProsoal] = useState([])
  const value = {
    sideBarStatus,
    createnewProsoal,
    setcreatenewProsoal,
    proposal, Selectitem, setSelectitem,
    setproposal,
    clinetDetail, finalDetail, setfinalDetail,
    setclinetDetail,
    setSideBarStatus,
    opentoggleclass,
    setopentoggleclass,
    centerContainerShow,
    setcenterContainerShow,
    //sidebar
    opencard,
    setopencard,
    therightcard,
    settherightcard,
    closecard,
    setclosecard,
    pagemask,
    setpagemask,
    closedisp,
    setclosedisp,
    closerightdisp,
    setcloserightdisp,
    icondisp,
    seticondisp,
    allgoals,
    setallgoals,
  };

  return (
    <>
      <LoginContextProvider>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <Globalcontext.Provider value={value}>
              <TopCard />
              <Routes />
            </Globalcontext.Provider>
          </BrowserRouter>
        </QueryClientProvider>
      </LoginContextProvider>
      <ToastContainer />
    </>
  );
}

export default AtmsApp;
