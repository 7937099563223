import React, { useEffect, useState } from "react";
import classNames from "./advisorleads.module.scss";
import axios from "axios";
import { toast } from "react-toastify";

const AdvisorLeads = () => {
  const [allAdvisorLeads, setAllAdvisorLeads] = useState([]);
  const [localRefresh, setLocalRefresh] = useState(false);

  //functions

  async function getAllAdvisorLeads() {
    try {
      let response = await axios.get(
        "https://api.retired.app/retired/user/get/remainusers?userType=user"
      );
      if (response?.data?.status && response?.data?.data?.length > 0) {
        setAllAdvisorLeads(response?.data?.data);
      } else {
        setAllAdvisorLeads([]);
      }
      console.log(response, "Get all advisor leads response");
    } catch (error) {
      console.log(error?.message, "Get all advisor leads error");
    }
  }

  async function selectAnAdvisor(selectedEmail) {
    let obj = {
      email: selectedEmail,
      advisorEmail: localStorage.getItem("LoginAccount"),
    };
    try {
      let response = await axios.post(
        "https://api.retired.app/retired/useradvisor/add",
        obj
      );
      if (response?.data?.status) {
        toast(response?.data?.message);
        setLocalRefresh((prev) => !prev);
      }
    } catch (error) {
      console.log(error?.message, "Get all advisor leads error");
    }
  }

  //renderings

  useEffect(() => {
    getAllAdvisorLeads();
  }, [localRefresh]);

  return (
    <div className={classNames.allAdvisorLeads}>
      {Array.isArray(allAdvisorLeads) &&
        allAdvisorLeads?.length > 0 &&
        allAdvisorLeads?.map((eachLead, index) => {
          return (
            <div
              className={classNames.tableAdvisorList}
              //   style={{
              //     display: expand ? "none" : "flex",
              //   }}
            >
              <div className={classNames.eachSubDiv} style={{ width: "70px" }}>
                <img
                  src={eachLead?.profilePic}
                  alt="bitcoinIcon"
                  className={classNames.roundedImg}
                  style={{ width: "60px", height: "60px" }}
                />
              </div>

              <div
                className={classNames.eachSubDiv}
                style={{
                  width: "calc(75% - 80px)",
                  marginLeft: "8px",
                }}
              >
                <div
                  className={classNames.eachContentSubDiv}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div style={{ display: "flex", gap: "4px" }}>
                    <div className={classNames.heading}>
                      {eachLead?.firstName}
                    </div>
                    <div className={classNames.date}>{eachLead?.lastName}</div>
                  </div>
                  <div className={classNames.eachPhoneNumber}>
                    {eachLead?.phoneNumber}
                  </div>
                </div>
              </div>

              <div
                className={classNames.eachSubDiv}
                style={{
                  width: "25%",
                  justifyContent: "end",
                }}
              >
                <div
                  className={classNames.labelplantext}
                  onClick={() => selectAnAdvisor(eachLead?.email)}
                >
                  Select
                </div>
                <div
                  className={classNames.labelplantext}
                  //   onClick={() => clientFucntion(eachLead?.email)}
                >
                  Profile
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default AdvisorLeads;
