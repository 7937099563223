import React, { useEffect, useState } from "react";
import crossIcon from "../../../../../assets/fi-bs-cross-small.svg";
import "./Filter.scss";
import MultiSelect from "./MultiSelect/MultiSelect";

const Filter = ({ handleCancel, handleApply }) => {
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedSubProducts, setSelectedSubProducts] = useState([]);
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const [isApplyEnabled, setIsApplyEnabled] = useState(false);

  useEffect(() => {
    setIsApplyEnabled(
      selectedCompanies.length > 0 ||
        selectedProducts.length > 0 ||
        selectedSubProducts.length > 0 ||
        selectedKeywords.length > 0
    );
  }, [
    selectedCompanies,
    selectedProducts,
    selectedSubProducts,
    selectedKeywords,
  ]);

  const handleCancelClick = () => {
    setSelectedCompanies([]);
    setSelectedProducts([]);
    setSelectedSubProducts([]);
    setSelectedKeywords([]);
    handleCancel();
  };

  const handleApplyClick = () => {
    handleApply({
      companies: selectedCompanies,
      products: selectedProducts,
      subProducts: selectedSubProducts,
      keywords: selectedKeywords,
    });
  };

  return (
    <div className={"container"}>
      <div className={"header"}>
        <p className="header-title">Filter</p>
        <button className="cancel-btn" onClick={handleCancel}>
          <img alt="x" src={crossIcon} />
        </button>
      </div>
      <div className={"content"}>
        <MultiSelect
          label="Company:"
          options={["Company A", "dolor sit amet", "Lorem Ipsum"]}
          selectedOptions={selectedCompanies}
          setSelectedOptions={setSelectedCompanies}
        />
        <MultiSelect
          label="Product:"
          options={["Product A", "Product B", "Product C"]}
          selectedOptions={selectedProducts}
          setSelectedOptions={setSelectedProducts}
        />
        <MultiSelect
          label="Subproduct:"
          options={["Subproduct A", "Subproduct B", "Subproduct C"]}
          selectedOptions={selectedSubProducts}
          setSelectedOptions={setSelectedSubProducts}
        />
        <MultiSelect
          label="Keywords:"
          options={["Keyword A", "Keyword B", "Keyword C"]}
          selectedOptions={selectedKeywords}
          setSelectedOptions={setSelectedKeywords}
        />
      </div>
      <div className={"btnContainer"}>
        <button onClick={handleCancelClick}>Cancel</button>
        <button onClick={handleApplyClick} disabled={!isApplyEnabled}>
          Apply
        </button>
      </div>
    </div>
  );
};

export default Filter;
