import React from "react";
import "./rotatecard.scss";

//assets
import moneymarkets from "../../assets/Images/rotatecards/moneymarkets.svg";
import indexfunds from "../../assets/Images/rotatecards/indexfunds.svg";
import funds from "../../assets/Images/rotatecards/funds.svg";
import sharetokens from "../../assets/Images/rotatecards/sharetokens.svg";
import bonds from "../../assets/Images/rotatecards/bonds.svg";

const Rotatecard = ({ eachcard }) => {
  let logoImage =
    require(`../../assets/Images/rotatecards/${eachcard.image}.svg`).default;

  return (
    <div
      className={`ecosystem-single-container-image ${
        eachcard.image === "careercoach" || eachcard.image === "lifecoach"
          ? "fullwidthard"
          : ""
      }`}
    >
      <div className="eco-content">
        <div>
          <img src={logoImage} alt={eachcard.image} />
          <div>{eachcard.title}</div>
        </div>
        <div className="ecosystem-single-container">
          <div>{eachcard.content}</div>
          {/* <div style={{ background: "#186AB4" }}>{eachcard.title}</div> */}
          <div></div>
        </div>
      </div>
      {/* <div>Money Markets</div> */}
    </div>
  );
};

export default Rotatecard;
