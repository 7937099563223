import React, { useContext } from "react";
import Landing from "../../components/Landing/Landing";
import { Globalcontext } from "../../contexts/GlobalContext";

const Homepage = () => {
  const { sideBarStatus } = useContext(Globalcontext);
  return (
    <div>
      <Landing />
    </div>
  );
};

export default Homepage;
