import React from 'react';
import './AdvisorCard.scss';

const AdvisorCard = ({ name, other, experience, place, image, isAccepted }) => {
  return (
    <div className="advisorCard">
      <img src={image} alt={name} className="advisorImage"/>
      <div className="advisorInfo">
        <div className="advisorName">{name}</div>
        <div className="advisorId">{other}</div>
        <div className="advisorExperience">{experience}</div>
        <div className="advisorPlace">{place}</div>
      </div>
      <button className="sendRequestButton">
        {isAccepted ? "Message" : "Send request"}
      </button>
    </div>
  );
};

export default AdvisorCard;
