import React, { useEffect, useState } from "react";
import styles from "./product.module.scss";
import { useParams } from "react-router-dom";
import axios from "axios";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import blog from "./blog.png";
import { FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { CiFacebook } from "react-icons/ci";
import { useHistory } from "react-router-dom";
import imgCard from "./cardImg.png"
import HeaderNav from "../../components/HeaderNav";

const AssetsPage = () => {
    let history = useHistory();
    const { email } = useParams();
    const [userData, setUserData] = useState(null);
    const [loadingUserData, setLoadingUserData] = useState(false);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        if (email) {
            setLoadingUserData(true);
            axios
                .get(
                    `https://comms.globalxchange.io/retired/user/get/userprofiles?email=${email}`
                )
                .then(({ data }) => {
                    if (data.status) {
                        setUserData(data?.data[0]);
                        setLoadingUserData(false);
                        // setDotColor(`#${data?.data[0]?.colorCode}`);
                    } else {
                        setLoadingUserData(false);
                    }
                });

            getProducts(email);
            // getBlogs(email);
            // getTestimonials(email);
            // getServices(email)
        }
    }, [email]);

    const [products, setProducts] = useState(null);

    const getProducts = (email) => {
        axios
            .get(
                `https://api.retired.app/retired/user/get/userprofiles?email=${email}&userType=advisor`
            )
            .then(({ data }) => {
                if (data.status) {
                    setProducts(data?.data[0]?.assetClassDetails);
                }
            });
    };
    return (
        <div style={{ zoom: "125%" }}>
            <HeaderNav userData={userData} lastpage={true} history={history} />
            <div style={{ height: '86vh', marginTop: '2rem' }}>
                {products?.map((item, index) => {
                    if (index % 2 == 0) {
                        return (
                            <div className={styles.evenCard}>
                                <div style={{ width: '50%' }}>
                                    <div className={styles.cardTitle}>{item?.name}</div>
                                    <div className={styles.cardSubTitle}>
                                        {item?.description}
                                    </div>
                                </div>
                                <div style={{ width: '48%', borderRadius: '10px', border: "1px solid #e7e7e7", padding: '2rem', width: '40.5rem', height: '26.875rem' }}>
                                    <img src={item.icon ? item.icon : imgCard} alt="" style={{ width: '100%', height: '100%' }} />
                                </div>
                            </div>
                        )
                    } else {
                        return (
                            <div className={styles.oddCard} style={{
                                backgroundColor: `rgba(${parseInt(userData?.colorCode?.substring(0, 2), 16)}, ${parseInt(userData?.colorCode?.substring(2, 4), 16)}, ${parseInt(userData?.colorCode?.substring(4, 6), 16)}, 0.1)`, // 0.5 is the opacity value
                            }}>
                                <div style={{ width: '50%', borderRadius: '10px', border: "1px solid #e7e7e7", padding: '2rem', width: '40.5rem', height: '26.875rem' }}>
                                    <img src={item.icon ? item.icon : imgCard} alt="" style={{ width: '100%', height: '100%' }} />
                                </div>
                                <div style={{ width: '50%' }}>
                                    <div className={styles.cardTitle}>{item?.name}</div>
                                    <div className={styles.cardSubTitle}>
                                        {item?.description}
                                    </div>
                                </div>

                            </div>
                        )
                    }
                })}
            </div>
            <div
                className={styles.footerNav}
                style={{ backgroundColor: `#${userData?.colorCode}` }}
            >
                <div className={styles.footerLeft}>
                    <img
                        src={require("./phone.png").default}
                        alt=""
                        className={styles.phoneIcon}
                    />
                    <div className={styles.phoneNo}>{userData?.phoneNumber}</div>
                </div>
                {/* <img
          src={require("./social.png").default}
          alt=""
          className={styles.socialLinks}
        /> */}
                <div
                    style={{
                        width: "7.125rem",
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <FaInstagram
                        className={styles.socialLinks}
                        onClick={() => window.open(userData?.instagram, "_blank")}
                    />
                    <FaLinkedinIn
                        className={styles.socialLinks}
                        onClick={() => window.open(userData?.linkedIn, "_blank")}
                    />
                    <CiFacebook
                        className={styles.socialLinks}
                        onClick={() => window.open(userData?.facebook, "_blank")}
                    />
                </div>
            </div>
        </div>
    );
}

export default AssetsPage;