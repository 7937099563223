import React, { useState } from 'react';
import './AdvisorProfileEdit.scss';
import PersonImg from '../../../assets/Images/AdvisorList/person.png';
import CompanyLogoImg from '../../../assets/Images/AdvisorList/Ellipse.png';

const AdvisorProfileEdit = ({ onSave, onCancel }) => {
  const [formData, setFormData] = useState({
    name: 'Harvey Bing',
    expertise: 'Lorem Ipsum',
    experience: 'Lorem Ipsum',
    tagline: 'Lorem Ipsum',
    place: 'Lorem Ipsum',
    mail: 'Lorem Ipsum',
    about: 'Lorem Ipsum'
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSave = () => {
    onSave(formData);
  };

  return (
    <div className="advisor-profile-edit">
      <div className="form-container">
        <div className="left-section">
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label htmlFor="expertise">Expertise</label>
            <input type="text" id="expertise" name="expertise" value={formData.expertise} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label htmlFor="experience">Experience</label>
            <input type="text" id="experience" name="experience" value={formData.experience} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label htmlFor="tagline">Tag line</label>
            <input type="text" id="tagline" name="tagline" value={formData.tagline} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label htmlFor="place">Place</label>
            <input type="text" id="place" name="place" value={formData.place} onChange={handleChange} />
          </div>
          <div className="form-group">
            <label htmlFor="mail">Mail</label>
            <input type="text" id="mail" name="mail" value={formData.mail} onChange={handleChange} />
          </div>
        </div>
        <div className="right-section">
            <div>
          <div className="form-group">
            <label htmlFor="about">About</label>
            <textarea id="about" name="about" value={formData.about} onChange={handleChange} />
          </div>
          <div className="profile-pictures">
            <div className="profile-picture-item">
              <label>Company logo</label>
              <img src={CompanyLogoImg} alt="Company logo" />
            </div>
            <div className="profile-picture-item">
              <label>Profile Picture</label>
              <img src={PersonImg} alt="Profile" />
            </div>
          </div>
          </div>
        </div>
      </div>
          <div className="buttons">
            <button className="cancel-button" onClick={onCancel}>Cancel</button>
            <button className="save-button" onClick={handleSave}>Save</button>
          </div>
    </div>
  );
};

export default AdvisorProfileEdit;
