import React, { useState } from "react";
import "./AdvisorProfileAddExisting.scss";

import FlowerImg from "../../../assets/Images/AdvisorList/pot.png";
import PictureImg from "../../../assets/Images/AdvisorList/picture.png";
import { FiPlus } from "react-icons/fi";
import DeleteIcon from "../../../assets/Images/AdvisorList/trash.png";


const AdvisorProfileAddExisting = ({ onSave, onCancel }) => {
  const [isAddingImage, setIsAddingImage] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);


  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
        setIsAddingImage(false);
      };
      reader.readAsDataURL(file);
    }
  };

  const onRemove = () => {
    setIsAddingImage(false)
  }

  const handleAddClick = () => {
    setIsAddingImage(true);
  };

  return (
    <>
      <div className="advisor-profile-add-existing">
        <div className="checkbox-section">
          <ol>
            <li>
              <label>
                {!isAddingImage && (<input type="checkbox" /> )}
                <span>Real-estate</span>
              </label>
            </li>
            <li>
              <label>
              {!isAddingImage && (<input type="checkbox" /> )}
              <span>Trading</span>
              </label>
            </li>
            <li>
              <label>
              {!isAddingImage && (<input type="checkbox" /> )}
              <span>Need basic financial planning</span>
              </label>
            </li>
            <li>
              <label>
              {!isAddingImage && (<input type="checkbox" /> )}
              <span>Need basic financial planning</span>
              </label>
            </li>
            <li>
              <label>
              {!isAddingImage && (<input type="checkbox" /> )}
              <span>Need basic financial planning</span>
              </label>
            </li>
            <li>
              <label>
              {!isAddingImage && (<input type="checkbox" /> )}
              <span>Need basic financial planning</span>
              </label>
            </li>
            <li>
              <label>
              {!isAddingImage && (<input type="checkbox" /> )}
              <span>Need basic financial planning</span>
              </label>
            </li>
            <li>
              <label>
              {!isAddingImage && (<input type="checkbox" /> )}
              <span>Need basic financial planning</span>
              </label>
            </li>
          </ol>
        </div>
        <div className="header">
          <label className="product-label">Service 3</label>
          {isAddingImage && <img src={DeleteIcon} alt="delete" className="delete-icon" /> })
        </div>
        <div className="service-details">
          <div className="service-header">
            <h3>Need basic financial planning</h3>
          </div>
          <div className="service-content">
            <div className="service-description">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>
            </div>
            <div className="service-image">
              {isAddingImage ? (
                <div className="image-upload">
                  <img src={PictureImg} alt="Image Icon" />
                  <p>Drag and drop image here or <br /> 
                    <label className="file-input-label">
                      <span>Choose image</span>
                      <input type="file" onChange={handleImageUpload} />
                    </label>
                  </p>
                </div>
              ) : (
                <img src={selectedImage || FlowerImg} alt="Placeholder" />
              )}
            </div>
          </div>
        </div>
      </div>
      {isAddingImage && (
        <div className="add-one-container">
        <button className="add-one-button"><FiPlus /> Add one</button>
      </div>
      )}

      <div className="buttons">
        <button className="cancel-button" onClick={onRemove}>
          Cancel
        </button>
        {!isAddingImage ? (
          <button className="save-button" onClick={handleAddClick}>Add</button>
        ) : (
          <button className="save-button" onClick={onSave}>
            Save
          </button>
        )}
      </div>
    </>
  );
};

export default AdvisorProfileAddExisting;
