import React, { useState, useEffect, useContext } from "react";
import { NewUIMyEachInput, NewMyEachCustomTextarea } from "../GloabelInput";
import { SketchPicker } from "react-color";

export default function AdvisorProfileView({ client, setclient }) {
  //functions

  //renderings

  return (
    <div className="editing-page">
      <div className="editingSectionTop notClickable">
        <div className="textSectionTop">
          <div className="textheader">Basic Information</div>
          <div
            style={{
              marginTop: "3.5rem",
            }}
          >
            <img
              src={client?.profilePic}
              alt={client?._id}
              style={{ height: "80px", width: "80px", borderRadius: "50%" }}
            />
          </div>
        </div>
        <div className="eddintright">
          <div className="Innerinnputsection">
            <div className="subdivision">
              <NewUIMyEachInput
                title="Job title"
                placeholder="Enter Job title"
                // stateValue={client?.name}
                name="jobTitle"
                stateValue={client}
                setState={setclient}
                topTitle="true"
              />
            </div>
            <div className="subdivision">
              <NewUIMyEachInput
                title="Years of experience"
                placeholder="Enter Years of experience"
                // stateValue={client?.name}
                name="yearsOfExperience"
                stateValue={client}
                setState={setclient}
                topTitle="true"
              />
            </div>
          </div>
          <div className="Innerinnputsection">
            <div className="subdivision">
              <NewUIMyEachInput
                title="Contact email"
                placeholder="Contact email"
                // stateValue={client?.name}
                name="contactEmail"
                stateValue={client}
                setState={setclient}
                topTitle="true"
              />
            </div>

            <div className="subdivision">
              <NewUIMyEachInput
                title="Contact Phone Number"
                placeholder="Enter Phone Number"
                // stateValue={client?.name}
                name="whatsappNumber"
                stateValue={client}
                setState={setclient}
                topTitle="true"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="editingSectionTop notClickable">
        <div className="textSectionTop">
          <div className="textheader">Social media</div>
        </div>
        <div className="eddintright">
          <div className="Innerinnputsection">
            <div className="subdivision">
              <NewUIMyEachInput
                title="Facebook"
                placeholder="Facebook Link"
                // stateValue={client?.name}
                name="facebook"
                stateValue={client}
                setState={setclient}
                topTitle="true"
              />
            </div>
            <div className="subdivision">
              <NewUIMyEachInput
                title="Instagram"
                placeholder="Instagram Link"
                // stateValue={client?.name}
                name="instagram"
                stateValue={client}
                setState={setclient}
                topTitle="true"
              />
            </div>
          </div>
          <div className="Innerinnputsection">
            <div className="subdivision">
              <NewUIMyEachInput
                title="Linkedin"
                placeholder="Linkedin Link"
                // stateValue={client?.name}
                name="linkedIn"
                stateValue={client}
                setState={setclient}
                topTitle="true"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="editingSectionTop notClickable">
        <div className="textSectionTop">
          <div className="textheader">Images</div>
        </div>
        <div className="eddintright">
          <div className="Innerinnputsection">
            <div
              className="subdivision"
              style={{
                width: "100%",
              }}
            >
              <img
                src={client?.coverPhoto}
                alt={client?._id}
                style={{ height: "100%", width: "100%" }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="editingSectionTop notClickable">
        <div className="textSectionTop">
          <div className="textheader">Website information</div>
          <div
            style={{
              marginTop: "3.5rem",
            }}
          >
            <img
              src={client?.logo}
              alt={client?._id}
              style={{ height: "80px", width: "80px", borderRadius: "50%" }}
            />
          </div>
        </div>
        <div className="eddintright" style={{ display: "flex", gap: "1rem" }}>
          <div
            className="Innerinnputsection"
            style={{ flexDirection: "column", width: "50%" }}
          >
            <div className="subdivision" style={{ width: "100%" }}>
              <SketchPicker
                color={`#${client?.colourCode}`}
                onChangeComplete={(color) => {
                  setclient((prev) => {
                    return { ...prev, Colourcode: color?.hex };
                  });
                }}
              />
            </div>
            <div className="subdivision" style={{ width: "100%" }}>
              <NewMyEachCustomTextarea
                title="Bio"
                // stateValue={patientlist?.name}
                name="about"
                placeholder="Enter Bio"
                stateValue={client}
                setState={setclient}
                topTitle="true"
              />
            </div>
          </div>
          <div className="Innerinnputsection" style={{ width: "50%" }}>
            <div
              className="subdivision"
              style={{ width: "100%", height: "100%" }}
            >
              <NewMyEachCustomTextarea
                title="Strategy"
                // stateValue={patientlist?.name}
                name="strategy"
                placeholder="Enter Strategy"
                stateValue={client}
                setState={setclient}
                topTitle="true"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="editingSectionTop notClickable">
        <div className="textSectionTop">
          <div className="textheader">Office address</div>
        </div>
        <div className="eddintright">
          <div className="Innerinnputsection">
            <div className="subdivision">
              <NewUIMyEachInput
                title="Address"
                placeholder="Enter Address"
                // stateValue={client?.name}
                name="address"
                stateValue={client}
                setState={setclient}
                topTitle="true"
              />
            </div>
            <div className="subdivision">
              <NewUIMyEachInput
                title="City"
                placeholder="Enter City"
                // stateValue={client?.name}
                name="city"
                stateValue={client}
                setState={setclient}
                topTitle="true"
              />
            </div>
          </div>
          <div className="Innerinnputsection">
            <div className="subdivision">
              <NewUIMyEachInput
                title="State"
                placeholder="Enter State"
                // stateValue={client?.name}
                name="state"
                stateValue={client}
                setState={setclient}
                topTitle="true"
              />
            </div>

            <div className="subdivision">
              <NewUIMyEachInput
                title="Pincode"
                placeholder="Enter Pincode"
                // stateValue={client?.name}
                name="pincode"
                stateValue={client}
                setState={setclient}
                topTitle="true"
              />
            </div>
          </div>

          <div className="Innerinnputsection">
            <div className="subdivision">
              <NewUIMyEachInput
                title="Country"
                placeholder="Enter country"
                // stateValue={client?.name}
                name="countryName"
                stateValue={client}
                setState={setclient}
                topTitle="true"
              />
            </div>

            <div className="subdivision"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
