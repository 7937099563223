import React, { useEffect, useRef, useState } from "react";
import classNames from "./createarticle.module.scss";
import { IoMdArrowRoundBack, IoIosClose } from "react-icons/io";
import { FaPlus } from "react-icons/fa6";
import {
  MyEachCustomImageUpload,
  NewEachCustomDropdown,
  NewEachCustomDropdownOpen,
  NewMyEachCustomTextarea,
} from "../../../ProfileEditingPage/GloabelInput";
import axios from "axios";
import CustomInput, {
  CustomInputMultiple,
  CustomInputWithBox,
} from "../../../../components/Custom";
import { toast } from "react-toastify";

const CreateArticle = ({ setCreateArticle }) => {
  const [newArticle, setNewArticle] = useState({
    email: "shorupandomains@gmail.com",
    user_id: "669c00592530588a424320eb",
    categoryType: [],
    navbar_id: [],
    metatags: [],
    keywords: [],
    altTag: "",
    custom_url: "",
  });
  const [allPublications, setAllPublications] = useState([]);
  const [allAuthors, setAllAuthors] = useState([]);
  const [allCategory, setAllCategory] = useState([]);
  const [allNavbars, setAllNavbars] = useState([]);

  //functions

  async function getAllDropdowns(URL, setState) {
    try {
      let response = await axios.get(URL);
      if (response?.data?.status) {
        setState(response?.data?.data);
      }
      console.log(response, "Get all dropdowns response");
    } catch (error) {
      console.log(error?.message, "Get all dropdowns error");
    }
  }

  async function submitNewArticle() {
    // Extract _id from categoryType
    const categoryTypeIds = newArticle?.categoryType?.map((item) => item._id);

    // Extract _id from navbar_id
    const navbarIds = newArticle?.navbar_id?.map((item) => item._id);

    let obj = {
      ...newArticle,
      categoryType: categoryTypeIds,
      navbar_id: navbarIds,
      publication_id: "66479e22530d1d0cd205a7dd",
    };

    console.log(newArticle, obj);
    try {
      let response = await axios.post(
        "https://publications.apimachine.com/article/new",
        obj
      );
      if (response?.data?.status) {
        toast("Created new article successfully!");
        setCreateArticle(false);
      } else {
        toast(response?.data?.message);
      }
      console.log(response, "Submit new article response");
    } catch (error) {
      console.log(error?.message, "Submit new article error");
    }
  }

  //renderings

  useEffect(() => {
    getAllDropdowns(
      "https://publications.apimachine.com/publication/",
      setAllPublications
    );
    getAllDropdowns(
      "http://publications.apimachine.com/publisher/",
      setAllAuthors
    );
    getAllDropdowns(
      "https://publications.apimachine.com/category",
      setAllCategory
    );
    getAllDropdowns(
      "https://publications.apimachine.com/navbar",
      setAllNavbars
    );
  }, []);

  return (
    <div className={classNames.createArticle}>
      <div className={classNames.header}>
        <div className={classNames.title}>
          <div>New Article</div>
          <div>
            <div>Add </div>
            <div>{`->`}</div>
            <div>New Article</div>
          </div>
        </div>
        <div className={classNames.btnsContainer}>
          <div>
            <IoMdArrowRoundBack onClick={() => setCreateArticle(false)} />
          </div>
          <div>
            <IoIosClose onClick={() => setCreateArticle(false)} />
          </div>
        </div>
      </div>
      <div className={classNames.createArticleForm}>
        {/* <NewEachCustomDropdown
          dropdown={allPublications}
          name="publication_id"
          title="Select Publication"
          stateValue={newArticle}
          setState={setNewArticle}
          topTitle="true"
          type="obj"
          mapVal={{ name: "name", image: "profile_pic", subName: "fxa_app_id" }}
          stateVal="_id"
        /> */}
        {/* <NewEachCustomDropdown
          dropdown={allAuthors}
          name={"email"}
          name2={"user_id"}
          title="Select Author"
          stateValue={newArticle}
          setState={setNewArticle}
          topTitle="true"
          type="obj"
          mapVal={{ name: "name", image: "profile_pic", subName: "email" }}
          stateVal={{ name1: "email", name2: "user_id" }}
        /> */}
        <CustomInput
          title="Article Title"
          titleSize={"1.3rem"}
          placeholder="Enter Title..."
          stateValue={newArticle}
          setState={setNewArticle}
          name="title"
        />
        <CustomInput
          title="Article Excerpt"
          titleSize={"1.3rem"}
          placeholder="Excerpt..."
          stateValue={newArticle}
          setState={setNewArticle}
          name="desc"
        />
        <NewMyEachCustomTextarea
          title="Enter Article"
          name="article"
          placeholder="Article..."
          stateValue={newArticle}
          setState={setNewArticle}
          topTitle="true"
        />
        <div className={classNames.uploadPic}>
          <div className={classNames.title}>Upload Images</div>
          <div>
            <MyEachCustomImageUpload
              title="Coloured Icon"
              name="icon"
              stateValue={newArticle}
              setState={setNewArticle}
              topTitle="true"
            />
            <MyEachCustomImageUpload
              title="Cover Photo"
              name="media"
              stateValue={newArticle}
              setState={setNewArticle}
              topTitle="true"
            />
          </div>
        </div>
        <AddMultipleItems
          dropdownItems={allCategory}
          stateValue={newArticle}
          setState={setNewArticle}
          name="categoryType"
          mapVal={{
            name: "title",
            image: "thumbnail",
            subName: "_id",
          }}
        />
        <AddMultipleItems
          dropdownItems={allNavbars}
          stateValue={newArticle}
          setState={setNewArticle}
          name="navbar_id"
          mapVal={{
            name: "navTitle",
            image: "icon",
            subName: "_id",
          }}
        />
        <CustomInput
          title="Action Button Title"
          titleSize={"1.3rem"}
          placeholder="Title..."
          stateValue={newArticle}
          setState={setNewArticle}
          name="action"
        />
        <CustomInputWithBox
          title="Action Button Link"
          titleSize={"1.3rem"}
          placeholder="Website..."
          stateValue={newArticle}
          setState={setNewArticle}
          name="action_link"
          inputText="https://"
        />
        <CustomInputMultiple
          title="Add Metatags"
          titleSize={"1.3rem"}
          placeholder="Enter Metatag.."
          stateValue={newArticle}
          setState={setNewArticle}
          name="metatags"
          func={(value) =>
            setNewArticle((prev) => {
              return { ...prev, metatags: [...prev?.metatags, value] };
            })
          }
        />
        <CustomInputMultiple
          title="Add Key Words"
          titleSize={"1.3rem"}
          placeholder="Enter Key Words.."
          stateValue={newArticle}
          setState={setNewArticle}
          name="keywords"
          func={(value) =>
            setNewArticle((prev) => {
              return { ...prev, keywords: [...prev?.keywords, value] };
            })
          }
        />
      </div>
      <div className={classNames.submitBtn} onClick={submitNewArticle}>
        Submit
      </div>
    </div>
  );
};

export default CreateArticle;

const AddMultipleItems = ({
  dropdownItems,
  stateValue,
  setState,
  name,
  mapVal,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  //   const [allSelectedItems, setAllSelectedItems] = useState([]);

  const addMultipleDropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        addMultipleDropdownRef.current &&
        !addMultipleDropdownRef.current.contains(event.target)
      ) {
        setIsDropdownOpen(false);
      }
    };

    if (isDropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isDropdownOpen]);

  useEffect(() => {
    console.log(stateValue[name], "allSelectedItems");
    setIsDropdownOpen(false);
  }, [stateValue]);

  return (
    <div className={classNames.addMultipleItems} ref={addMultipleDropdownRef}>
      <div className={classNames.title}>Add Categories</div>
      <div className={classNames.allItems}>
        {stateValue[name] &&
          Array.isArray(stateValue[name]) &&
          stateValue[name]?.length > 0 &&
          stateValue[name]?.map((eachItem, index) => {
            return (
              <div className={classNames.eachItem}>
                <div className={classNames.name}>{eachItem[mapVal?.name]}</div>
                <div className={classNames.removeBtn}>X</div>
              </div>
            );
          })}
        <div
          className={classNames.eachItem}
          onClick={() => setIsDropdownOpen(true)}
        >
          {isDropdownOpen ? (
            <NewEachCustomDropdownOpen
              dropdown={dropdownItems}
              name={name}
              title="Select Author"
              stateValue={stateValue}
              setState={setState}
              type="arrayItems"
              mapVal={mapVal}
              stateVal="_id"
              isDropdownOpen={isDropdownOpen}
              setIsDropdownOpen={setIsDropdownOpen}
            />
          ) : (
            <FaPlus />
          )}
        </div>
      </div>
    </div>
  );
};
