import React, { useState, useEffect, useContext } from "react";
import {
  NewUIMyEachInput,
  NewDateAndPicker,
  NewEachCustomDropdown,
  NewEachCustomImageUploadcustom,
  NewMyEachCustomTextarea,
  MyEachCustomImageUpload,
} from "./GloabelInput";
import { Children } from "react/cjs/react.production.min";
import axios from "axios";
import { Globalcontext } from "../../contexts/GlobalContext";
import { SketchPicker } from "react-color";
export default function UssrProfile({ settabName }) {
  const { email } = useContext(Globalcontext);
  const [AllCountries, setAllCountries] = useState([]);
  useEffect(() => {
    getAllCountries();
  }, []);
  async function getAllCountries() {
    axios
      .get(`https://comms.globalxchange.io/coin/vault/countries/data/get`)
      .then((res) => {
        if (res.data.status) {
          setAllCountries(res?.data?.countries);
        } else {
          setAllCountries([]);
        }
      });
  }
  const [client, setclient] = useState({
    jname: "",

    State: "",

    Pincode: "",

    address: "",
    city: "",

    country: "",

    email: "",
    Yearsofexperience: "",
    number: "",
    Facebook: "",
    Instagram: "",
    Linkedin: "",
    coverpic: "",
    Colourcode: "",
    Experience: "",
    Bio: "",
    Strategy: "",
    flag: "",
    wphoto: "",
  });
  console.log("client", client);

  const submitfuction = () => {
    axios
      .post(
        `https://api.retired.app/retired/user/add/level2profile?email=${localStorage.getItem(
          "LoginAccount"
        )}`,
        {
          email: localStorage.getItem("LoginAccount"),
          token: localStorage.getItem("AccessToken"),
          coverPhoto: client?.coverpic,
          profilePhoto: client?.photo,
          about: client?.Bio,
          jobTitle: client?.jname,
          whatsappNumber: client?.number,
          contactEmail: client?.email,

          facebook: client?.Facebook,
          linkedIn: client?.Linkedin,
          instagram: client?.Instagram,
          yearsOfExperience: client?.Yearsofexperience,
          colorCode: client?.Colourcode?.includes("#")
            ? client.Colourcode.replace("#", "")
            : client?.Colourcode,
          logo: client?.wphoto,
          strategy: client?.Strategy,
          address: client?.address,
          state: client?.State,
          city: client?.city,
          pincode: client?.Pincode,
          countryName: client?.country,
        }
      )
      .then(({ data }) => {
        if (data.status) {
          settabName("Products & Services");
        } else {
        }
      });
  };
  return (
    <div className="editing-page">
      <div className="editingSectionTop">
        <div className="textSectionTop">
          <div className="textheader">Basic Information</div>
          <div
            style={{
              marginTop: "3.5rem",
            }}
          >
            <NewEachCustomImageUploadcustom
              title="Upload"
              name="photo"
              placeholder="Profile picture "
              stateValue={client}
              setState={setclient}
              topTitle={false}
              btnText="Upload Profile"
            />
          </div>
        </div>
        <div className="eddintright">
          <div className="Innerinnputsection">
            <div className="subdivision">
              <NewUIMyEachInput
                title="Job title"
                placeholder="Enter Job title"
                // stateValue={client?.name}
                name="jname"
                stateValue={client}
                setState={setclient}
                topTitle="true"
              />
            </div>
            <div className="subdivision">
              <NewUIMyEachInput
                title="Years of experience"
                placeholder="Enter Years of experience"
                // stateValue={client?.name}
                name="Yearsofexperience"
                stateValue={client}
                setState={setclient}
                topTitle="true"
              />
            </div>
          </div>
          <div className="Innerinnputsection">
            <div className="subdivision">
              <NewUIMyEachInput
                title="Contact email"
                placeholder="Contact email"
                // stateValue={client?.name}
                name="email"
                stateValue={client}
                setState={setclient}
                topTitle="true"
              />
            </div>

            <div className="subdivision">
              <NewUIMyEachInput
                title="Contact Phone Number"
                placeholder="Enter Phone Number"
                // stateValue={client?.name}
                name="number"
                stateValue={client}
                setState={setclient}
                topTitle="true"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="editingSectionTop">
        <div className="textSectionTop">
          <div className="textheader">Social media</div>
        </div>
        <div className="eddintright">
          <div className="Innerinnputsection">
            <div className="subdivision">
              <NewUIMyEachInput
                title="Facebook"
                placeholder="Facebook Link"
                // stateValue={client?.name}
                name="Facebook"
                stateValue={client}
                setState={setclient}
                topTitle="true"
              />
            </div>
            <div className="subdivision">
              <NewUIMyEachInput
                title="Instagram"
                placeholder="Instagram Link"
                // stateValue={client?.name}
                name="Instagram"
                stateValue={client}
                setState={setclient}
                topTitle="true"
              />
            </div>
          </div>
          <div className="Innerinnputsection">
            <div className="subdivision">
              <NewUIMyEachInput
                title="Linkedin"
                placeholder="Linkedin Link"
                // stateValue={client?.name}
                name="Linkedin"
                stateValue={client}
                setState={setclient}
                topTitle="true"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="editingSectionTop">
        <div className="textSectionTop">
          <div className="textheader">Images</div>
        </div>
        <div className="eddintright">
          <div className="Innerinnputsection">
            <div
              className="subdivision"
              style={{
                width: "100%",
              }}
            >
              <MyEachCustomImageUpload
                title="Upload"
                name="coverpic"
                placeholder=""
                stateValue={client}
                setState={setclient}
                topTitle="true"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="editingSectionTop">
        <div className="textSectionTop">
          <div className="textheader">Website information</div>
          <div
            style={{
              marginTop: "3.5rem",
            }}
          >
            <NewEachCustomImageUploadcustom
              title="Upload"
              name="wphoto"
              placeholder="Profile picture "
              stateValue={client}
              setState={setclient}
              topTitle={false}
              btnText="Upload Logo"
            />
          </div>
        </div>
        <div className="eddintright" style={{ display: "flex", gap: "1rem" }}>
          <div
            className="Innerinnputsection"
            style={{ flexDirection: "column", width: "50%" }}
          >
            <div className="subdivision" style={{ width: "100%" }}>
              <SketchPicker
                color={client?.Colourcode}
                onChangeComplete={(color) => {
                  setclient((prev) => {
                    return { ...prev, Colourcode: color?.hex };
                  });
                }}
              />
            </div>
            <div className="subdivision" style={{ width: "100%" }}>
              <NewMyEachCustomTextarea
                title="Bio"
                // stateValue={patientlist?.name}
                name="Bio"
                placeholder="Enter Bio"
                stateValue={client}
                setState={setclient}
                topTitle="true"
              />
            </div>
          </div>
          <div className="Innerinnputsection" style={{ width: "50%" }}>
            <div
              className="subdivision"
              style={{ width: "100%", height: "100%" }}
            >
              <NewMyEachCustomTextarea
                title="Strategy"
                // stateValue={patientlist?.name}
                name="Strategy"
                placeholder="Enter Strategy"
                stateValue={client}
                setState={setclient}
                topTitle="true"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="editingSectionTop">
        <div className="textSectionTop">
          <div className="textheader">Office address</div>
        </div>
        <div className="eddintright">
          <div className="Innerinnputsection">
            <div className="subdivision">
              <NewUIMyEachInput
                title="Address"
                placeholder="Enter Address"
                // stateValue={client?.name}
                name="address"
                stateValue={client}
                setState={setclient}
                topTitle="true"
              />
            </div>
            <div className="subdivision">
              <NewUIMyEachInput
                title="City"
                placeholder="Enter City"
                // stateValue={client?.name}
                name="city"
                stateValue={client}
                setState={setclient}
                topTitle="true"
              />
            </div>
          </div>
          <div className="Innerinnputsection">
            <div className="subdivision">
              <NewUIMyEachInput
                title="State"
                placeholder="Enter State"
                // stateValue={client?.name}
                name="State"
                stateValue={client}
                setState={setclient}
                topTitle="true"
              />
            </div>

            <div className="subdivision">
              <NewUIMyEachInput
                title="Pincode"
                placeholder="Enter Pincode"
                // stateValue={client?.name}
                name="Pincode"
                stateValue={client}
                setState={setclient}
                topTitle="true"
              />
            </div>
          </div>

          <div className="Innerinnputsection">
            <div className="subdivision">
              <NewEachCustomDropdown
                dropdown={AllCountries}
                name="country"
                title="Select Country"
                stateValue={client}
                setState={setclient}
                topTitle="true"
                type="obj"
              />
            </div>

            <div className="subdivision"></div>
          </div>
        </div>
      </div>

      <div className="labelsubmit">
        <div className="submitlabel" onClick={submitfuction}>
          Submit
        </div>
      </div>

      {/* <div className="Innerinnputsection">

                <div className='subdivision'>

                    <NewDateAndPicker
                        title="Date of birth"
                        placeholder="DD/MM/YYYY"
                        stateValue={client}
                        setState={setclient}
                        name="Birthday"
                        topTitle="true"
                    />
                </div>
                <div className='subdivision'>

                    <NewEachCustomDropdown
                        dropdown={[
                            "Male",
                            "Female",

                        ]}
                        name="gender"
                        title="Gender"
                        placeholder="Select Gender"
                        Select Gender
                        stateValue={client}
                        setState={setclient}
                        topTitle="true"
                        type="single"
                    />


                </div>
                <div className='subdivision'>
                    <NewUIMyEachInput
                        title="Height (inches)"
                        // stateValue={client?.name}
                        placeholder="Enter Height"
                        name="height"
                        stateValue={client}
                        setState={setclient}
                        topTitle="true"
                    />
                </div>

                <div className='subdivision'>
                    <NewUIMyEachInput
                        title="Weight (Kgs)"
                        // stateValue={client?.name}
                        name="weight"
                        placeholder="Enter Weight in Kgs"
                        stateValue={client}
                        setState={setclient}
                        topTitle="true"
                    />
                </div>
            </div>


            <div className="Innerinnputsection">
                <div className='subdivision'>

              


                </div>
            </div> */}
    </div>
  );
}
