import React, { useState } from "react";
import AdvisorSidebar from "../../../components/AdvisorSidebar/AdvisorSidebar";

import BackArrowImg from "../../../assets/Images/AdvisorList/Vector.png";
import AdvisorProfileEdit from "../../../components/Advisors/AdvisorProfileEdit/AdvisorProfileEdit";
import { useHistory } from "react-router-dom";

import "./EditAdvisorProfileInfo.scss";
import AdvisorSidebarLatest from "../../../components/AdvisorSidebarLatest";
import Header from "../AdvisorProfile/Header";

const EditAdvisorProfileInfo = () => {
  const [isEditing, setIsEditing] = useState(false);
  const history = useHistory();

  const handleSave = (formData) => {
    console.log("Saved data:", formData);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  return (
    <div className="advisor-profile-pagemainflex">
      <Header />
      <div className="flexContainers">
        <AdvisorSidebarLatest />
        <div className="advisor-profile-rightsidedatashow">
          <AdvisorProfileEdit onSave={handleSave} onCancel={handleCancel} />
        </div>
      </div>
    </div>
  );
};

export default EditAdvisorProfileInfo;
