import React, { useState, useContext, useEffect } from 'react';
import './ClientGoal.scss';
import AdvisorSidebar from '../../../../components/AdvisorSidebar/AdvisorSidebar';
import { useHistory } from 'react-router-dom';

import axios from 'axios';
import BackArrowImg from '../../../../assets/Images/AdvisorList/back_arrow.png';
import { FiPlus } from "react-icons/fi";
import { Globalcontext } from '../../../../contexts/GlobalContext';


const ClientGoal = () => {
    const history = useHistory();
    const { email, token, tostShowOn, Selectitem, setfinalDetail, clinetDetail, setclinetDetail, setgobalseacrhadviser, opentoggleclass, setopentoggleclass } = useContext(Globalcontext);
    const [proposal, setproposal] = useState([])
    useEffect(() => {
        proposalFucntion()
    }, [Selectitem?.retirement_plan_id])
    const timestamp = Date.now(); // This would be the timestamp you want to format


    const proposalFucntion = () => {
        axios.get(
            `https://comms.globalxchange.io/retired/proposal/get?PlanbID=${Selectitem?.retirement_plan_id}`
        ).then(({ data }) => {
            if (data.status) {

                setproposal(data?.data)

                //setdatalist(data?.data);

            }
            else {
                setproposal([])
            }
        });
    }
    const onClickdata = (e) => {
        setfinalDetail(e)
        history.push('/advisor-client/client-goal/proposal')
    }

    return (
        <div className="advisor-chat-page-main-flex">
            <AdvisorSidebar />
            <div className="main-content">
                <div className="header"
                    style={{
                        width: "max-content"
                    }}
                >
                    <button className="backButton" onClick={() => history.goBack()}>
                        <img src={BackArrowImg} alt="back arrow" />
                    </button>
                    <span className="profileName">{Selectitem?.retirement_plan_name}</span>
                </div>
                <div className="client-goal-main">
                    <div className="client-goal-left">
                        <div className="client-goal-name">
                            <span className="label">Name:</span>
                            <input className="value-input" type="text" defaultValue={Selectitem?.retirement_plan_name} />
                        </div>
                        <div className="client-goal-saved-amount">
                            <h4>Already Saved amount</h4>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Saved in</th>
                                        <th>Amount Saved</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="client-goal-right">
                        <div className='client-flex'>
                            <div className="client-goal-name">
                                <span className="label">Total Amount:</span>
                                <input className="value-input" type="text" defaultValue={Selectitem?.amountToAccomplish} />
                            </div>
                            <div className="client-goal-duration">
                                <span className="label">Duration:</span>
                                <input className="value-input half-width" type="text" defaultValue={Selectitem?.date_of_accomplishment} />
                            </div>
                        </div>
                        <div className='report-parent'>
                            <h4>Reports</h4>
                            <div className="report-section">
                                {
                                    proposal?.map(item => {
                                        return (
                                            <>
                                                <div className="report-item"


                                                >
                                                    <span
                                                        onClick={() => onClickdata(item)}
                                                    >{new Intl.DateTimeFormat('en-GB', {
                                                        year: 'numeric',
                                                        month: '2-digit',
                                                        day: '2-digit'
                                                    }).format(item?.timestamp)}</span>
                                                    <span className="status rejected"

                                                        style={{
                                                            textTransform: "capitalize",
                                                            color: "#000"
                                                            // color: item?.proposalStatus?.toLowerCase() == "approved" ? "#0D9C13" : item?.proposalStatus?.toLowerCase() == "request for resubmission" ? "In progress" :
                                                            //     item?.proposalStatus?.toLowerCase() == "rejected" && "red"
                                                        }}
                                                    >{item?.proposalStatus}</span>
                                                </div>                                       </>

                                        )
                                    })
                                }
                                {/* <div className="report-item">
                                    <span>Buy a car 1</span>
                                    <span className="status rejected">Rejected</span>
                                </div>
                                <div className="report-item">
                                    <span>Buy a car plan 2</span>
                                    <span className="status approved">Approved</span>
                                </div>
                                <div className="report-item">
                                    <span>Buy a car 2</span>
                                    <span className="status in-progress">In progress</span>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <button className="create-report-button"><FiPlus size={20} /> CREATE REPORT</button>
            </div>
        </div>
    );
};

export default ClientGoal;
