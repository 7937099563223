import React from "react";
import Firstsection from "../../components/Landing/sections/Firstsection/Firstsection";
import Navbar from "../../components/Navbar/Navbar";
import "./coach.css";

const Coach = () => {
  return (
    <section className="coach-page">
      <Navbar />
      <Firstsection />
    </section>
  );
};

export default Coach;
