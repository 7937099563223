import { FiSearch } from "react-icons/fi";
import React, { useState, useContext } from "react";
import ReactQuill from "react-quill";
import AdvisorSidebar from "../../../../components/AdvisorSidebar/AdvisorSidebar";
import "./ClientChat.scss";

import AmazonImg from "../../../../assets/Images/AdvisorList/amazon.png";
import DocImg from "../../../../assets/Images/AdvisorList/doc.png";
import FilterImg from "../../../../assets/Images/AdvisorList/settings.png";
import sendIconImg from "../../../../assets/Images/AdvisorList/sendIcon.png";
import ToyCarImg from "../../../../assets/Images/AdvisorList/toy_car.png";
import PersonIconImg from "../../../../assets/Images/AdvisorList/person.png";
import BackArrowImg from "../../../../assets/Images/AdvisorList/back_arrow.png";
import { useHistory } from "react-router-dom";
import { Globalcontext } from "../../../../contexts/GlobalContext";
import chaticon from "../../../../assets/chaticon.svg";
import Header from "../../AdvisorProfile/Header";
import AdvisorSidebarLatest from "../../../../components/AdvisorSidebarLatest";

const reports = [
  {
    id: 1,
    title: "New report from Harvey",
    idText: "Abc-report-125898",
    image: DocImg,
  },
  {
    id: 2,
    title: "Amazon had a stock boom...",
    idText: "Abc-report-125898",
    image: AmazonImg,
  },
  {
    id: 3,
    title: "New report from Harvey",
    idText: "Abc-report-125898",
    image: DocImg,
  },
];

const ClientChat = () => {
  const [globalseacrhadviser, setglobalseacrhadviser] = useState("");
  const [message, setMessage] = useState("");
  const {
    email,
    token,
    tostShowOn,
    proposal,
    setSelectitem,
    clinetDetail,
    setclinetDetail,
    setgobalseacrhadviser,
    opentoggleclass,
    setopentoggleclass,
  } = useContext(Globalcontext);
  const modules = {
    toolbar: {
      container: "#toolbar",
    },
  };
  console.log("Message sent:", proposal);
  const handleSendMessage = () => {
    setMessage("");
  };
  const [Namelist, setNamelist] = useState("Chat");

  const history = useHistory();
  const datafunction = (e) => {
    setSelectitem(e);
    history.push(`/advisor-client/client-goal`);
  };
  return (
    <div>
      <div className="advisor-chat-pagemainflex">
        <Header />
        <div className="flexContainers">
          <AdvisorSidebarLatest />

          <div className="advisor-chat-advisor-rightsidedatashow">
            {/* <div className="searchProfileContainer">
            <div className="profileTextContainer">
              <div className="topBar">
                <button className="backButton">
                  <img
                    src={BackArrowImg}
                    alt="back arrow"
                    onClick={() => history.goBack()}
                  />
                </button>
                <div className="profileIconsContainer">
                  <img
                    style={{
                      borderRadius: "10rem",
                    }}
                    src={clinetDetail?.ProfileCount?.[0]?.profilePic}
                    alt="Share Icon"
                    className="shareIcon"
                  />
                </div>
              </div>
              <span className="profileName">
                {clinetDetail?.ProfileCount?.[0]?.firstName}
              </span>
            </div>
          </div> */}

            <div className="chatContainer">
              <div className="chat-sec">
                <div className="chatlabel-sec">
                  <div
                    className="chatlabel"
                    style={{
                      background: Namelist != "Chat" && "#fff",
                      color: Namelist != "Chat" && "#000",
                      border: Namelist != "Chat" && "1px solid #D9D9D9",
                    }}
                    onClick={() => setNamelist("Chat")}
                  >
                    Chat
                  </div>
                  <div
                    className="chatlabel"
                    style={{
                      background: Namelist != "Email" && "#fff",
                      color: Namelist != "Email" && "#000",
                      border: Namelist != "Email" && "1px solid #D9D9D9",
                    }}
                    onClick={() => setNamelist("Email")}
                  >
                    {" "}
                    Email
                  </div>
                </div>
                <div className="chatBox">
                  <div className="chatContent">
                    {/* <div className="message">
                    <div className="messageText">
                    
                    </div>
                    <div className="messageDate"></div>
                  </div> */}
                  </div>
                  <div className="chatInput">
                    <ReactQuill
                      value={message}
                      onChange={setMessage}
                      placeholder="Type Here"
                      className="editor"
                      modules={modules}
                    />
                    <div id="toolbar" className="quill-toolbar">
                      <div className="toolbar-buttons-left">
                        <button className="ql-bold"></button>
                        <button className="ql-italic"></button>
                        <button className="ql-underline"></button>
                        <button className="ql-link"></button>
                        <button className="ql-list" value="ordered"></button>
                        <button className="ql-list" value="bullet"></button>
                      </div>

                      <button
                        className="sendButton"
                        onClick={handleSendMessage}
                      >
                        <img src={chaticon} alt="Send" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="chat-main">
                <div className="goals-reports">
                  <div className="goals">
                    <h4>Goals</h4>
                    <div>
                      {clinetDetail?.PlanCount?.map((item) => {
                        return (
                          <div
                            className="goals-child"
                            onClick={() => datafunction(item)}
                          >
                            <div>
                              <img
                                style={{
                                  height: "28px",
                                  width: "28px",
                                  borderRadius: "10rem",
                                }}
                                src={item?.icon}
                                alt=""
                              />
                            </div>
                            <div className="goals-toy">
                              <h5 className="goals-title">
                                {item?.retirement_plan_name}
                              </h5>
                              <p className="goals-priority">
                                {item?.date_of_accomplishment}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div className="addnewbutton">Add New</div>
                  </div>
                  <div className="reportsBox">
                    <div className="addnewbutton">Add New</div>
                    <div className="inner-report">
                      <div className="reportsHeader">
                        <h3 className="reportsTitle">Reports</h3>

                        <div className="filterIcon">
                          <img src={FilterImg} alt="Filter" />
                        </div>
                      </div>

                      <div className="reportSection">
                        {proposal?.map((item) => {
                          return (
                            <div className="reportItem">
                              <img
                                src={item?.PlanDetails?.[0]?.icon}
                                // alt={report.title}
                                className="reportImage"
                              />
                              <div className="reportDetails">
                                <div className="reportTitle">
                                  {item?.PlanDetails?.[0]?.retirement_plan_name}
                                </div>
                                <div className="reportId">
                                  {item?.timestamp}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="investment">
                  <div className="investment-text">
                    <h5 className="investment-title">Investments</h5>
                    <div className="investment-details">
                      <p className="investment-total">Total investments</p>
                      <p className="investment-amount">40 lakhs</p>
                    </div>
                  </div>
                  <div className="investment-avg">
                    <div className="fund-box mutual-funds">
                      <p className="fund-percentage">64%</p>
                      <p className="fund-type">
                        Mutual <br /> funds
                      </p>
                    </div>
                    <div className="fund-box gold">
                      <p className="fund-percentage">14%</p>
                      <p className="fund-type">Gold</p>
                    </div>
                    <div className="fund-box others">
                      <p
                        className="fund-percentage"
                        style={{
                          color: "white",
                        }}
                      >
                        22%
                      </p>
                      <p
                        className="fund-type"
                        style={{
                          color: "white",
                        }}
                      >
                        Others
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientChat;
