import React, { useEffect, useState } from "react";
import styles from "./user.module.scss";
import "./carausel.css";
import { useParams } from "react-router-dom";
import axios from "axios";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import testimonialAvatar from "./testimonialAvatar.png";
import blog from "./blog.png";
import { FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { CiFacebook } from "react-icons/ci";
import { useHistory } from "react-router-dom";
import HeaderNav from "../../components/HeaderNav";

const Userpage = () => {
  let history = useHistory();
  const { email } = useParams();
  const [userData, setUserData] = useState(null);
  const [loadingUserData, setLoadingUserData] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const handleChange = (index) => {
    setCurrentIndex(index);
  };
  const [dotColor, setDotColor] = useState("#000000");
  const renderIndicator = (onClickHandler, isSelected, index, label) => {
    const style = {
      backgroundColor: isSelected ? dotColor : "#ccc", // Selected dot color vs default color
      width: 10,
      height: 10,
      borderRadius: "50%",
      display: "inline-block",
      margin: "0 8px",
      cursor: "pointer",
    };

    return (
      <li
        key={index}
        style={style}
        onClick={onClickHandler}
        onKeyDown={onClickHandler}
        value={index}
        role="button"
        tabIndex={0}
        aria-label={`${label} ${index + 1}`}
      />
    );
  };

  // const testimonials = [
  //   {
  //     name: "Rakesh.1",
  //     text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  //     avatar: testimonialAvatar, // Replace with actual avatar image path
  //     bgColor: "#136B8E",
  //   },
  //   {
  //     name: "Rakesh.2",
  //     text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  //     avatar: testimonialAvatar, // Replace with actual avatar image path
  //     bgColor: "#136B8E",
  //   },
  //   {
  //     name: "Rakesh.3",
  //     text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  //     avatar: testimonialAvatar, // Replace with actual avatar image path
  //     bgColor: "#136B8E",
  //   },
  //   {
  //     name: "Rakesh.4",
  //     text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  //     avatar: testimonialAvatar, // Replace with actual avatar image path
  //     bgColor: "#136B8E",
  //   },
  //   {
  //     name: "Rakesh.5",
  //     text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  //     avatar: testimonialAvatar, // Replace with actual avatar image path
  //     bgColor: "#136B8E",
  //   },
  // ];

  const [testimonials, setTestimonials] = useState([]);
  const getTestimonials = (email) => {
    axios
      .get(
        `https://api.retired.app/retired/testimonial/get?advisoremail=${email}`
      )
      .then(({ data }) => {
        if (data.status) {
          setTestimonials(data.data);
        }
      });
  };
  useEffect(() => {
    if (email) {
      setLoadingUserData(true);
      axios
        .get(
          `https://comms.globalxchange.io/retired/user/get/userprofiles?email=${email}`
        )
        .then(({ data }) => {
          if (data.status) {
            setUserData(data?.data[0]);
            setLoadingUserData(false);
            // setDotColor(`#${data?.data[0]?.colorCode}`);
          } else {
            setLoadingUserData(false);
          }
        });

      getProducts(email);
      getBlogs(email);
      getTestimonials(email);
      getServices(email);
    }
  }, [email]);

  const [products, setProducts] = useState(null);
  const [assets, setAssets] = useState(null);
  const [subAssets, setSubAssets] = useState(null);
  const [companies, setCompanies] = useState(null);
  const getProducts = (email) => {
    axios
      .get(
        `https://api.retired.app/retired/user/get/userprofiles?email=${email}&userType=advisor`
      )
      .then(({ data }) => {
        if (data.status) {
          setProducts(data?.data[0]?.ProductDetails);
          setAssets(data?.data[0]?.assetClassDetails);
          setSubAssets(data?.data[0]?.subAssetClassDetails);
          setCompanies(data?.data[0]?.investmentProviderDetails);
        }
      });
  };

  const [services, setServices] = useState(null);
  const getServices = (email) => {
    axios
      .get(
        `https://api.retired.app/retired/service/advisor/get?advisoremail=${email}`
      )
      .then(({ data }) => {
        if (data.status) {
          setServices(data.data);
        }
      });
  };

  const [blogs, setBlogs] = useState([]);
  const getBlogs = (email) => {
    axios
      .get(`https://publications.apimachine.com/article?email=${email}`)
      .then(({ data }) => {
        if (data.status) {
          setBlogs(data?.data);
        }
      });
  };
  return (
    <div style={{ zoom: "125%" }}>
      <HeaderNav userData={userData} />
      <div style={{ padding: "0px 0vw" }}>
        <div className={styles.section2}>
          <div className={styles.section2Left}>
            <div className={styles.userAvatar}>
              <img src={userData?.profilePic} alt="" />
            </div>
            <div className={styles.userDetails}>
              <div>{userData?.city ? userData?.city : "N/A"}</div>
              <div>
                {userData?.firstName} {userData?.lastName}
              </div>
              <div>
                {userData?.jobTitle} | {userData?.brokerAgeName}
              </div>
              <div>{userData?.yearsOfExperience} Years of experience</div>
            </div>
          </div>
          <div className={styles.section2Right}>
            <div
              className={styles.btnSecondary}
              style={{ borderColor: `#${userData?.colorCode}` }}
            >
              Free Consultation
            </div>
            <div
              className={styles.btnPrimary}
              style={{ backgroundColor: `#${userData?.colorCode}` }}
            >
              Ask a question
            </div>
          </div>
        </div>
        <div className={styles.section3} id="about">
          <div className={styles.section3Title}>
            About {userData?.firstName}
          </div>
          <div className={styles.section3Body}>{userData?.about}</div>
        </div>
        <div
          className={styles.section4}
          style={{ backgroundColor: `#${userData?.colorCode}` }}
        >
          <img src={userData?.coverPhoto} alt="" className={styles.sec4img} />
          <div className={styles.sec4Text}>
            <div className={styles.sec4Title}>Our Strategy</div>
            <div className={styles.sec4SubTitle}>Put Your Goals First</div>
            <div className={styles.sec4body}>
              {userData?.strategy?.substring(0, 300)}...
            </div>
          </div>
        </div>
        {/* <div
          className={styles.section4}
          style={{ backgroundColor: `#${userData?.colorCode}` }}
        >
          <div
            className={styles.section4Img}
            style={{
              backgroundImage: userData?.coverPhoto,
              width: "36.688rem",
              height: "23.25rem",
            }}
          >
            &nbsp;
          </div>
          <div>
            <div className={styles.section4Title}>
              a Lorem ipsum dolor sit amet, consectetur ad
            </div>
            <div className={styles.section4Body}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam empor incididunt ut labore et dolore magna
              aliqua. Ut enim ad minim venia Lorem ipsum dolor sit amet,
              consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
              labore et dolore magna aliqua. lore magna aliqua. Ut enim ad
              minimlore magna aliqua. Ut enim ad minimlore magna aliqua. Ut enim
              ad minimlore magna aliqua. Ut enim ad minim
              <br />
              <br />
              lore magna aliqua. Ut enim ad minimlore magna aliqua. Ut enim ad
              minimlore magna aliqua. Ut enim ad minimlore magna aliqua. Ut
              enim.....................
            </div>
            <div className={styles.readBtn}>Read - {`>`}</div>
          </div>
        </div> */}

        <div className={styles.productSection} id="products">
          <div className={styles.leftSection}>
            <div className={styles.productsTitle}>Products</div>
            {products?.map((item, index) => {
              if (index < 3) {
                return (
                  <div className={styles.productCard}>
                    <div className={styles.productCardTitle}>
                      {item?.productName}
                    </div>
                    <div className={styles.productCardSubTitle}>
                      {item?.description}
                    </div>
                  </div>
                );
              }
            })}
            <div
              className={styles.serviceCardBtn}
              onClick={(e) =>
                history.push(`/advisorprofiles/${email}/products`)
              }
            >
              See All Products
            </div>
          </div>
          <div className={styles.rightSection}>
            <div className={styles.productsTitle}>Services</div>
            {services?.map((item, index) => {
              if (index < 3) {
                return (
                  <div className={styles.serviceCard}>
                    <div className={styles.productCardTitle}>{item?.name}</div>
                    <div className={styles.productCardSubTitle}>
                      {item?.description}
                    </div>
                  </div>
                );
              }
            })}
            <div
              className={styles.serviceCardBtn}
              onClick={(e) =>
                history.push(`/advisorprofiles/${email}/services`)
              }
            >
              See All Services
            </div>
          </div>
        </div>

        <div className={styles.productSection}>
          <div className={styles.leftSection}>
            <div className={styles.productsTitle}>Asset Classes</div>
            {assets?.map((item, index) => {
              if (index < 3) {
                return (
                  <div className={styles.productCard}>
                    <div className={styles.productCardTitle}>{item?.name}</div>
                    <div className={styles.productCardSubTitle}>
                      {item?.description}
                    </div>
                  </div>
                );
              }
            })}
            <div
              className={styles.serviceCardBtn}
              onClick={(e) => history.push(`/advisorprofiles/${email}/assets`)}
            >
              See All Asset Classes
            </div>
          </div>
          <div className={styles.rightSection}>
            <div className={styles.productsTitle}>Sub Asset Class</div>
            {subAssets?.map((item, index) => {
              if (index < 3) {
                return (
                  <div className={styles.serviceCard}>
                    <div className={styles.productCardTitle}>{item?.name}</div>
                    <div className={styles.productCardSubTitle}>
                      {item?.description}
                    </div>
                  </div>
                );
              }
            })}
            <div
              className={styles.serviceCardBtn}
              onClick={(e) =>
                history.push(`/advisorprofiles/${email}/subassets`)
              }
            >
              See All Sub Asset Class
            </div>
          </div>
        </div>
        <div className={styles.productSection}>
          <div className={styles.leftSection}>
            <div className={styles.productsTitle}>Companies</div>
            {companies?.map((item, index) => {
              if (index < 3) {
                return (
                  <div className={styles.productCard}>
                    <div className={styles.productCardTitle}>
                      {item?.displayName}
                    </div>
                    <div className={styles.productCardSubTitle}>
                      {item?.description}
                    </div>
                  </div>
                );
              }
            })}
            <div
              className={styles.serviceCardBtn}
              onClick={(e) =>
                history.push(`/advisorprofiles/${email}/companies`)
              }
            >
              See All Companies
            </div>
          </div>
          <div className={styles.rightSection}></div>
        </div>

        {/* <div className={styles.productsWrapper} id="products">
          <div className={styles.productsTitle}>Products</div>
          {products?.map((item) => (
            <div className={styles.productStyle}>
              <div className={styles.productName}>{item?.productName}</div>
              <div className={styles.productDesc}>{item?.description}</div>
            </div>
          ))}
        </div> */}
        {/* <div className={styles.section5}>
        <div>
          <div className={styles.listTitle}>Services</div>
          <div className={styles.listWrapper}>
            <ul>
              <li>Lorem Ipsum</li>
              <li>Lorem ipsum consectue ajdn</li>
              <li>Johae ajemannadi</li>
              <li>Onja jaledu aammtredum</li>
            </ul>
            <ul>
              <li>Lorem Ipsum</li>
              <li>Lorem ipsum consectue ajdn</li>
              <li>Johae ajemannadi</li>
              <li>Onja jaledu aammtredum</li>
            </ul>
          </div>
        </div>
        <div className={styles.verticalDivider}></div>
        <div>
          <div className={styles.listTitle}>Products</div>
          <div className={styles.listWrapper}>
            <ul>
              <li>Lorem Ipsum</li>
              <li>Lorem ipsum consectue ajdn</li>
              <li>Johae ajemannadi</li>
              <li>Onja jaledu aammtredum</li>
            </ul>
            <ul>
              <li>Lorem Ipsum</li>
              <li>Lorem ipsum consectue ajdn</li>
              <li>Johae ajemannadi</li>
              <li>Onja jaledu aammtredum</li>
            </ul>
          </div>
        </div>
      </div>
      <div className={styles.section6}>
        <div className={styles.listTitle1}>Experience</div>
        <div className={styles.listWrapper1}>
          <ul>
            <li>
              Lorem Ipsum orem ipsum dolor sit amet, consectetur adipiscing
              elit, sed do eiusmod tempor incidi
            </li>
            <li>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incidiorem ipsum dolor sit amet, consectetur
              adipiscing elit, sed do eiusmod tempor incidi
            </li>
            <li>
              Johae orem ipsum dolor sit amet, consectetur adipiscing elit, sed
              do eiusmod tempor incidi
            </li>
            <li>
              Onja jaledu orem ipsum dolor sit amet, consectetur adipiscing
              elit, sed do eiusmod tempor incidiorem ipsum dolor sit amet,
              consectetur adipiscing elit, sed do eiusmod tempor incidi
            </li>
          </ul>
        </div>
      </div> */}
        <div
          style={{
            backgroundColor: "rgba(19, 107, 142, 0.05)",
            padding: "5.438rem 0px",
          }}
        >
          <div className="carouselWrapper">
            <Carousel
              showArrows={false}
              infiniteLoop={true}
              showThumbs={false}
              showStatus={false}
              autoPlay={true}
              interval={5000}
              centerMode={true}
              centerSlidePercentage={33.33}
              selectedItem={currentIndex}
              onChange={handleChange}
              showIndicators={true}
              renderIndicator={renderIndicator} // Add this line
            >
              {testimonials?.map((testimonial, index) => (
                <div
                  key={index}
                  className={`testimonial ${
                    index === currentIndex ? "selected" : ""
                  }`}
                  style={{
                    backgroundColor: `#${userData?.colorCode}`,
                    opacity: index === currentIndex ? 1 : 0.5,
                    height: index === currentIndex ? "19.625rem" : "15.5rem",
                    marginTop: index !== currentIndex ? "2rem" : "",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div
                    className={styles.avatar}
                    style={{
                      width: "5rem",
                      height: "5rem",
                      backgroundColor: "rgba(255, 255, 255, 0.43)",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "2rem",
                      fontWeight: 700,
                    }}
                  >
                    {testimonial?.useremail.charAt(0).toUpperCase()}
                  </div>
                  <h3>{testimonial?.useremail}</h3>
                  <p>{testimonial?.text?.substring(0, 200)}...</p>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
        {/* <div
          className={styles.section6}
          style={{ backgroundColor: `#${userData?.colorCode}` }}
        >
          <div className={styles.listTitle1}>Team Details</div>
          <div className={styles.listWrapper2}>
            <ul>
              <li>Lorem Ipsum</li>
              <li>Lorem ipsum consectue ajdn</li>
              <li>Johae ajemannadi</li>
              <li>Onja jaledu aammtredum</li>
            </ul>
            <ul>
              <li>Lorem Ipsum</li>
              <li>Lorem ipsum consectue ajdn</li>
              <li>Johae ajemannadi</li>
              <li>Onja jaledu aammtredum</li>
            </ul>
          </div>
        </div> */}
        {/* <div
          className={styles.section41}
          style={{ backgroundColor: `#${userData?.colorCode}` }}
        >
          <div
            style={{
              width: "40%",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <img
              src={userData?.profilePic}
              alt=""
              style={{
                width: "17.9375rem",
                height: "23.25rem",
                borderRadius: "1.25rem",
              }}
            />
          </div>
          <div className={styles.sec4Text1} style={{ width: "60%" }}>
            <div className={styles.sec4Title}>Rohith G</div>
            <div className={styles.sec4SubTitle}>Founder & CEO</div>
            <div className={styles.sec4body}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam empor incididunt ut labore et dolore magna
              aliqua. Ut enim ad minim venia Lorem ipsum dolor sit amet,
              consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
              labore et dolore magna aliqua. lore magna aliqua.
            </div>
          </div>
        </div> */}
        <div className={styles.section8} id="blog">
          <div className={styles.section8Title}>Blogs</div>
          <div style={{ display: "flex", gap: "3rem" }}>
            {blogs?.map((item) => (
              <div
                className={styles.blogWrapper}
                onClick={() => {
                  history.push(
                    `/advisorprofiles/${item?.email}/article/${item?.custom_url}`
                  );
                }}
              >
                <img src={item?.icon} alt="" className={styles.blogImg} />
                <div className={styles.blogTitle}>{item?.title}</div>
                <div className={styles.blogSubTitle}>{item?.desc}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div
        className={styles.footerNav}
        style={{ backgroundColor: `#${userData?.colorCode}` }}
      >
        <div className={styles.footerLeft}>
          <img
            src={require("./phone.png").default}
            alt=""
            className={styles.phoneIcon}
          />
          <div className={styles.phoneNo}>{userData?.phoneNumber}</div>
        </div>
        {/* <img
          src={require("./social.png").default}
          alt=""
          className={styles.socialLinks}
        /> */}
        <div
          style={{
            width: "7.125rem",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <FaInstagram
            className={styles.socialLinks}
            onClick={() => window.open(userData?.instagram, "_blank")}
          />
          <FaLinkedinIn
            className={styles.socialLinks}
            onClick={() => window.open(userData?.linkedIn, "_blank")}
          />
          <CiFacebook
            className={styles.socialLinks}
            onClick={() => window.open(userData?.facebook, "_blank")}
          />
        </div>
      </div>
    </div>
  );
};

export default Userpage;
