import React, { useState } from "react";
import AdvisorSidebar from "../../../../components/AdvisorSidebar/AdvisorSidebar";

import { useHistory } from "react-router-dom";

import AdvisorProfileAddService from "../../../../components/Advisors/AdvisorProfileAddService/AdvisorProfileAddService";
import AdvisorAddArticle from "../../../../components/Advisors/AdvisorAddArticle/AdvisorAddArticle";
import "./AddArticle.scss";

import { FiSearch } from "react-icons/fi";
import BackArrowImg from "../../../../assets/Images/AdvisorList/Vector.png";
import AdvisorSidebarLatest from "../../../../components/AdvisorSidebarLatest";
import Header from "../../AdvisorProfile/Header";

const AddArticle = () => {
  const [isEditing, setIsEditing] = useState(false);
  const history = useHistory();
  const [activeTab, setActiveTab] = useState("Articles/Blogs");

  const handleSave = (formData) => {
    console.log("Saved data:", formData);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    if (tab === "Articles/Blogs") {
      history.push("/advisor-library/articles");
    } else {
      history.push("/advisor-library/social-content");
    }
  };

  return (
    <div className="advisor-profile-pagemainflex">
      <Header />
      <div className="flexContainers">
        <AdvisorSidebarLatest />
        <div className="advisor-profile-rightsidedatashow advisor-add-article">
          {/* <div className="article-page-header">
            <div className="tab-container">
              <button
                className={`tab ${
                  activeTab === "Articles/Blogs" ? "active" : ""
                }`}
                onClick={() => handleTabClick("Articles/Blogs")}
              >
                Articles/Blogs
              </button>
              <button
                className={`tab ${
                  activeTab === "Social Content" ? "active" : ""
                }`}
                onClick={() => handleTabClick("Social Content")}
              >
                Social Content
              </button>
            </div>
          </div> */}
          <AdvisorAddArticle onSave={handleSave} onCancel={handleCancel} />
        </div>
      </div>
    </div>
  );
};

export default AddArticle;
