import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Aboutus from "./pages/AboutUs/Aboutus";
import Calculator from "./pages/Calculator/Calculator";
import Coach from "./pages/CoachPage/Coach";
import Homepage from "./pages/LandingPage/Homepage";
import LegacydesignsPage from "./pages/LegacydesignsPage/Legacydesigns";
import Privacy from "./pages/Privacy";
import AdvisorIndiviual from "./pages/AdvisorIndiviual/AdvisorIndiviual";
import ProfilePage from "./pages/ProfilePage/ProfilePage";
import webiste from "./pages/Webiste/webiste";
import Login from "./pages/Login/Login";
import Signup from "./pages/Login/Signup";
import AdvisorList from "./pages/Advisors/AdvisorList/AdvisorList";
import AdvisorReport from "./pages/Advisors/AdvisorReport/AdvisorReport";
import AdvisorChat from "./pages/Advisors/AdvisorChat/AdvisorChat";
import AdvisorProfile from "./pages/Advisors/AdvisorProfile/AdvisorProfile";
import EditAdvisorProfileInfo from "./pages/Advisors/EditAdvisorProfileInfo/EditAdvisorProfileInfo";
import EditProfileAddService from "./pages/Advisors/EditProfileAddService/EditProfileAddService";
import EditProfileAddExisting from "./pages/Advisors/EditProfileAddExisting/EditProfileAddExisting";
import ClientList from "./pages/Advisors/Client/ClientList/ClientList";
import ClientChat from "./pages/Advisors/Client/ClientChat/ClientChat";
import ClientGoal from "./pages/Advisors/Client/ClientGoal/ClientGoal";
import ClientReport from "./pages/Advisors/Client/ClientReport/ClientReport";
import ClientPreview from "./pages/Advisors/Client/ClientPreview/ClientPreview";
import Article from "./pages/Advisors/AdvisorLibrary/Article/Article";
import EditArticle from "./pages/Advisors/AdvisorLibrary/EditArticle/EditArticle";
import SocialContent from "./pages/Advisors/AdvisorLibrary/SocialContent/SocialContent";
import AddArticle from "./pages/Advisors/AdvisorLibrary/AddArticle/AddArticle";
import ShareOn from "./pages/Advisors/AdvisorLibrary/ShareOn/ShareOn";
import EditContent from "./pages/Advisors/AdvisorLibrary/EditContent/EditContent";
import AdvisorPortal from "./pages/AdvisorPortal/AdvisorPortal";
import AdvisorResultPage from "./pages/AdivisorResultPage/AdvisorResulpage";
import Userpage from "./pages/UserPage";
import AdviserClientReport from "./pages/AdvisorPortal/ClientSideAdvisor";
import ProfileEditingPage from "./pages/ProfileEditingPage/Editing";
import ProductPage from "./pages/ProductsPage";
import ServicesPage from "./pages/ServicesPage";
import AssetsPage from "./pages/AssetsPage";
import SubAssetsPage from "./pages/SubAssetsPage";
import CompanyPage from "./pages/CompanyPage";
import Dashboardv2 from "./layout/Dashboardv2";
import MarketPlaceMain from "./pages/Marketplace";
import ArticlePage from "./pages/ArticlePage";
import AdvisorProfileLayout from "./layout/AdvisorProfileLayout";
import ViewProfile from "./pages/ProfileEditingPage/ViewProfile";
import AdvisorLeads from "./pages/AdvisorLeads";
import UserPageFirms from "./pages/UserPage/UserPageFirms";
import ProductsFirms from "./pages/ProductsPage/ProductsFirms";
import ServicesFirms from "./pages/ServicesPage/ServicesFirms";
import AssetsFirms from "./pages/AssetsPage/AssetFirms";
import SubAssetsFirms from "./pages/SubAssetsPage/SubAssetsFirms";

function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={Homepage} />
      <Route exact path="/advisorprofiles/:email" component={Userpage} />
      <Route
        exact
        path="/advisorprofiles/:email/products"
        component={ProductPage}
      />
      <Route
        exact
        path="/advisorprofiles/:email/services"
        component={ServicesPage}
      />
      <Route
        exact
        path="/advisorprofiles/:email/assets"
        component={AssetsPage}
      />
      <Route
        exact
        path="/advisorprofiles/:email/subassets"
        component={SubAssetsPage}
      />
      <Route
        exact
        path="/advisorprofiles/:email/companies"
        component={CompanyPage}
      />
      <Route
        exact
        path="/advisorprofiles/:email/article/:articlename"
        component={() => <AdvisorProfileLayout child={<ArticlePage />} />}
      />
      {/* Advisor routes */}
      <Route exact path="/firms/:firmsid" component={UserPageFirms} />
      <Route exact path="/firms/:firmsid/products" component={ProductsFirms} />
      <Route exact path="/firms/:firmsid/services" component={ServicesFirms} />
      <Route exact path="/firms/:firmsid/assets" component={AssetsFirms} />
      <Route
        exact
        path="/firms/:firmsid/subassets"
        component={SubAssetsFirms}
      />
      {/* <Route exact path="/firms/:email/companies" component={CompanyPage} /> */}
      <Route
        exact
        path="/firms/:email/article/:articlename"
        component={() => <AdvisorProfileLayout child={<ArticlePage />} />}
      />
      {/* <Route exact path="/login" component={Login} /> */}
      {/* <Route exact path="/signup" component={Signup} /> */}
      <Route exact path="/client/login" component={Login} />
      <Route exact path="/advisor/login" component={Login} />
      <Route exact path="/client/signup" component={Signup} />
      <Route exact path="/advisor/signup" component={Signup} />
      <Route exact path="/privacy" component={Privacy} />
      <Route path="/aboutus" component={Aboutus} />
      <Route path="/coach" component={Coach} />

      <Route path="/rplanb/:currentstep" component={Calculator} />
      <Route path="/legacydesigns" component={LegacydesignsPage} />
      <Route path="/advisors/:id/:pname/webiste" component={webiste} />
      <Route path="/advisors/:id/:pname" component={ProfilePage} />
      <Route
        path="/advisor-client/client-goal/proposal"
        component={AdvisorResultPage}
      />

      <Route
        exact
        path="/advisor-profile/editProfile"
        component={ProfileEditingPage}
      />
      <Route exact path="/advisors/:id" component={AdvisorIndiviual} />
      <Route exact path="/advisors-list" component={AdvisorList} />
      <Route exact path="/advisors-report" component={AdvisorReport} />
      <Route exact path="/advisors-chat" component={AdvisorChat} />
      <Route exact path="/advisors-profile" component={AdvisorProfile} />

      <Route
        path="/advisor-library/social-content/share-on/edit"
        component={EditContent}
      />
      <Route
        path="/advisor-library/social-content/share-on"
        component={ShareOn}
      />
      <Route path="/advisor-library/social-content" component={SocialContent} />

      <Route path="/advisor-library/articles/edit" component={EditArticle} />
      <Route
        path="/advisor-library/articles/add-article"
        component={AddArticle}
      />

      <Route path="/advisor-library/articles" component={Article} />
      <Route
        path="/advisor-client/client-goal/create"
        component={AdviserClientReport}
      />
      <Route path="/advisor-client/client-chat" component={ClientChat} />
      <Route path="/advisor-client/client-preview" component={ClientPreview} />
      <Route path="/advisor-client/client-report" component={ClientReport} />
      <Route path="/advisor-client/client-goal" component={ClientGoal} />
      <Route path="/advisor-client" component={AdvisorPortal} />

      <Route
        path="/advisor-profile/add-service/add-existing"
        component={EditProfileAddExisting}
      />
      <Route
        path="/advisor-profile/add-service"
        component={EditProfileAddService}
      />
      <Route path="/advisor-profile/edit" component={EditAdvisorProfileInfo} />

      <Route path="/advisor-profile" component={AdvisorProfile} />

      {/* with firms id (advisor profile) */}

      <Route exact path="/react/advisorPortal" component={AdvisorPortal} />
      <Route
        exact
        path="/marketplace/:marketPlaceType"
        component={(event) => {
          // let marketPlaceType = event?.match?.params?.marketPlaceType;
          return <Dashboardv2 child={<MarketPlaceMain />} />;
        }}
      />
      <Route
        exact
        path="/profile/:profileType"
        component={() => {
          // let marketPlaceType = event?.match?.params?.marketPlaceType;
          return <Dashboardv2 child={<ViewProfile />} />;
        }}
      />
      <Route
        exact
        path="/advisor-leads"
        component={() => {
          // let marketPlaceType = event?.match?.params?.marketPlaceType;
          return <Dashboardv2 child={<AdvisorLeads />} />;
        }}
      />
      {/* Advisor route end */}

      {window.location.pathname === "/rplanb" ||
      window.location.pathname === "/rplanb/" ? (
        <Redirect to="/rplanb/step1" />
      ) : (
        ""
      )}
    </Switch>
  );
}

export default Routes;
